import { organizationsType } from '../typesActions/types';

const initialState = {
	loadingOrg: false,
	message: '',
	listOrg: [],

	loadingSendMail: false,
	messageSendMail: '',
	statusMail: '',

	loadingDestroy: false,
	messageDestroy: ''
};

export default function organizationsReducer(state = initialState, action) {
	const { listOrg } = state;

	switch (action.type) {
		// retorna lista com todas as organizações
		case organizationsType.ORGANIZATIONS_REQUEST:
			return {
				...state,
				loadingOrg: true
			};
		case organizationsType.ORGANIZATIONS_SUCCESS:
			const orgs = action.payload;

			orgs.forEach((d) => {
				d.simpleName = d.name;
				d.name = `${d.name} - ${d.initials}`;
			});
			return {
				...state,
				loadingOrg: false,
				listOrg: action.payload
			};
		case organizationsType.ORGANIZATIONS_FAILURE:
			return {
				...state,
				loadingOrg: false,
				message: action.payload.errors.message
			};
		// Solicitação de cadastro de uma nova organização pelo usuario comum
		case organizationsType.EMAIL_REGISTRATION_REQUEST:
			return {
				...state,
				loadingSendMail: true
			};
		case organizationsType.EMAIL_REGISTRATION_SUCCESS:
			return {
				...state,
				loadingSendMail: false,
				messageSendMail: action.payload.message,
				statusMail: 200
			};
		case organizationsType.EMAIL_REGISTRATION_FAILURE:
			return {
				...state,
				loadingSendMail: false,
				messageSendMail: action.payload.errors.message,
				statusMail: 400
			};

		// Cadastra uma nova organização pelo ADM
		case organizationsType.ORGANIZATIONS_SAVE_REQUEST:
			return {
				...state,
				loadingSave: true
			};
		case organizationsType.ORGANIZATIONS_SAVE_SUCCESS:
			// Adiciona a sigla após o nome
			action.payload.simpleName = action.payload.name;
			action.payload.name = `${action.payload.name} - ${action.payload.initials}`;

			return {
				...state,
				loadingSave: false,
				messageSave: action.message,
				listOrg: [...state.listOrg, action.payload]
			};
		case organizationsType.ORGANIZATIONS_SAVE_FAILURE:
			return {
				...state,
				loadingSave: false,
				messageSave: action.payload.errors.message
			};

		// Atualiza uma nova organização pelo ADM

		case organizationsType.ORGANIZATIONS_UPDATE_REQUEST:
			return {
				...state,
				loadingSave: true
			};
		case organizationsType.ORGANIZATIONS_UPDATE_SUCCESS:
			listOrg.forEach((g, i) => {
				if (g.id === action.payload.id) {
					listOrg[i].id = action.payload.id;
					listOrg[i].simpleName = action.payload.name;
					listOrg[i].name = `${action.payload.name} - ${action.payload.initials}`;
					listOrg[i].initials = action.payload.initials;
				}
			});
			return {
				...state,
				loadingSave: false,
				messageSave: action.message
			};
		case organizationsType.ORGANIZATIONS_UPDATE_FAILURE:
			return {
				...state,
				loadingSave: false,
				messageSave: action.payload.errors.message
			};

		// remove uma organização pelo ADM
		case organizationsType.ORGANIZATIONS_DESTROY_REQUEST:
			return {
				...state,
				loadingDestroy: true
			};
		case organizationsType.ORGANIZATIONS_DESTROY_SUCCESS:
			const { id, message } = action.payload;
			const filter = listOrg.filter((item) => item.id !== id);

			return {
				...state,
				loadingDestroy: false,
				messageDestroy: message,
				listOrg: filter
			};
		case organizationsType.ORGANIZATIONS_DESTROY_FAILURE:
			return {
				...state,
				loadingDestroy: false,
				messageDestroy: action.payload.errors.message
			};

		case organizationsType.ORGANIZATIONS_CLOSE:
			return {
				...state,
				messageSave: '',
				loadingSave: false,
				messageSendMail: '',
				statusMail: '',
				loadingDestroy: false,
				messageDestroy: ''
			};

		default:
			return state;
	}
}
