import React from 'react';
import { svg } from 'lib/svgList';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import * as S from './DrawerBotton.Styled';
import SubNav from './SubNav';

function DrawerBotton({ open, setOpen, content }) {
	return (
		<S.ContainerDrawer>
			<SubNav open={open} />
			<S.StyledDrawer open={open}>
				<div style={{ display: !open ? 'none' : 'initial' }}>
					<S.ButtonArrow onClick={() => setOpen(!open)}>
						<img alt="down" src={svg.icon.carouselUpWhite} />
					</S.ButtonArrow>
					{content}
				</div>

				<S.ButtonFooter style={{ display: open ? 'none' : 'initial' }} type="button" onClick={() => setOpen(!open)}>
					<Box sx={{ flexGrow: 1, pt: 1 }}>
						<Grid container spacing={2}>
							<Grid item xs={10}>
								<h5>SAIBA MAIS</h5>
							</Grid>
							<Grid item xs={2} style={{ padding: 0, paddingTop: 8 }}>
								<img alt="down" src={svg.icon.carouselUpWhite} />
							</Grid>
						</Grid>
					</Box>
				</S.ButtonFooter>
			</S.StyledDrawer>
		</S.ContainerDrawer>
	);
}

export default DrawerBotton;
