import React from 'react';
import { useSelector } from 'react-redux';
import FireRisk from 'components/Graphs/FireRisk/FireRisk';
import * as S from './styled';

export default function RiskFireData() {
	const { dataRiskFire, isLoading: loadingFireRisk } = useSelector((state) => state.riskFire);

	return (
		<S.countainerRiskFire>
			{dataRiskFire !== '' ? (
				<FireRisk positionProps={dataRiskFire} isLoading={loadingFireRisk} />
			) : (
				<p>NÃO HÁ DADOS PARA GERAR GRÁFICO</p>
			)}
		</S.countainerRiskFire>
	);
}
