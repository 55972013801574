/* eslint-disable no-unused-vars */
import React, { useEffect, useRef } from 'react';
import Attribution from 'ol/control/Attribution';
import { olMap } from 'components/Map/ControlMap';
import * as S from './style';
import Attributions from '..';

function AttributionMobileInfoContainer() {
	/* const container = useRef();
	useEffect(() => {
		setTimeout(() => {
			const attributionControl = new Attribution({
				className: 'somai_custom-ol-attribution',
				target: 'somai__custom-attribution-mobile',
				collapsible: false
			});
			olMap()?.addControl(attributionControl);
		}, 1000);
	}, []); */
	return (
		<S.Container>
			<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M8 6.5H7C6.86739 6.5 6.74021 6.55268 6.64645 6.64645C6.55268 6.74021 6.5 6.86739 6.5 7C6.5 7.13261 6.55268 7.25979 6.64645 7.35355C6.74021 7.44732 6.86739 7.5 7 7.5H7.5V11C7.5 11.1326 7.55268 11.2598 7.64645 11.3536C7.74021 11.4473 7.86739 11.5 8 11.5C8.13261 11.5 8.25979 11.4473 8.35355 11.3536C8.44732 11.2598 8.5 11.1326 8.5 11V7C8.5 6.86739 8.44732 6.74021 8.35355 6.64645C8.25979 6.55268 8.13261 6.5 8 6.5Z"
					fill="#69625D"
				/>
				<path
					d="M7.75 5.5C8.16421 5.5 8.5 5.16421 8.5 4.75C8.5 4.33579 8.16421 4 7.75 4C7.33579 4 7 4.33579 7 4.75C7 5.16421 7.33579 5.5 7.75 5.5Z"
					fill="#69625D"
				/>
				<rect x="0.5" y="0.5" width="15" height="15" rx="7.5" stroke="#69625D" />
			</svg>

			{/* <div ref={container} id="somai__custom-attribution-mobile" /> */}
			<Attributions />
		</S.Container>
	);
}

export default AttributionMobileInfoContainer;
