/* eslint-disable react/no-unknown-property */
import React from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import { svg } from 'lib/svgList';
import Grid from '@mui/material/Grid';
import { actionsUsers } from 'helpers/utils';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import useWindowSize from 'hooks/getWindowSize';
import { itens } from './itensMenu';
import SocialNetworks from '../../SocialNetworks';

export default function MenuSmallScreen() {
	const [openNavbar, setOpenNavbar] = React.useState(false);
	const { width } = useWindowSize();
	const handleOpenNavMenu = () => {
		setOpenNavbar(true);
	};

	const handleCloseNavMenu = () => {
		setOpenNavbar(false);
	};

	return (
		<Box sx={confStyle.container}>
			<button type="button" style={confStyle.button} onClick={handleOpenNavMenu}>
				<img
					style={{ width: 26, height: 16 }}
					alt="menu"
					src={width >= 800 ? svg.icon.menuGreen : svg.icon.menuWhite}
				/>
			</button>
			<Modal
				open={openNavbar}
				style={confStyle.containerModal}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={[confStyle.containerItem, { width: width > 500 ? '32vw' : '80vw' }]}>
					<div style={confStyle.sticker}>
						<Grid style={{ display: 'flex', justifyContent: 'center' }} item xs={12}>
							<IconButton edge="start" color="inherit" onClick={handleCloseNavMenu} aria-label="close">
								<div style={{ position: 'fixed', left: 30 }}>
									<img alt="close" src={svg.icon.closeBold} style={{ width: 25 }} />
								</div>
							</IconButton>
						</Grid>
					</div>
					<Grid mt={10} item xs={12} style={{ width: '100%' }}>
						{itens.map((page) => {
							const { title, href, urlIcon } = page;

							const handleGA = () => {
								actionsUsers({
									category: `plataforma/menuLateral`,
									action: 'Click no botão',
									label: title
								});
							};
							return (
								<a
									aria-hidden
									styling="link"
									key={title}
									onClick={() => {
										handleCloseNavMenu();
										handleGA();
									}}
									href={href}
									target="_blank"
									style={{ textDecoration: 'none', cursor: 'pointer' }}
									rel="noreferrer"
								>
									<ListItem style={confStyle.itemsMenu}>
										<Grid item xs={10} style={{ wordBreak: 'break-all' }}>
											<h2 style={{ color: '#16381b', fontSize: '1rem' }}>{title}</h2>
										</Grid>
										<Grid item xs={2}>
											<img alt={title} src={urlIcon} style={{ height: 30, width: 30 }} />
										</Grid>
									</ListItem>

									<Divider />
								</a>
							);
						})}
						<SocialNetworks sizeIcon={50} />
					</Grid>
				</Box>
			</Modal>
		</Box>
	);
}

const confStyle = {
	container: {
		flexGrow: 1
	},
	containerItem: {
		display: 'flex',
		position: 'absolute',
		maxWidth: 580,
		background: 'rgb(187, 168, 152)',
		padding: 2,
		height: '100vh'
	},
	containerModal: {
		height: '100%'
	},
	sticker: {
		position: 'absolute',
		top: 0,
		display: 'flex',
		paddingTop: 40,
		paddingBottom: 20
	},
	itemsMenu: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between'
	},
	button: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		background: 'transparent',
		paddingLeft: 16
	}
};
