/* eslint-disable prettier/prettier */
export const systemType = {
	GET_COORDS_ON_MAP: '@system/GET_COORDS_ON_MAP',
	GET_GEOM_ON_MAP: '@system/GET_GEOM_ON_MAP',

	VIEW_LEGEND: '@system/VIEW_LEGEND'
};

export const layerType = {
	LAYERS_READ_REQUEST: '@layer/LAYERS_READ_REQUEST',
	LAYERS_READ_SUCCESS: '@layer/LAYERS_READ_SUCCESS',
	LAYERS_READ_FAIL: '@layer/LAYERS_READ_FAIL',

	STATES_GEOJSON_READ_REQUEST: '@layer/STATES_GEOJSON_READ_REQUEST',
	STATES_GEOJSON_READ_SUCCESS: '@layer/STATES_GEOJSON_READ_SUCCESS',
	STATES_GEOJSON_READ_FAIL: '@layer/STATES_GEOJSON_READ_FAIL',

	LAYERS_REDRAW_LIST_MENU: '@layer/LAYERS_REDRAW_LIST_MENU'
};

export const reportsForAdminType = {
	ALERTS_READ_REQUEST: '@reportsForAdmin/ALERTS_READ_REQUEST',
	ALERTS_READ_SUCCESS: '@reportsForAdmin/ALERTS_READ_SUCCESS',
	ALERTS_READ_FAIL: '@reportsForAdmin/ALERTS_READ_FAIL',

	TRADITIONAL_USE_READ_REQUEST: '@reportsForAdmin/TRADITIONAL_USE_READ_REQUEST',
	TRADITIONAL_USE_READ_SUCCESS: '@reportsForAdmin/TRADITIONAL_USE_READ_SUCCESS',
	TRADITIONAL_USE_READ_FAIL: '@reportsForAdmin/TRADITIONAL_USE_READ_FAIL',

	ERROR_READ_CLOSE: '@reportsForAdmin/ERROR_READ_CLOSE'
};

export const mapType = {
	SET_ACTIVATED_LAYERS: '@map/SET_ACTIVATED_LAYERS',
	CLOSE_ACTIVATED_LAYERS: '@map/CLOSE_ACTIVATED_LAYERS',
	CLOSE_TOGGLE_ALL_LAYERS: '@map/CLOSE_TOGGLE_ALL_LAYERS',

	LOAD_LAYER_START: '@map/LOAD_LAYER_START',
	LOAD_LAYER_END: '@map/LOAD_LAYER_END',
	LOAD_LAYER_ERROR: '@map/LOAD_LAYER_ERROR',

	LOAD_IMAGE_START: '@map/LOAD_IMAGE_START',
	LOAD_IMAGE_END: '@map/LOAD_IMAGE_END',
	LOAD_IMAGE_ERROR: '@map/LOAD_IMAGE_ERROR',

	LOAD_INFO_START: '@map/LOAD_INFO_START',
	LOAD_INFO_END: '@map/LOAD_INFO_END',

	ERROR_LAYER_CLOSE: '@map/ERROR_LAYER_CLOSE'
};

export const sendMailUserType = {
	SEND_MAIL_FAILURE: '@sendMail/SEND_MAIL_FAILURE',
	SEND_MAIL_REQUEST: '@sendMail/SEND_MAIL_REQUEST',
	SEND_MAIL_SUCCESS: '@sendMail/SEND_MAIL_SUCCESS',
	SEND_MAIL_ERROR: '@sendMail/SEND_MAIL_ERROR',
	CLOSE_MESSAGE: '@sendMail/CLOSE_MESSAGE'
};

export const isRouteRef = {
	SELECTED_ROUTE: '@route/SELECTED_ROUTE'
};

export const isTabRef = {
	SELECTED_TAB: '@tab/SELECTED_TAB'
};

export const statesAmazonLegal = {
	GET_STATES_AMAZON_LEGAL: '@tabAmazon/GET_STATES_AMAZON_LEGAL',
	SUCCESS_GET_STATES_AMAZON_LEGAL: '@tabAmazon/SUCCESS_GET_STATES_AMAZON_LEGAL',
	DELETE_STATES_AMAZON_LEGAL: '@tabAmazon/DELETE_STATES_AMAZON_LEGAL',
	FAILURE_GET_STATES_AMAZON_LEGAL: '@tabAmazon/FAILURE_GET_STATES_AMAZON_LEGAL',
	SELECT_STATE_AMAZON_LEGAL: '@tabAmazon/SELECT_STATE_AMAZON_LEGAL',
	DELETE_SELECT_STATE_AMAZON_LEGAL: '@tabAmazon/SELECT_STATE_AMAZON_LEGAL',
	REQ_DATA_ABOUT_STATE_SELECTED_AMAZON_LEGAL: '@tabAmazon/REQ_DATA_ABOUT_STATE_SELECTED_AMAZON_LEGAL',
	SUCCESS_DATA_ABOUT_STATE_SELECTED_AMAZON_LEGAL: '@tabAmazon/SUCCESS_DATA_ABOUT_STATE_SELECTED_AMAZON_LEGAL',
	FAIL_DATA_ABOUT_STATE_SELECTED_AMAZON_LEGAL: '@tabAmazon/FAIL_DATA_ABOUT_STATE_SELECTED_AMAZON_LEGAL',
	DELETE_DATA_ABOUT_STATE_SELECTED_AMAZON_LEGAL: '@tabAmazon/DELETE_DATA_ABOUT_STATE_SELECTED_AMAZON_LEGAL',
	REQ_PDF: '@tabAmazon/REQ_PDF',
	SUCCESS_PDF: '@tabAmazon/SUCCESS_PDF',
	ERROR_PDF: '@tabAmazon/ERROR_PDF',
	TOGGLE_MODAL_TI_AMAZON_LEGAL: '@tabAmazon/TOGGLE_MODAL_TI_AMAZON_LEGAL'
};

export const user = {
	USER_AUTHENTICATE_REQUEST: '@user/USER_AUTHENTICATE_REQUEST',
	USER_AUTHENTICATE_SUCCESS: '@user/USER_AUTHENTICATE_SUCCESS',
	USER_AUTHENTICATE_FAILURE: '@user/USER_AUTHENTICATE_FAILURE',

	USER_SAVE_REQUEST: '@user/USER_SAVE_REQUEST',
	USER_SAVE_SUCCESS: '@user/USER_SAVE_SUCCESS',
	USER_SAVE_FAILURE: '@user/USER_SAVE_FAILURE',

	USER_UPDATE_REQUEST: '@user/USER_UPDATE_REQUEST',
	USER_UPDATE_SUCCESS: '@user/USER_UPDATE_SUCCESS',
	USER_UPDATE_FAILURE: '@user/USER_UPDATE_FAILURE',

	USER_UPDATE_ADM_REQUEST: '@user/USER_UPDATE_ADM_REQUEST',
	USER_UPDATE_ADM_SUCCESS: '@user/USER_UPDATE_ADM_SUCCESS',
	USER_UPDATE_ADM_FAILURE: '@user/USER_UPDATE_ADM_FAILURE',

	USER_PROFILES_REQUEST: '@user/USER_PROFILES_REQUEST',
	USER_PROFILES_SUCCESS: '@user/USER_PROFILES_SUCCESS',
	USER_PROFILES_FAILURE: '@user/USER_PROFILES_FAILURE',

	// retorna todos os usuarios de acordo com o perfil
	USERS_LIST_REQUEST: '@user/USERS_LIST_REQUEST',
	USERS_LIST_SUCCESS: '@user/USERS_LIST_SUCCESS',
	USERS_LIST_FAILURE: '@user/USERS_LIST_FAILURE',

	// ATUALIZA OS DADOS DO USUARIO A CADA ACESSO
	USERS_VERIFY_UPDATE_REQUEST: '@user/USERS_VERIFY_UPDATE_REQUEST',
	USERS_VERIFY_UPDATE_SUCCESS: '@user/USERS_VERIFY_UPDATE_SUCCESS',
	USERS_VERIFY_UPDATE_FAILURE: '@user/USERS_VERIFY_UPDATE_FAILURE',

	ROLES_REQUEST: '@user/ROLES_REQUEST',
	ROLES_SUCCESS: '@user/ROLES_SUCCESS',
	ROLES_FAILURE: '@user/ROLES_FAILURE',

	USER_DELETE_REQUEST: '@user/USER_DELETE_REQUEST',
	USER_DELETE_SUCCESS: '@user/USER_DELETE_SUCCESS',
	USER_DELETE_FAILURE: '@user/USER_DELETE_FAILURE',

	USER_LOGOUT: '@user/USER_LOGOUT',
	USER_CLOSE_ERROR: '@user/USER_CLOSE_ERROR',
	USER_CLOSE_DELETION_REQUESTED: '@user/USER_CLOSE_DELETION_REQUESTED'
};

export const listTisType = {
	LIST_TIS_REC: '@listTis/LIST_TIS_REC',
	LIST_TIS_REC_SUCCESS: '@listTis/LIST_TIS_REC_SUCCESS',
	LIST_TIS_REC_ERROR: '@listTis/LIST_TIS_REC_ERROR'
};

export const organizationsType = {
	ORGANIZATIONS_REQUEST: '@organizations/ORGANIZATIONS_REQUEST',
	ORGANIZATIONS_SUCCESS: '@organizations/ORGANIZATIONS_SUCCESS',
	ORGANIZATIONS_FAILURE: '@organizations/ORGANIZATIONS_FAILURE',

	ORGANIZATIONS_SAVE_REQUEST: '@organizations/ORGANIZATIONS_SAVE_REQUEST',
	ORGANIZATIONS_SAVE_SUCCESS: '@organizations/ORGANIZATIONS_SAVE_SUCCESS',
	ORGANIZATIONS_SAVE_FAILURE: '@organizations/ORGANIZATIONS_SAVE_FAILURE',

	ORGANIZATIONS_UPDATE_REQUEST: '@organizations/ORGANIZATIONS_UPDATE_REQUEST',
	ORGANIZATIONS_UPDATE_SUCCESS: '@organizations/ORGANIZATIONS_UPDATE_SUCCESS',
	ORGANIZATIONS_UPDATE_FAILURE: '@organizations/ORGANIZATIONS_UPDATE_FAILURE',

	ORGANIZATIONS_DESTROY_REQUEST: '@organizations/ORGANIZATIONS_DESTROY_REQUEST',
	ORGANIZATIONS_DESTROY_SUCCESS: '@organizations/ORGANIZATIONS_DESTROY_SUCCESS',
	ORGANIZATIONS_DESTROY_FAILURE: '@organizations/ORGANIZATIONS_DESTROY_FAILURE',

	EMAIL_REGISTRATION_REQUEST: '@organizations/EMAIL_REGISTRATION_REQUEST',
	EMAIL_REGISTRATION_SUCCESS: '@organizations/EMAIL_REGISTRATION_SUCCESS',
	EMAIL_REGISTRATION_FAILURE: '@organizations/EMAIL_REGISTRATION_FAILURE',

	ORGANIZATIONS_CLOSE: '@organizations/ORGANIZATIONS_CLOSE'
};

export const deforestationType = {
	DEFORESTATION_REC: '@DEFORESTATION_REC',
	DEFORESTATION_REC_SUCCESS: '@DEFORESTATION_REC_SUCCESS',
	DEFORESTATION_REC_ERROR: '@DEFORESTATION_REC'
};

export const threatsType = {
	TIS_THREAT_SELECTED: '@threats/TIS_THREAT_SELECTED',

	TIS_USE_AND_COVERAGE_REQUEST: '@threats/TIS_USE_AND_COVERAGE_REQUEST',
	TIS_USE_AND_COVERAGE_SUCCESS: '@threats/TIS_USE_AND_COVERAGE_SUCCESS',
	TIS_USE_AND_COVERAGE_FAILURE: '@threats/TIS_USE_AND_COVERAGE_FAILURE',

	TIS_BURNED_REQUEST: '@threats/TIS_BURNED_REQUEST',
	TIS_BURNED_SUCCESS: '@threats/TIS_BURNED_SUCCESS',
	TIS_BURNED_FAILURE: '@threats/TIS_BURNED_FAILURE'
};

export const termsOfUse = {
	ACCEPT: '@AACCEPT',
	NO_ACCEPT: '@NO_ACCEPT'
};

export const nabBar = {
	OPEN_OR_CLOSE: '@OPEN_OR_CLOSE'
};

export const deforestationSadLastType = {
	DEFORESTATION_SAD_LAST: '@DEFORESTATION_SAD_LAST',
	DEFORESTATION_SAD_LAST_SUCCESS: '@DEFORESTATION_SAD_LAST_SUCCESS',
	DEFORESTATION_SAD_LAST_ERROR: '@DEFORESTATION_SAD_LAST_ERROR'
};

export const deforestationSadSumType = {
	DEFORESTATION_SAD_SUM: '@DEFORESTATION_SAD_SUM',
	DEFORESTATION_SAD_SUM_SUCCESS: '@DEFORESTATION_SAD_SUM_SUCCESS',
	DEFORESTATION_SAD_SUM_ERROR: '@DEFORESTATION_SAD_SUM_ERROR'
};

export const deforestationDeterLastType = {
	DEFORESTATION_DETER_LAST: '@DEFORESTATION_DETER_LAST',
	DEFORESTATION_DETER_LAST_SUCCESS: '@DEFORESTATION_DETER_LAST_SUCCESS',
	DEFORESTATION_DETER_LAST_ERROR: '@DEFORESTATION_DETER_LAST_ERROR'
};

export const deforestationDeterSumType = {
	DEFORESTATION_DETER_SUM: '@DEFORESTATION_DETER_SUM',
	DEFORESTATION_DETER_SUM_SUCCESS: '@DEFORESTATION_DETER_SUM_SUCCESS',
	DEFORESTATION_DETER_SUM_ERROR: '@DEFORESTATION_DETER_SUM_ERROR'
};

export const tiGeoType = {
	TIS_GEO_REQUEST: '@TIS_GEO_REQUEST',
	TIS_GEO_SUCCESS: '@TIS_GEO_SUCCESS',
	TIS_GEO_ERROR: '@TIS_GEO_ERROR',
	TI_GEO_REC: '@TI_GEO_REC',
	TI_GEO_REC_SUCCESS: '@TI_GEO_REC_SUCCESS',
	TI_GEO_REC_ERROR: '@TI_GEO_REC_ERROR'
};

export const fireLast30daysType = {
	FIRE_LAST_30: '@FIRE_LAST_30',
	FIRE_LAST_30_SUCCESS: '@FIRE_LAST_30_SUCCESS',
	FIRE_LAST_30_ERROR: '@FIRE_LAST_30_ERROR'
};

export const fireTotalLast30daysType = {
	FIRE_TOTAL_LAST_30: '@FIRE_TOTAL_LAST_30',
	FIRE_TOTAL_LAST_30_SUCCESS: '@FIRE_TOTAL_LAST_30_SUCCESS',
	FIRE_TOTAL_LAST_30_ERROR: '@FIRE_TOTAL_LAST_30_ERROR'
};

export const fireFocusByMonthType = {
	FIRE_FOCUS_BY_MONTH: '@FIRE_FOCUS_BY_MONTH',
	FIRE_FOCUS_BY_MONTH_SUCCESS: '@FIRE_FOCUS_BY_MONTH_SUCCESS',
	FIRE_FOCUS_BY_MONTH_ERROR: '@FIRE_FOCUS_BY_MONTH_ERROR'
};

export const fireFocusByYearType = {
	FIRE_FOCUS_BY_YEAR: '@FIRE_FOCUS_BY_YEAR',
	FIRE_FOCUS_BY_YEAR_SUCCESS: '@FIRE_FOCUS_BY_YEAR_SUCCESS',
	FIRE_FOCUS_BY_YEAR_ERROR: '@FIRE_FOCUS_BY_YEAR_ERROR'
};

export const riskFireType = {
	RISK_FIRE: '@RISK_FIRE',
	RISK_FIRE_SUCCESS: '@RISK_FIRE_SUCCESS',
	RISK_FIRE_ERROR: '@RISK_FIRE_ERROR'
};

export const apiClimateType = {
	CLIMATE_REC: '@CLIMATE_REC',
	CLIMATE_REC_SUCCESS: '@CLIMATE_REC_SUCCESS',
	CLIMATE_REC_ERROR: '@CLIMATE_REC_ERROR'
};

export const temperatureType = {
	TEMPERATURE_REC: '@TEMPERATURE_REC',
	TEMPERATURE_REC_SUCCESS: '@TEMPERATURE_REC_SUCCESS',
	TEMPERATURE_REC_ERROR: '@TEMPERATURE_REC_ERROR'
};

export const monitoringDataType = {
	MONITORING_FORM_ACTIVATED: '@monitoring/MONITORING_FORM_ACTIVATED',
	MONITORING_REQ: '@monitoring/MONITORING_REQ',
	MONITORING_REC_SUCCESS: '@monitoring/MONITORING_REC_SUCCESS',
	MONITORING_UPDATE: '@monitoring/MONITORING_UPDATE',
	MONITORING_REC_ERROR: '@monitoring/MONITORING_REC_ERROR',
	MONITORING_CLEAR_DATA: '@monitoring/MONITORING_CLEAR_DATA',

	REMOVE_REGISTER_REQUEST: '@monitoring/REMOVE_REGISTER_REQUEST',
	REMOVE_REGISTER_SUCCESS: '@monitoring/REMOVE_REGISTER_SUCCESS',
	REMOVE_REGISTER_FAILURE: '@monitoring/REMOVE_REGISTER_FAILURE',

	SAVE_REGISTER_REQUEST: '@monitoring/SAVE_REGISTER_REQUEST',
	SAVE_REGISTER_SUCCESS: '@monitoring/SAVE_REGISTER_SUCCESS',
	SAVE_REGISTER_RESET: '@monitoring/SAVE_REGISTER_RESET',
	SAVE_REGISTER_FAILURE: '@monitoring/SAVE_REGISTER_FAILURE',

	EDIT_REGISTER_REQUEST: '@monitoring/EDIT_REGISTER_REQUEST',
	EDIT_REGISTER_SUCCESS: '@monitoring/EDIT_REGISTER_SUCCESS',
	EDIT_REGISTER_FAILURE: '@monitoring/EDIT_REGISTER_FAILURE',

	APPROVE_ITEM_REQUEST: '@monitoring/APPROVE_ITEM_REQUEST',
	APPROVE_ITEM_SUCCESS: '@monitoring/APPROVE_ITEM_SUCCESS',
	APPROVE_ITEM_FAILURE: '@monitoring/APPROVE_ITEM_FAILURE',

	OPEN_IS_REGISTER: '@monitoring/OPEN_IS_REGISTER',

	ADD_ROUTE_DESTROY: '@monitoring/ADD_ROUTE_DESTROY',
	CLOSE_ERROR_DESTROY: '@monitoring/CLOSE_ERROR_DESTROY',

	ADD_FILTER_TI_MONITORING: '@monitoring/ADD_FILTER_TI_MONITORING',
	REMOVE_FILTER_TI_MONITORING: '@monitoring/REMOVE_FILTER_TI_MONITORING',

	ADD_FILTER_TYPE_MONITORING: '@monitoring/ADD_FILTER_TYPE_MONITORING',
	REMOVE_FILTER_TYPE_MONITORING: '@monitoring/REMOVE_FILTER_TYPE_MONITORING',

	ADD_FILTER_DATE_MONITORING: '@monitoring/ADD_FILTER_DATE_MONITORING',
	REMOVE_FILTER_DATE_MONITORING: '@monitoring/REMOVE_FILTER_DATE_MONITORING',

	DOWNLOAD_ITEM_REQUEST: '@monitoring/DOWNLOAD_ITEM_REQUEST',
	DOWNLOAD_ITEM_SUCCESS: '@monitoring/DOWNLOAD_ITEM_SUCCESS',
	DOWNLOAD_ITEM_FAILURE: '@monitoring/DOWNLOAD_ITEM_FAILURE'
};

export const rainType = {
	RAIN_REC: '@RAIN_REC',
	RAIN_REC_SUCCESS: '@RAIN_REC_SUCCESS',
	RAIN_REC_ERROR: '@RAIN_REC_ERROR'
};

export const tiSelectedType = {
	TI_SELECTED_SAVE: '@TI_SELECTED_SAVE',
	TI_SELECTED_DELETE: '@TI_SELECTED_DELETE',
	REQ_INFORMATION_TI_SELECTED: '@REQ_INFORMATION_TI_SELECTED',
	SUCCESS_INFORMATION_TI_SELECTED: '@SUCCESS_INFORMATION_TI_SELECTED',
	ERROR_INFORMATION_TI_SELECTED: '@ERROR_INFORMATION_TI_SELECTED',
	DELETE_INFORMATION_TI_SELECTED: '@DELETE_INFORMATION_TI_SELECTED'
};

export const layersTisType = {
	TOGGLE_TERRAS_INDIGENAS: '@TOGGLE_TERRAS_INDIGENAS:',
	TOGGLE_ESTADOS_AMAZONIA: '@TOGGLE_ESTADOS_AMAZONIA:',
	TOGGLE_SEDES_MUNICIPAIS: '@TOGGLE_SEDES_MUNICIPAIS:',
	TOGGLE_COORDENACAO_REGIONAL: '@TOGGLE_COORDENACAO_REGIONAL:',
	TOGGLE_DISTRITO_SANITARIO: '@TOGGLE_DISTRITO_SANITARIO'
};

export const dataUserToShowOnMap = {
	ADD_LIST_DATA_USER_TO_ON_MAP: '@ADD_LIST_DATA_USER_TO_ON_MAP',
	EDIT_LIST_DATA_USER_TO_ON_MAP: '@EDIT_LIST_DATA_USER_TO_ON_MAP',
	DELETE_LIST_DATA_USER_TO_ON_MAP: '@DELETE_LIST_DATA_USER_TO_ON_MAP'
};

export const editDataUserOnMap = {
	INIT_EDIT_DATA_ON_MAP: '@INIT_EDIT_DATA_ON_MAP',
	FINISHED_EDIT_DATA_ON_MAP: '@FINISHED_EDIT_DATA_ON_MAP',
	CLOSE_MESSAGE: '@CLOSE_MESSAGE'
};

export const mapLoad = {
	MAP_LOADED: '@map/MAP_LOADED'
};

export const map = {
	SET_ACTIVATED_LAYERS: '@map/SET_ACTIVATED_LAYERS',

	LOAD_LAYER_START: '@map/LOAD_LAYER_START',
	LOAD_LAYER_END: '@map/LOAD_LAYER_END',
	LOAD_LAYER_ERROR: '@map/LOAD_LAYER_ERROR'
};

export const allLayersMap = {
	REQ_All_LAYERS_MAP: '@map/REQ_All_LAYERS_MAP',
	SUCCESS_All_LAYERS_MAP: '@map/SUCCESS_All_LAYERS_MAP',
	FAIL_GET_All_LAYERS_MAP: '@map/FAIL_GET_All_LAYERS_MAP',

	TOGGLE_TIS: '@map/TOGGLE_TIS'
};

export const deforestationThreatBufferType = {
	REQ_DEFORASTATION_THREAT_BUFFER: '@deforastaionThreatBuffer/REQ_DEFORASTATION_THREAT_BUFFER',
	SUCCESS_DEFORASTATION_THREAT_BUFFER: '@deforastaionThreatBuffer/SUCCESS_DEFORASTATION_THREAT_BUFFER',
	ERROR_DEFORASTATION_THREAT_BUFFER: '@deforastaionThreatBuffer/ERROR_DEFORASTATION_THREAT_BUFFER',
	DELETE_DEFORASTATION_THREAT_BUFFER: '@deforastaionThreatBuffer/DELETE_DEFORASTATION_THREAT_BUFFER'
};

export const webGisType = {
	// retorna todos os usuarios de acordo com o perfil
	WEBGIS_SYSTEM_USERS_REQUEST: '@webGis/WEBGIS_SYSTEM_USERS_REQUEST',
	WEBGIS_SYSTEM_USERS_SUCCESS: '@webGis/WEBGIS_SYSTEM_USERS_SUCCESS',
	WEBGIS_SYSTEM_USERS_FAILURE: '@webGis/WEBGIS_SYSTEM_USERS_FAILURE',

	WEBGIS_SYSTEM_USERS_UPDATE: '@webGis/WEBGIS_SYSTEM_USERS_UPDATE',

	WEBGIS_FILTER_USERS_PROFILE: '@webGis/WEBGIS_FILTER_USERS_PROFILE',
	WEBGIS_FILTER_USERS_ORGANIZATON: '@webGis/WEBGIS_FILTER_USERS_ORGANIZATON',
	WEBGIS_FILTER_USERS_LETTER: '@webGis/WEBGIS_FILTER_USERS_LETTER',
	WEBGIS_FILTER_USERS_SEARCH: '@webGis/WEBGIS_FILTER_USERS_SEARCH',
	WEBGIS_FILTER_MODAL_SEARCH: '@webGis/WEBGIS_FILTER_MODAL_SEARCH',
	WEBGIS_FILTER_PERIOD: '@webGis/WEBGIS_FILTER_PERIOD',
	WEBGIS_FILTER_DATA_USERS: '@webGis/WEBGIS_FILTER_DATA_USERS'
};

export const measure = {
	MEASURE_SAVE_REQUEST: '@measure/MEASURE_SAVE_REQUEST',
	MEASURE_SAVE_SUCCESS: '@measure/MEASURE_SAVE_SUCCESS',
	MEASURE_SAVE_FAILURE: '@measure/MEASURE_SAVE_FAILURE',

	MEASURE_READ_REQUEST: '@measure/MEASURE_READ_REQUEST',
	MEASURE_READ_SUCCESS: '@measure/MEASURE_READ_SUCCESS',
	MEASURE_READ_FAILURE: '@measure/MEASURE_READ_FAILURE',

	MEASURE_DELETE_REQUEST: '@measure/MEASURE_DELETE_REQUEST',
	MEASURE_DELETE_SUCCESS: '@measure/MEASURE_DELETE_SUCCESS',
	MEASURE_DELETE_FAILURE: '@measure/MEASURE_DELETE_FAILURE',

	MEASURE_ON_MAP: '@measure/MEASURE_ON_MAP'
};
