import React, { useState, useEffect } from 'react';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { color } from 'styles/Theme';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { useSelector, useDispatch } from 'react-redux';
import { setActivatedLayers } from 'store/actions/mapActions';
import { toggleTisMap } from 'store/actions/allLayersMapAction';
import SelectTi from './SelectTis';
import SelectAmazon from './SelectAmazon';
import ModalReportAllTisAmazon from './ModalReportAllTisAmazon';
import ModalTi from './ModalTi/index';

function Territory({ territory, setTerritory, stateSelectedIndex, setStateSelectedIndex }) {
	const dispatch = useDispatch();

	const { isOpenModalTi } = useSelector((state) => state.amazonLegal);
	const { activeToggleTis } = useSelector((state) => state.allLayersMap);
	const [isOpenModalIndex, setIsOpenModalIndex] = useState(false);

	const handleChange = (event) => {
		setTerritory(event.target.value);
	};

	useEffect(() => {
		// ATIVA as tis ao entrar na tab por estado
		if (territory === 'amazonia' && !activeToggleTis) {
			dispatch(toggleTisMap());
		}

		// DESATIVA as tis ao entrar na tab por tis
		// if (territory === 'tis' && activeToggleTis) {
		// 	dispatch(toggleTisMap());
		// }
		dispatch(setActivatedLayers());

		return () => {};
	}, [territory]);

	return (
		<div style={styles.container}>
			<FormControl sx={{ mb: 1.5 }}>
				<RadioGroup row name="controlled-radio-buttons-group" value={territory} onChange={handleChange}>
					<FormControlLabel
						value="amazonia"
						control={<Radio size="medium" sx={styles.radio} />}
						label={<p style={styles.formControlLabel}>Por estado</p>}
					/>
					<FormControlLabel
						value="tis"
						control={<Radio size="medium" sx={styles.radio} />}
						label={<p style={styles.formControlLabel}>Por Terra Indígena</p>}
					/>
				</RadioGroup>
			</FormControl>

			<SelectAmazon
				territory={territory}
				isOpenModalIndex={isOpenModalIndex}
				setIsOpenModalIndex={setIsOpenModalIndex}
				setStateSelectedIndex={setStateSelectedIndex}
			/>
			<SelectTi territory={territory} />
			<ModalReportAllTisAmazon
				stateSelected={stateSelectedIndex}
				isOpenModal={isOpenModalIndex}
				setIsOpenModal={setIsOpenModalIndex}
			/>

			{isOpenModalTi && <ModalTi />}
		</div>
	);
}

const styles = {
	container: {
		marginBottom: 15
	},
	radio: {
		padding: 1,
		paddingLeft: 1.3,
		'&, &.Mui-checked': {
			color: color.interface.green
		}
	},
	formControlLabel: { fontSize: 14, fontWeight: 400 }
};
export default Territory;
