/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/jsx-no-constructed-context-values */
import { useContext, createContext, useState } from 'react';

const AlertTraditionlUseContext = createContext();

function AlertTraditionlUseContextProvider({ children }) {
	const [lat, setLat] = useState('');
	const [long, setLong] = useState('');

	return (
		<AlertTraditionlUseContext.Provider value={{ lat, setLat, long, setLong }}>
			{children}
		</AlertTraditionlUseContext.Provider>
	);
}

export { AlertTraditionlUseContext, AlertTraditionlUseContextProvider };

export const useAlertTraditionlUseContext = () => useContext(AlertTraditionlUseContext);
