/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unstable-nested-components */
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { saveMeasure } from 'store/actions/measureAction';
import { AlertDialog } from 'components/AlertDialog';
import ShareModal from './Modal';

function SaveMeasure({ itemSelect }) {
	const dispatch = useDispatch();

	const { loadingSaveMeasure } = useSelector((state) => state.measure);

	const [showModal, setShowModal] = useState(false);

	const openModal = () => {
		setShowModal((prev) => !prev);
		AlertDialog({
			customUI: ({ onClose }) => (
				<ShareModal
					onClose={onClose}
					itemSelect={itemSelect}
					loadingSaveMeasure={loadingSaveMeasure}
					saveMeasure={saveMeasure}
					dispatch={dispatch}
				/>
			),
			afterClose: () => {
				setShowModal(false);
			}
		});
	};

	return (
		<button type="button" className={showModal ? `active` : 'btn btn-default btn-map ng-binding'} onClick={openModal}>
			{loadingSaveMeasure ? 'Aguarde...' : 'Salvar polígono'}
		</button>
	);
}

export default SaveMeasure;
