import { deforestationSadLastType } from 'store/typesActions/types';
import { api } from 'services/api';

export const getDeforestationSadLast = (code) => async (dispatch) => {
	dispatch({ type: deforestationSadLastType.DEFORESTATION_SAD_LAST });
	try {
		const res = await api.get(`/ti/${code}/alerts_deforestation_sad_last_months/12`);
		const { data } = res || {};
		dispatch({
			type: deforestationSadLastType.DEFORESTATION_SAD_LAST_SUCCESS,
			payload: data
		});
		return data;
	} catch (error) {
		const { response } = error;
		if (response && response.status === 400) {
			const { message } = response.data;
			dispatch({
				type: deforestationSadLastType.DEFORESTATION_SAD_LAST_ERROR,
				payload: { errors: { message } }
			});
		} else {
			dispatch({
				type: deforestationSadLastType.DEFORESTATION_SAD_LAST_ERROR,
				payload: {
					errors: { message: 'Erro desconhecido. Tente novamente mais tarde' }
				}
			});
		}
	}
};
