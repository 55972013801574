import React, { useState, memo } from 'react';
import InputRegister from 'components/InputRegister';
import DeleteForeverSharpIcon from '@mui/icons-material/DeleteForeverSharp';
import { svg } from 'lib/svgList';
import AddItem from 'components/Buttons/AddItem';
import { especialCharMask } from 'helpers/utils';
import SaveOrUpdateOrgs from './SaveOrUpdateOrgs';
import Destroy from './Destroy';
import * as S from './styled';

function ListOrgs({ orgs = [] }) {
	const [isitem, setIsitem] = useState([]);
	const [textFilter, setTextFilter] = useState('');
	const [modalEdit, setModalEdit] = useState(false);
	const [modalDestroy, setModalDestroy] = useState(false);

	if (textFilter) {
		orgs = orgs.filter((i) => {
			const nameOrg = especialCharMask(i.name);
			const initials = especialCharMask(i.initials);
			const newTextFilter = especialCharMask(textFilter);
			return nameOrg.includes(newTextFilter) || initials.includes(newTextFilter);
		});
	}

	const handleEdit = (item) => {
		setModalEdit(true);
		setIsitem(item);
	};

	const handleNew = () => {
		setModalEdit(true);
		setIsitem([]);
	};

	const handleDestroy = (item) => {
		setModalDestroy(true);
		setIsitem(item);
	};

	return (
		<>
			<S.ContainerList>
				<S.ContainerSubTitle>
					<p>Total de Organizações: {orgs.length || 0}</p>
					<div style={{ marginRight: 15 }}>
						<AddItem
							name="organização"
							onClink={() => {
								handleNew();
							}}
						/>
					</div>
				</S.ContainerSubTitle>
				<S.BoxFilter>
					<InputRegister type="text" placeholder="Pesquisar..." value={textFilter} setValue={setTextFilter} />
				</S.BoxFilter>

				{orgs &&
					orgs.map((item) => (
						<S.Item key={item.id}>
							<S.Name style={configStyle.p}>{`${item.name}`}</S.Name>
							<div>
								<button style={configStyle.button} type="button" onClick={() => handleEdit(item)}>
									<img style={{ width: 16 }} alt="edit" src={svg.icon.edit} />
								</button>
								<button style={configStyle.button} type="button" onClick={() => handleDestroy(item)}>
									<DeleteForeverSharpIcon sx={{ color: 'red', fontSize: 20 }} />
								</button>
							</div>
						</S.Item>
					))}
			</S.ContainerList>
			<SaveOrUpdateOrgs org={isitem} stateModal={modalEdit} setStateModal={setModalEdit} />

			<Destroy org={isitem} stateModal={modalDestroy} setStateModal={setModalDestroy} setIsitem={setIsitem} />
		</>
	);
}

const configStyle = {
	p: {
		maxWidth: '100%',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap'
	},
	button: {
		background: 'transparent',
		marginLeft: 7,
		cursor: 'pointer'
	}
};

export default memo(ListOrgs);
