import React, { useState, useEffect, memo } from 'react';
import InputRegister from 'components/InputRegister';
import { orgRegistration, orgUpdate, closeMessageAndError } from 'store/actions/organizationsActions';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'components/Modal';
import { color } from 'styles';
import * as S from './styled';

function SaveOrUpdateOrgs({ org = [], stateModal, setStateModal = () => {} }) {
	const dispatch = useDispatch();
	const [id, setId] = useState('');
	const [name, setName] = useState('');
	const [initials, setInitials] = useState('');

	const { loadingSave, messageSave } = useSelector((state) => state.organizations);

	useEffect(() => {
		setId(org?.id || '');
		setName(org?.simpleName || '');
		setInitials(org?.initials || '');
	}, [org]);

	const formData = {
		id,
		name,
		initials
	};

	if (!id) {
		delete formData.id;
	}

	const sendData = (e) => {
		e.preventDefault();
		if (id) {
			dispatch(orgUpdate(formData));
		} else {
			dispatch(orgRegistration(formData));
		}
	};

	useEffect(() => {
		if (messageSave !== '') {
			setTimeout(() => {
				dispatch(closeMessageAndError());
			}, 2000);
		}
	}, [messageSave]);

	return (
		<Modal
			styleContent={{
				width: '70vw',
				height: '56vh',
				maxWidth: '90%',
				zIndex: 200
			}}
			isOpen={stateModal}
			setIsOpen={setStateModal}
			useBtnEnd={false}
		>
			<S.Container>
				{messageSave ? (
					<p style={{ display: 'flex', justifyContent: 'center', padding: 20 }}>{messageSave}</p>
				) : (
					<form onSubmit={sendData} style={styledConfg.form}>
						<S.Label>Nome</S.Label>
						<InputRegister type="text" value={name} setValue={setName} required />
						<S.Label>Sigla</S.Label>
						<InputRegister type="text" value={initials} setValue={setInitials} required />{' '}
						<S.BoxButton>
							<S.Button type="submit" disabled={loadingSave}>
								{loadingSave ? 'Aguarde...' : 'Salvar'}
							</S.Button>
						</S.BoxButton>
					</form>
				)}
			</S.Container>
		</Modal>
	);
}

const styledConfg = {
	select: {
		width: '100%',
		height: 55,
		color: color.interface.inputs,
		fontSize: 16,
		fontFamily: 'Nunito',
		border: `1.5px solid ${color.interface.inputs}`,
		padding: '0 22px',
		borderRadius: 10
	},
	form: {
		width: '100%',
		padding: 10
	}
};
export default memo(SaveOrUpdateOrgs);
