/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { color } from 'styles/Theme';
import Button from 'components/Buttons/Button/Button';
import { getUserLocalStorage } from 'services/session';

import { generateHash } from 'helpers/utils';
import CustomInput from 'components/CustomInput';
import * as S from './styled';

function SaveModalMeasure({ onClose, itemSelect, loadingSaveMeasure, saveMeasure, dispatch }) {
	const [nameMeasure, setNameMeasure] = useState('');
	const [nameMeasureEmpty, setNameMeasureEmpty] = useState(false);

	const { user } = getUserLocalStorage();
	const date = new Date();

	useEffect(() => {
		let timer;
		if (nameMeasureEmpty) {
			timer = setTimeout(() => {
				setNameMeasureEmpty(false);
			}, 5000);
		}
		return () => clearTimeout(timer);
	}, [nameMeasureEmpty]);

	const closeModal = () => {
		onClose();
	};

	const handleSave = () => {
		const values = {
			name: nameMeasure,
			type: itemSelect.typeMeasure,
			geom: itemSelect.coordinates,
			user_id: user?.id,
			created_at: date.toString(),
			hash: generateHash()
		};

		if (nameMeasure === '') {
			setNameMeasureEmpty(true);
			return;
		}

		dispatch(saveMeasure(values)).then(() => {
			setNameMeasureEmpty(false);
			onClose();
		});
	};

	return (
		<S.ModalWrapper>
			<div className="somai-confirm-alert-body" style={{ maxWidth: 300 }}>
				<S.Title>Salvar Medida</S.Title>
				<S.ModalContent>
					<S.itemForm>
						<p>Insira o nome da medida abaixo:</p>
						<CustomInput
							styleInput={styles.input}
							value={nameMeasure}
							setValue={setNameMeasure}
							minLength={1}
							maxLength={35}
						/>
						{nameMeasureEmpty && <S.errorInputEmpity>*Favor preencher o nome da medida</S.errorInputEmpity>}
					</S.itemForm>
				</S.ModalContent>
				<S.ModalFooter>
					<Button
						onClick={handleSave}
						text={loadingSaveMeasure ? 'Aguarde...' : 'Salvar'}
						isLoading={loadingSaveMeasure}
					/>
				</S.ModalFooter>
			</div>

			<S.CloseModalButton aria-label="Fechar modal" onClick={closeModal} />
		</S.ModalWrapper>
	);
}

const styles = {
	input: {
		borderRadius: '8px',
		marginBottom: '0px',
		border: `1px solid ${color.interface.lightGray}`,
		backgroundColor: '#ffffff'
	}
};

export default SaveModalMeasure;
