import React, { useState } from 'react';
import { formatSizesFiles, calculateRequestTime } from 'helpers/utils';
import Compressor from 'compressorjs';
import Grid from '@mui/material/Grid';
import DragDropFile from 'components/DragDropFile';
import * as S from './styles';

function ImageUploading({ isImage = '', setPhoto = () => {} }) {
	const [errorPhoto, setErrorPhoto] = useState(false);
	const [viewPhoto, setViewPhoto] = useState('');
	// função que faz verificação pra saber se o arquivo carregado no input de foto, é um arquivo do tipo foto.
	function savePhoto(event) {
		const extesions = ['png', 'jpeg', 'jpg'];
		let isPhoto = false;

		extesions.forEach((item) => {
			const isExist = event.target.value.indexOf(item);
			if (isExist !== -1) isPhoto = true;
		});

		if (isPhoto) {
			const file = event.target.files[0];
			// compacta do tamanho das imagens
			const t0 = performance.now();

			new Compressor(file, {
				quality: 1,
				maxWidth: 800,
				convertSize: 2000000, // 2MB
				success(blob) {
					const newFile = new File([blob], file.name);
					// Tamanho da imagem após a compressão
					console.log('compressorjs', formatSizesFiles(blob), calculateRequestTime(t0));

					setErrorPhoto(false);
					setPhoto(newFile);
					setViewPhoto({
						file: URL.createObjectURL(newFile)
					});
				}
			});
		} else {
			setErrorPhoto(true);
			setPhoto(null);
		}
	}
	/* const handleChange = (e) => {
		e.preventDefault();
	}; */
	const handleDragChange = (files) => {
		const extesions = ['image/jpeg', ' image/jpg', 'image/png'];
		let isPhoto = false;

		extesions.forEach((item) => {
			const isExist = files[0].type.indexOf(item);
			if (isExist !== -1) isPhoto = true;
		});
		if (isPhoto) {
			const file = files[0];
			// compacta do tamanho das imagens
			const t0 = performance.now();

			new Compressor(file, {
				quality: 1,
				maxWidth: 800,
				convertSize: 2000000, // 2MB
				success(blob) {
					const newFile = new File([blob], file.name);
					// Tamanho da imagem após a compressão
					console.log('compressorjs', formatSizesFiles(blob), calculateRequestTime(t0));

					setErrorPhoto(false);
					setPhoto(newFile);
					setViewPhoto({
						file: URL.createObjectURL(newFile)
					});
				}
			});
		} else {
			setErrorPhoto(true);
			setPhoto(null);
		}
	};
	const image = viewPhoto?.file || isImage;

	return (
		<Grid container style={{ alignItems: 'center' }}>
			<Grid item xs={12} mt={2}>
				<p>Imagem do registro:</p>
			</Grid>

			<Grid
				item
				xs={12}
				mt={1}
				style={{
					display: 'flex',
					alignItems: 'center'
				}}
			>
				<DragDropFile
					handleChange={(event) => savePhoto(event)}
					handleDragChange={handleDragChange}
					accept="image/jpeg, image/jpg, image/png"
					isMultipl={false}
					image={image}
					size="xs"
				/>
			</Grid>

			<Grid item xs={12}>
				{errorPhoto ? <S.errorPhoto>Selecione apenas fotos nos formatos: jpeg ou png.</S.errorPhoto> : null}
			</Grid>
		</Grid>
	);
}

export default ImageUploading;
