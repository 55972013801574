import React from 'react';
import Modal from 'components/Modal';
import FlasingText from 'components/FlashingText';
import Load from 'components/Load/Load';
import { useSelector } from 'react-redux';

export default function ReqModal() {
	const { isReqInformation } = useSelector((state) => state.tiSelectedTabTi);

	return (
		<Modal isOpen={isReqInformation} btnClose={false} useBtnEnd={false}>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					gap: '10px',
					padding: '10px'
				}}
			>
				<FlasingText text="Buscando dados sobre a TI selecionada..." style={{ marginBottom: '10px' }} />
				<Load />
			</div>
		</Modal>
	);
}
