import React, { useEffect, useState } from 'react';
import { getUserLocalStorage, setUserLogout } from 'services/session';
import { useDispatch, useSelector } from 'react-redux';
import { addRefRouteAction } from 'store/actions/isRouteRefActions';
import { toggleOpenOrCloseNavBar } from 'store/actions/isNavBarOpenAction';
import { addRefTabAction } from 'store/actions/isTabRefActions';
import * as S from '../MenuUser.Styled';

function Unplugged({ handleCloseUserMenu }) {
	const dispatch = useDispatch();

	const { loading } = useSelector((state) => state.user);
	const [session, setSession] = useState({});

	useEffect(() => {
		setSession(getUserLocalStorage());
	}, [loading]);

	const handleLogout = () => {
		setUserLogout();
		document.location.reload(true);
	};

	return (
		<>
			<h4 style={{ marginBottom: 10, fontSize: 13 }}>
				{session ? `Olá, ${session?.user?.firstName}!` : 'Carregue seus dados no SOMAI'}
			</h4>

			{session ? (
				<S.ButtonRegister
					type="button"
					onClick={() => {
						handleLogout();
					}}
				>
					Sair da conta
				</S.ButtonRegister>
			) : (
				<>
					<S.ButtonLogin
						type="button"
						onClick={() => {
							dispatch(addRefRouteAction('login'));
							dispatch(addRefTabAction('tab2'));
							dispatch(toggleOpenOrCloseNavBar({ status: true }));
							handleCloseUserMenu();
						}}
					>
						Entrar
					</S.ButtonLogin>
					<S.ButtonRegister
						type="button"
						onClick={() => {
							dispatch(addRefRouteAction('register'));
							dispatch(addRefTabAction('tab2'));
							dispatch(toggleOpenOrCloseNavBar({ status: true }));
							handleCloseUserMenu();
						}}
					>
						Cadastrar
					</S.ButtonRegister>
				</>
			)}
		</>
	);
}

export default Unplugged;
