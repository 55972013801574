/* eslint-disable no-unused-vars */
import React, { useState, memo } from 'react';
import { svg } from 'lib/svgList';
import { useDispatch, useSelector } from 'react-redux';
import useWindowSize from 'hooks/getWindowSize';
import { color } from 'styles/Theme';
import GridBox from 'components/Grid/Grid';
import Row from 'components/Grid/Row';
import { stateViewLegend } from 'store/actions/systemActions';
import ControlButtons from './ControlButtons';
import * as S from './LegendAndAdjustments.style';
import Opacity from './Opacity';
import LayerOrder from './LayerOrder';
import getLegend from './getLegend';
import CloseAllLayers from '../Controls/CloseAllLayers';

const maxHeightBodyLegend = window.innerHeight - 120;

function LegendAndAdjustments() {
	const dispatch = useDispatch();
	const isLoadMap = useSelector((state) => state.isLoadMap);

	const { width } = useWindowSize();
	const { activatedLayers } = useSelector((state) => state.map);

	// se não for mobile ele inicia com a legenda fechada
	const [expandedLegend, setExpandedLegend] = useState(true);

	const singleLayer = activatedLayers?.length <= 1;

	const { statusLegend } = useSelector((state) => state.system);
	const HandleControl = () => {
		dispatch(stateViewLegend(!statusLegend));
	};

	// Entra no componente apenas apos carregar o mapa
	if (!isLoadMap) return null;
	return (
		<S.LegendModel layerOnMap={!!activatedLayers.length} expandedLegend={expandedLegend}>
			<S.LegendContainer {...{ maxHeight: maxHeightBodyLegend, isExpanded: expandedLegend }}>
				{width > 800 && (
					<GridBox style={styles.header}>
						<Row>
							<S.CellLegend size={2} {...{ isExpanded: expandedLegend }}>
								<ControlButtons expandedLegend={expandedLegend} setExpandedLegend={setExpandedLegend} />
							</S.CellLegend>
							<S.CellLegend size={8} style={styles.titleLegend} {...{ isExpanded: expandedLegend }}>
								<S.LegendTitle>Camadas ativas</S.LegendTitle>
							</S.CellLegend>
						</Row>
					</GridBox>
				)}

				<S.LegendContent {...{ maxHeight: maxHeightBodyLegend }}>
					<GridBox>
						<Row>
							<S.CellDescription size={2}>
								<S.ContainerItem>
									<img style={styles.icon} src={svg.icon.position} alt="Posição" />
								</S.ContainerItem>
							</S.CellDescription>

							<S.CellDescription size={width < 800 ? 5 : 6}>
								<S.ContainerItem>
									<img style={styles.icon} src={svg.icon.camadaLayer} alt="Legenda" />
									Legenda
								</S.ContainerItem>
							</S.CellDescription>
							<S.CellDescription size={width < 800 ? 5 : 4}>
								<S.ContainerItem>
									<img style={styles.icon} src={svg.icon.visualize} alt="Transparência" />
									Transparência
									{width < 800 && (
										<button
											style={{
												width: 23,
												borderLeft: '1px solid #D9D5D2',
												marginLeft: 6,
												paddingLeft: 6
											}}
											type="button"
											onClick={HandleControl}
										>
											<img style={styles.btnClose} src={svg.icon.closeBold} alt="fechar" />
										</button>
									)}
								</S.ContainerItem>
							</S.CellDescription>
						</Row>
					</GridBox>
					<GridBox>
						{activatedLayers.map((layer, i) => {
							const label = layer.get('label');
							const nameMapFile = layer.get('name');
							const { haveTitle } = layer.getProperties();

							return (
								<React.Fragment key={`layer-${nameMapFile}`}>
									<Row>
										<S.Cell size={2}>
											<LayerOrder
												item={layer}
												indexItem={i}
												lengthArray={activatedLayers.length}
												singleLayer={singleLayer}
												label={label}
											/>
										</S.Cell>

										<S.Cell size={width < 800 ? 5 : 6}>
											{haveTitle ? <S.LegendLabel>{label}</S.LegendLabel> : getLegend(layer)}
										</S.Cell>
										<S.Cell size={width < 800 ? 5 : 4}>
											<Opacity item={layer} label={label} />
										</S.Cell>
									</Row>
									{haveTitle && (
										<Row>
											<S.Cell size={2} />
											<S.Cell size={width < 800 ? 5 : 6}>{getLegend(layer)}</S.Cell>
											<S.Cell size={width < 800 ? 5 : 4} />
										</Row>
									)}
								</React.Fragment>
							);
						})}
					</GridBox>
					<GridBox>
						<Row>
							<CloseAllLayers />
						</Row>
					</GridBox>
				</S.LegendContent>
			</S.LegendContainer>
		</S.LegendModel>
	);
}

const styles = {
	header: {
		backgroundColor: color.interface.orange
	},
	icon: {
		width: 18
	},
	titleLegend: {
		display: 'flex',
		justifyContent: 'center'
	},
	btnClose: {
		width: 8
	}
};

export default memo(LegendAndAdjustments);
