import React from 'react';
import { svg } from 'lib/svgList';
import NavAccordion from 'components/NavAccordion';
import { useSelector } from 'react-redux';
import OptionsMonitoring from './OptionsMonitoring';

export default function DataPersonal() {
	const { listDataUsers, loadingSystemUsers } = useSelector((state) => state.webGis);

	const data = [
		{
			id: 1,
			title: 'Monitoramento',
			subTitle: 'Alertas',
			icon: svg.icon.warningOrange,
			size: listDataUsers.alerts.length,
			content: (
				<OptionsMonitoring type="alert" listDataUsers={listDataUsers.alerts} loadingSystemUsers={loadingSystemUsers} />
			)
		},
		{
			id: 2,
			title: 'Monitoramento',
			subTitle: 'Usos Tradicionais',
			icon: svg.icon.mapLocationOrange,
			size: listDataUsers.traditionalUses.length,
			content: (
				<OptionsMonitoring
					type="traditionalUse"
					listDataUsers={listDataUsers.traditionalUses}
					loadingSystemUsers={loadingSystemUsers}
				/>
			)
		},
		{
			id: 3,
			title: 'Monitoramento',
			subTitle: 'Trajetos',
			icon: svg.icon.routeOrange,
			size: listDataUsers.tracks.length,
			content: (
				<OptionsMonitoring
					type="tracker"
					listDataUsers={listDataUsers.tracks}
					loadingSystemUsers={loadingSystemUsers}
				/>
			)
		},
		{
			id: 4,
			title: 'Monitoramento',
			subTitle: 'Localizações',
			icon: svg.icon.locationRoundOrange,
			size: listDataUsers.whereiBeen.length,
			content: (
				<OptionsMonitoring
					type="whereIBeen"
					listDataUsers={listDataUsers.whereiBeen}
					loadingSystemUsers={loadingSystemUsers}
				/>
			)
		}
	];
	return <NavAccordion title="MONITORAMENTO" dataRouteMenu={data} isInitiOpen />;
}
