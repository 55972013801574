/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import React from 'react';
import SwitchToggle from 'components/Buttons/SwitchToggleGeneral';
import SwitchToggleLayers from 'components/Buttons/SwitchToggleLayers/index';
import { actionsUsers } from 'helpers/utils';
import { useDispatch, useSelector } from 'react-redux';
import GridBox from 'components/Grid/Grid';
import { removeLayerFromMap, setActivatedLayers } from 'store/actions/mapActions';
import { toggleTisMap } from 'store/actions/allLayersMapAction';
import DownloadLayers from 'components/DownloadLayers';
import Load from 'components/Load/Load';
import BalloonDoubts from 'components/BalloonDoubts';
import { useColorsContext } from 'services/color.context';
import { useMeasureStore } from 'services/map/measure.context';
import { useShapeStore } from 'services/map/shapes.context';
import * as S from './styled';

export default function Camadas() {
	const dispatch = useDispatch();
	const { showModal, setShowModal, handleAddLayer, nameLayer } = useColorsContext();
	const { showModalMeasure, setShowModalMeasure, resetInteraction, state: stateMeasure } = useMeasureStore();
	const { showModalDraw, setShowModalDraw, resetInteraction: resetInteractionDraw, state: stateDraw } = useShapeStore();
	const {
		allLayers,
		activeToggleTis,
		isReq,
		isErrorReq,
		message: messageErro
	} = useSelector((state) => state.allLayersMap);

	const { activatedLayers } = useSelector((state) => state.map);
	const nameLabel = 'Terras indígenas';

	const { layers } = allLayers.length > 0 && allLayers.filter((item) => item.key === 'layersTabTi')[0];

	const handleToggle = () => {
		removeLayerFromMap('tabAmazon');
		removeLayerFromMap('tabAmazonTis');

		const status = !activeToggleTis ? 'Ativou' : 'desativou';

		actionsUsers({
			category: 'plataforma/tabTis/camadas',
			action: `${status} a Visualização de camada`,
			label: nameLabel
		});

		setTimeout(() => {
			dispatch(toggleTisMap());
			dispatch(setActivatedLayers());
		}, 100);
	};

	const openModal = (nameLayer) => {
		if (showModalDraw || stateDraw.drawShapesIsActive) {
			setShowModalDraw(false);
			resetInteractionDraw();
		}
		if (showModalMeasure || stateMeasure.drawMeasuresIsActive) {
			setShowModalMeasure(false);
			resetInteraction();
		}
		actionsUsers({
			category: `plataforma/mapa`,
			action: 'Camada Selecionada para a troca de cor',
			label: nameLabel
		});

		setShowModal(true);
		handleAddLayer(nameLayer);
	};

	// const openaAttributions = (nameLayer) => {
	// 	console.log(nameLayer);
	// };

	function checkNameInArray(name) {
		return activatedLayers.some((obj) => obj.get('name') === name);
	}

	function returnHTML() {
		if (isErrorReq) {
			return <S.erro>{messageErro}</S.erro>;
		}
		const itemTis = {
			name: nameLabel,
			folderPath: 'map/amazoniaLegal/tis',
			formatGeo: 'database',
			name_mapfile: 'tis_amazonia_legal',
			infor: {
				content:
					'Terras Indígenas são áreas tradicionalmente ocupadas por um ou mais povos indígenas e estão garantidas pelo Artigo 231 da Constituição Federal como território de livre uso dos povos que nelas habitam.',
				font: 'FUNAI, RAISG e ISA, 2020.'
			}
		};

		return (
			<GridBox style={{ padding: 0 }}>
				<S.BoxRow>
					<S.Box size={1.6}>
						<SwitchToggle isChecked={activeToggleTis} setIsChecked={handleToggle} />
					</S.Box>
					<S.Box size={7.4}>
						<S.title>{nameLabel}</S.title>
					</S.Box>
					<S.Box size={3}>
						<S.boxIcons>
							{checkNameInArray('tabAmazonTis') && (
								<button
									type="button"
									className={
										showModal && nameLayer === 'tabAmazonTis' ? `layout-paint__btn active` : `layout-paint__btn`
									}
									onClick={() => openModal('tabAmazonTis')}
								>
									<svg width="18" height="14" viewBox="0 0 59 55" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path
											d="M51 38C50.6663 37.9995 50.3377 38.0812 50.0443 38.2377C49.7509 38.3942 49.5019 38.6205 49.32 38.896C48 40.8751 45 45.7194 45 48.0923C45 49.6591 45.6321 51.1618 46.7574 52.2697C47.8826 53.3776 49.4087 54 51 54C52.5913 54 54.1174 53.3776 55.2426 52.2697C56.3679 51.1618 57 49.6591 57 48.0923C57 45.7194 54 40.8751 52.67 38.896C52.489 38.6219 52.2416 38.3965 51.9501 38.24C51.6585 38.0836 51.332 38.0011 51 38Z"
											fill={nameLayer === 'tabAmazonTis' ? '#D44500' : '#69625D'}
										/>
										<path
											d="M58.4144 29.2264L34.9413 5.43536L34.7728 5.30475L33.1365 3.63697C30.9386 1.39406 27.9743 0.0920853 24.856 0H23.8643C22.0128 0.0887269 20.2055 0.602377 18.5786 1.50218C16.9518 2.40199 15.5479 3.66443 14.4731 5.19424C12.8078 7.64772 12.0518 10.6184 12.3385 13.5818C12.6251 16.5452 13.9361 19.3106 16.0399 21.3898L16.1986 21.5606L8.87006 29.0154C8.31293 29.5805 8 30.3466 8 31.1453C8 31.9441 8.31293 32.7101 8.87006 33.2753L26.4823 51.1185C27.0401 51.683 27.7963 52 28.5847 52C29.3731 52 30.1292 51.683 30.687 51.1185L47.4266 34.1594H47.6646V33.9183L48.9141 32.15H57.0162C58.7814 32.6524 59.664 30.4923 58.4144 29.2264ZM16.1887 12.4079C16.1887 10.183 17.0611 8.04915 18.614 6.47588C20.1669 4.90261 22.2731 4.01875 24.4692 4.01875C25.5582 4.01637 26.6368 4.23269 27.6428 4.65518C28.6488 5.07768 29.5621 5.69798 30.33 6.48024L30.4094 6.56061L18.6976 18.426L18.6084 18.3456C17.8394 17.5658 17.2298 16.6399 16.8146 15.621C16.3993 14.6021 16.1867 13.5102 16.1887 12.4079ZM28.5847 47.572L15.3457 34.1594H41.8137L28.5847 47.572ZM48.9141 28.1313H47.2679L46.1077 29.8091L45.7804 30.1406H13.3624L33.5431 9.70529L52.2263 28.1313H48.9141Z"
											fill={nameLayer === 'tabAmazonTis' ? '#D44500' : '#69625D'}
										/>
									</svg>
								</button>
							)}
							<DownloadLayers item={itemTis} />
							<BalloonDoubts item={itemTis} positionRight={4} />
						</S.boxIcons>
					</S.Box>
				</S.BoxRow>

				{layers &&
					layers.length > 0 &&
					layers.map((item) => (
						<S.BoxRow key={item.name_mapfile}>
							<S.Box size={1.6}>
								<SwitchToggleLayers itemLayer={item} />
							</S.Box>

							<S.Box size={7.4}>
								<S.title>{item.name}</S.title>
							</S.Box>

							<S.Box size={3}>
								<S.boxIcons>
									{checkNameInArray(item.name_mapfile) && (
										<button
											type="button"
											className={
												showModal && nameLayer === item.name_mapfile ? `layout-paint__btn active` : `layout-paint__btn`
											}
											onClick={() => openModal(item.name_mapfile)}
										>
											<svg width="18" height="14" viewBox="0 0 59 55" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path
													d="M51 38C50.6663 37.9995 50.3377 38.0812 50.0443 38.2377C49.7509 38.3942 49.5019 38.6205 49.32 38.896C48 40.8751 45 45.7194 45 48.0923C45 49.6591 45.6321 51.1618 46.7574 52.2697C47.8826 53.3776 49.4087 54 51 54C52.5913 54 54.1174 53.3776 55.2426 52.2697C56.3679 51.1618 57 49.6591 57 48.0923C57 45.7194 54 40.8751 52.67 38.896C52.489 38.6219 52.2416 38.3965 51.9501 38.24C51.6585 38.0836 51.332 38.0011 51 38Z"
													fill={nameLayer === item.name_mapfile ? '#D44500' : '#69625D'}
												/>
												<path
													d="M58.4144 29.2264L34.9413 5.43536L34.7728 5.30475L33.1365 3.63697C30.9386 1.39406 27.9743 0.0920853 24.856 0H23.8643C22.0128 0.0887269 20.2055 0.602377 18.5786 1.50218C16.9518 2.40199 15.5479 3.66443 14.4731 5.19424C12.8078 7.64772 12.0518 10.6184 12.3385 13.5818C12.6251 16.5452 13.9361 19.3106 16.0399 21.3898L16.1986 21.5606L8.87006 29.0154C8.31293 29.5805 8 30.3466 8 31.1453C8 31.9441 8.31293 32.7101 8.87006 33.2753L26.4823 51.1185C27.0401 51.683 27.7963 52 28.5847 52C29.3731 52 30.1292 51.683 30.687 51.1185L47.4266 34.1594H47.6646V33.9183L48.9141 32.15H57.0162C58.7814 32.6524 59.664 30.4923 58.4144 29.2264ZM16.1887 12.4079C16.1887 10.183 17.0611 8.04915 18.614 6.47588C20.1669 4.90261 22.2731 4.01875 24.4692 4.01875C25.5582 4.01637 26.6368 4.23269 27.6428 4.65518C28.6488 5.07768 29.5621 5.69798 30.33 6.48024L30.4094 6.56061L18.6976 18.426L18.6084 18.3456C17.8394 17.5658 17.2298 16.6399 16.8146 15.621C16.3993 14.6021 16.1867 13.5102 16.1887 12.4079ZM28.5847 47.572L15.3457 34.1594H41.8137L28.5847 47.572ZM48.9141 28.1313H47.2679L46.1077 29.8091L45.7804 30.1406H13.3624L33.5431 9.70529L52.2263 28.1313H48.9141Z"
													fill={nameLayer === item.name_mapfile ? '#D44500' : '#69625D'}
												/>
											</svg>
										</button>
									)}
									<DownloadLayers item={item} />
									<BalloonDoubts item={item} positionRight={4} />
								</S.boxIcons>
							</S.Box>
						</S.BoxRow>
					))}
			</GridBox>
		);
	}

	return (
		<S.containerCamadas>
			<div>{isReq ? <Load /> : returnHTML()}</div>
		</S.containerCamadas>
	);
}
