import React from 'react';
import { clearVector } from 'store/actions/mapActions';
import { svg } from 'lib/svgList';
import { handleCloseTooltip } from 'helpers/utils';
import * as S from './styled';

export default function TabSection({
	sectionName = '',
	styleConfig = {},
	isButtonBack = false,
	icon = '',
	isActionButton = () => {}
}) {
	return (
		<S.containerTabSection>
			<S.BoxTitle>
				<p style={styleConfig?.color !== '' ? { color: styleConfig?.color } : null}>
					{icon ? <img style={{ width: 12, marginRight: 15 }} alt={sectionName} src={icon} /> : null}
					{sectionName}
				</p>
				{isButtonBack ? (
					<button
						type="button"
						onClick={() => {
							isActionButton();
							clearVector('monitoring');
							handleCloseTooltip();
						}}
					>
						<S.imageBtnToggleOpenClose
							src={svg.icon.rollScreem}
							alt="abre e fecha opção"
							style={{ transform: 'rotate(90deg)' }}
						/>
					</button>
				) : null}
			</S.BoxTitle>

			<S.Line />
		</S.containerTabSection>
	);
}
