import React from 'react';
import { svg } from 'lib/svgList';
import { color } from 'styles/Theme';
import ItensMenu from 'components/ItensMenu';
import GenericAccordion from 'components/GenericAccordion';
import { useSelector } from 'react-redux';
import TitleTab from 'components/TitleTab';
import LoadingDotsModal from 'components/LoadingDotsModal';
import DataUser from '../../DataUsers';
import * as S from './styled';

export default function OrganizationAngCommunities() {
	const { listUsers, loadingListUsers, loading } = useSelector((state) => state.user);
	const { listOrg } = useSelector((state) => state.organizations);

	const orgList = listOrg.map((org) => {
		const userIsOrg = listUsers.filter((user) => user.organization_id === org.id && user.situation_id === 1);
		return {
			id: org.id,
			name: org.name,
			sigla: org.initials,
			users: userIsOrg
		};
	});

	// retorna a organização apenas se tiver algum usuario
	const listOrgFilter = orgList.filter((item) => item.users.length > 0);

	const data = listOrgFilter?.map((org) => {
		const managers = org?.users?.filter((f) => f.role_id === 2) || [];
		const agents = org?.users?.filter((f) => f.role_id === 3) || [];

		const initialOptions = [
			{
				title: 'GESTORES',
				icon: svg.icon.users,
				content:
					managers.length > 0 ? (
						managers.map((item) => (
							<ItensMenu
								key={item.id}
								theme="dark"
								isStyle={{ fontWeight: 500, paddingLeft: 15 }}
								title="Monitoramento"
								subTitle={`${item.firstName} ${item.lastName}`}
								content={<DataUser user={item} />}
							/>
						))
					) : (
						<p style={{ width: '100%', color: color.interface.orange }}>Esta organização não possui gestores ativos.</p>
					)
			},
			{
				title: 'AGENTES',
				icon: svg.icon.userBlack,
				content:
					agents.length > 0 ? (
						agents.map((item) => (
							<ItensMenu
								key={item.id}
								theme="dark"
								isStyle={{ fontWeight: 500, paddingLeft: 15 }}
								title="Monitoramento"
								subTitle={`${item.firstName} ${item.lastName}`}
								content={<DataUser user={item} />}
							/>
						))
					) : (
						<p style={{ width: '100%', color: color.interface.orange }}>Esta organização não possui agentes ativos.</p>
					)
			}
		];

		return {
			id: org.id,
			title: 'Organizações e Comunidades',
			subTitle: org.name,
			icon: svg.icon.usersOrange,
			content: initialOptions.map((row) => (
				<GenericAccordion
					title={row.title}
					imgTitle={row.icon}
					styleTitle={confStyled.styleTitle}
					styleLine={confStyled.styleLine}
				>
					{row.content}
				</GenericAccordion>
			))
		};
	});

	return listUsers && listUsers.length > 0 ? (
		<S.containerGeralSupporters>
			<S.lineGray />
			<S.containerList>
				<TitleTab title="Organizações e Comunidades" />
				{data.map((item) => (
					<ItensMenu
						key={item.id}
						title={item.title}
						subTitle={item.subTitle}
						icon={item.icon}
						content={item.content}
					/>
				))}
			</S.containerList>

			<LoadingDotsModal isLoading={loadingListUsers || loading} />
		</S.containerGeralSupporters>
	) : null;
}

const confStyled = {
	styleTitle: { color: color.interface.darkGray, fontWeight: '900' },
	styleLine: {
		backgroundColor: color.interface.darkGray,
		fontWeight: '900',
		height: '1px'
	}
};
