import React, { useEffect, useState } from 'react';
import TitleMenu from 'components/TitleMenu';
import TabSection from 'components/Tabs/TabSection/TabSection';
import TitleTab from 'components/TitleTab';
import * as S from './styled';

export default function ItensMenuPanel({ initialOptions = {}, title = '', confStyled = {} }) {
	const [options, setOptions] = useState([]);

	function alterVisebable(position) {
		const newArray = [...options];
		newArray[position].isOpen = !newArray[position].isOpen;
		setOptions(newArray);
	}

	useEffect(() => {
		setOptions(initialOptions);
		return () => setOptions(initialOptions);
	}, [initialOptions]);

	return (
		<S.container props={confStyled?.container || {}}>
			{title ? <TitleTab title={title} /> : null}

			{options.map((item, i) => (
				<React.Fragment key={Math.floor(Math.random() * 655369898989)}>
					<TitleMenu
						title={item.title}
						icon={item.icon}
						onClick={() => {
							item.function();
							alterVisebable(i);
						}}
						theme={confStyled?.theme || {}}
					/>

					{item.isOpen ? (
						<S.Box>
							<TitleTab title={title} />

							<TabSection
								sectionName={item.title}
								isButtonBack
								isActionButton={() => alterVisebable(i)}
								icon={item.icon}
							/>

							{item.content}
						</S.Box>
					) : null}
				</React.Fragment>
			))}
		</S.container>
	);
}
