/* eslint-disable no-plusplus */
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateSystemUsers } from 'store/actions/webGisActions';
import { checkIfAllChecked } from '../utils';
import * as S from './styled';

function UserTable({ systemUsers }) {
	const dispatch = useDispatch();

	const [currentPage, setCurrentPage] = useState(1);
	const itemsPerPage = 10;

	const handleCheckboxClick = (event, id) => {
		const updatedSystemUsers = systemUsers.map((user) => {
			if (user.id === id) {
				return {
					...user,
					isChecked: event.target.checked
				};
			}
			return user;
		});

		// atualiza o estado do array systemUsers com o item modificado
		dispatch(updateSystemUsers(updatedSystemUsers));
	};

	const handleSelectAll = (event) => {
		if (event.target.checked) {
			// atualiza o estado do array systemUsers com os itens marcados
			const updatedSystemUsers = systemUsers.map((user) => ({
				...user,
				isChecked: true
			}));

			dispatch(updateSystemUsers(updatedSystemUsers));
		} else {
			// atualiza o estado do array systemUsers com os itens desmarcados
			const updatedSystemUsers = systemUsers.map((user) => ({
				...user,
				isChecked: false
			}));

			dispatch(updateSystemUsers(updatedSystemUsers));
		}
	};

	const pageCount = Math.ceil(systemUsers.length / itemsPerPage);
	const startIndex = (currentPage - 1) * itemsPerPage;
	const endIndex = startIndex + itemsPerPage;

	const rowsToDisplay = systemUsers.slice(startIndex, endIndex);

	return (
		<S.container>
			<S.StyledTable>
				<thead>
					<tr>
						<th>
							<input type="checkbox" onChange={handleSelectAll} checked={checkIfAllChecked(systemUsers)} />
						</th>
						<th>Usuário</th>
						<th>Perfil</th>
						<th>Organização</th>
					</tr>
				</thead>
				<tbody>
					{rowsToDisplay.map((row) => (
						<tr key={row.id}>
							<td className="light-style">
								<input
									type="checkbox"
									onChange={(event) => handleCheckboxClick(event, row.id)}
									checked={row.isChecked}
								/>
							</td>
							<td className="light-bold">{`${row.firstName} ${row.lastName}`}</td>
							<td className="light-style">{row.user_role.name}</td>
							<td className="light-style">{row.user_organization.name}</td>
						</tr>
					))}
				</tbody>
				<tfoot>
					<tr>
						<td colSpan="4">
							<button
								type="button"
								disabled={currentPage === 1}
								onClick={() => setCurrentPage(currentPage - 1)}
								style={{ marginRight: 10, backgroundColor: 'transparent' }}
							>
								Anterior
							</button>
							<span>{`Página ${currentPage} de ${pageCount}`}</span>
							<button
								type="button"
								disabled={currentPage === pageCount}
								onClick={() => setCurrentPage(currentPage + 1)}
								style={{ marginLeft: 10, backgroundColor: 'transparent' }}
							>
								Próxima
							</button>
						</td>
					</tr>
				</tfoot>
			</S.StyledTable>
		</S.container>
	);
}

export default UserTable;
