import React, { memo } from 'react';
import Modal from 'components/Modal';
import { color } from 'styles/Theme';
import { getUserLocalStorage } from 'services/session';
import * as S from './styled';

function Legend({ stateLegend, setStateLegend = () => {} }) {
	const session = getUserLocalStorage();
	const roleManager = session?.user?.user_role?.role || '';

	const legend = [
		{
			id: 0,
			name: 'aprovado',
			isColor: color.interface.green,
			description: 'significa que não há nenhuma pendência no cadastro.'
		},
		{
			id: 1,
			name: 'em ajuste',
			isColor: color.auxiliary.lightCoral,
			description: 'e um usuário antigo que solicitou a troca de perfil ou organização.'
		},
		{
			id: 2,
			name: 'em análise',
			isColor: color.auxiliary.mustard,
			description: 'e um usuário recem cadastrado no sistema e esta aguardando a análise pelo gestor da organização.'
		},
		{
			id: 3,
			name: 'aguardando documentação',
			isColor: color.auxiliary.blueWater,
			description:
				'e um usuário menor de idade que esta sem acesso ao sistema e esta aguardando a aprovação pelo ADM IPAM.'
		}
	];

	const filter = roleManager === 'admin' ? legend : legend.filter((f) => f.id !== 3);

	return (
		<Modal
			styleContent={{
				width: '700px',
				maxWidth: '90%',
				zIndex: 200
			}}
			isOpen={stateLegend}
			isClose={setStateLegend}
			setIsOpen={setStateLegend}
			useBtnEnd={false}
		>
			<S.Title>Descrição</S.Title>
			{filter.map((item) => {
				const { id, isColor, name, description } = item;
				return (
					<S.ContainerItem key={id}>
						<S.Color color={isColor} />
						<p>
							O status {name} {description}
						</p>
					</S.ContainerItem>
				);
			})}
		</Modal>
	);
}

export default memo(Legend);
