/* eslint-disable no-unused-vars */
import React, { useEffect, useState, memo } from 'react';
import Map from 'components/Map';
import Drawer from 'components/Drawer';
import { vefifyUpdate, getProfiles, getRoles } from 'store/actions/userActions';
import { getUserLocalStorage } from 'services/session';
import Tabs from 'components/Tabs';
import { reqToGetLinksLayersMap } from 'store/actions/allLayersMapAction';
import { getTis } from 'store/actions/listTisAction';
import { getOrganizations } from 'store/actions/organizationsActions';
import { useDispatch, useSelector } from 'react-redux';
import SnackBarGeneral from 'components/SnackBarGeneral';
import CookieConsent from 'components/CookieConsent';
import * as S from './Platform.style';
import TabTerritory from '../TabTerritory';
import TabPanel from '../TabPanel';

function Platform() {
	const dispatch = useDispatch();

	// open = estado do snackbar
	const { isErrorReq, message } = useSelector((state) => state.allLayersMap);
	const [open, setOpen] = useState(true);

	const idUser = getUserLocalStorage()?.user?.id || '';

	useEffect(() => {
		dispatch(reqToGetLinksLayersMap());
		dispatch(getOrganizations());
		dispatch(getTis());
		dispatch(getRoles());
		dispatch(getProfiles());

		// verifica se possui atualização nos dados do usuário
		if (idUser && typeof idUser === 'number') {
			dispatch(vefifyUpdate(idUser));
		}
	}, []);

	return (
		<S.Container>
			{isErrorReq && <SnackBarGeneral open={open} setOpen={setOpen} message={message} />}

			<Drawer content={<Tabs TabTerritory={TabTerritory} TabPanel={TabPanel} />} />
			<Map />
			<CookieConsent />
		</S.Container>
	);
}

export default memo(Platform);
