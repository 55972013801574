import React from 'react';
import { svg } from 'lib/svgList';
import { actionsUsers } from 'helpers/utils';
import { useDispatch } from 'react-redux';
import * as mapActions from 'store/actions/mapActions';

import * as S from './LayerOrder.styles';

function LayerOrder({ item, indexItem, lengthArray, singleLayer, label }) {
	// reducer
	const dispatch = useDispatch();

	const handleOrder = (action) => {
		const status = action === 'up' ? 'subir' : 'descer';

		actionsUsers({
			category: `plataforma/mapa/webgis/camadas-ativas`,
			action: `Click no botão ${status}`,
			label
		});

		dispatch(mapActions.handleOrderLayer({ action, item }));
	};

	return (
		<S.Container>
			<S.ButtonOrder
				type="button"
				onClick={() => handleOrder('down')}
				disabled={indexItem === lengthArray - 1 || singleLayer}
			>
				<S.Icon src={svg.icon.rollScreem} />
			</S.ButtonOrder>

			<S.ButtonOrder type="button" onClick={() => handleOrder('up')} disabled={indexItem === 0 || singleLayer}>
				<S.Icon src={svg.icon.rollScreem} style={{ transform: 'rotate(180deg)' }} />
			</S.ButtonOrder>
		</S.Container>
	);
}

export default LayerOrder;
