import React from 'react';
import AddItem from 'components/Buttons/AddItem';
import { useSelector } from 'react-redux';

import * as S from './styled';

// type = serve para identificar o tipo do alerta que será adicionado
// managerMonitoring = boolean para saber se é o proprio usuário que está monitorando os alertas ou seu o gerente do usuário
export default function TotalAlerts({
	type = '',
	nameTotal = 'items',
	nameBtn = 'item',

	managerMonitoring = false,
	modalAdd = false,
	setModalAdd = () => {},
	onClickAdd = () => {}
}) {
	const monitoringRedux = useSelector((state) => state.monitoring);

	return (
		<S.containerTotalAlerts>
			<S.informationAndAdd>
				<S.quantity>
					Total de {nameTotal || 'items'}: {monitoringRedux.dataMonitoring.length || 0}
				</S.quantity>

				{/* a condição abaixo é para não retornar a opção de add alerta caso, o gerente esteja olhando os alertas de seus gerenciados */}
				{!managerMonitoring ? (
					<div>
						{type === 'tracker' ? (
							<S.noticeACI>Para registrar trajetos, baixe o aplicativo ACI.</S.noticeACI>
						) : (
							<AddItem
								name={nameBtn}
								onClink={() => {
									onClickAdd();
									setModalAdd(!modalAdd);
								}}
							/>
						)}
					</div>
				) : null}
			</S.informationAndAdd>
		</S.containerTotalAlerts>
	);
}
