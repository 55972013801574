/* eslint-disable no-shadow */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { generateSolidStyle, generateGradientStyle } from '../../../../utils';

const ColorView = styled.div`
	display: flex;
	width: 1.7rem;
	height: 1.7rem;
	border-radius: 5px;
	border: none;
	border: solid 2px #d9d5d2;
`;

function Preview({ red, green, blue, alpha, isGradient, points, gradientType, gradientDegree }) {
	const [style, setStyle] = useState({});

	useEffect(() => {
		if (isGradient) {
			const style = generateGradientStyle(points, gradientType, gradientDegree);

			setStyle({ background: style });

			return;
		}

		const style = generateSolidStyle(red, green, blue, alpha);

		setStyle({ backgroundColor: style });
	}, [points, gradientDegree, gradientType, isGradient, red, green, blue, alpha]);

	return (
		<>
			<ColorView style={style} />
			{/*  <div className="preview-area">
			<div className="preview-box" style={style} />
		</div> */}
		</>
	);
}

export default Preview;
