import styled from 'styled-components';
import { color } from 'styles/Theme';

export const containerGeralManagement = styled.div`
	width: 100%;
	min-height: 100%;
	/* padding-bottom: 2rem; */
	background-color: ${color.interface.beige2};
`;

export const lineGray = styled.div`
	margin-bottom: 1rem;
	width: 100%;
	height: 0.5px;
	background-color: ${color.interface.lightGray};
`;

export const containerList = styled.div`
	margin-top: ${({ props }) => (props?.marginTop ? props.marginTop : '1rem')};
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 1rem;
	padding-left: ${({ props }) => (props?.paddingLeft ? props.paddingLeft : '1rem')};
	padding-right: ${({ props }) => (props?.paddingRight ? props.paddingRight : '1rem')};
	padding-bottom: 2.5rem;
`;
