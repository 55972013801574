import styled from 'styled-components';
import { color } from 'styles/Theme';

export const containerRecoveryPasswor = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 1rem;
	/* background-color: yellow; */
`;

export const titleModal = styled.p`
	font-family: 'Nunito', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans',
		'Helvetica Neue', sans-serif;
	color: ${color.interface.orange};
	font-weight: 700;
	align-self: flex-start;
`;

export const textModal = styled.p`
	font-family: 'Nunito', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans',
		'Helvetica Neue', sans-serif;
	color: ${color.interface.gray};
`;

export const showMessageRe = styled.div`
	min-width: 300px;
	min-height: 150px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

export const textMessageReq = styled.div`
	min-width: 30%;
	min-height: 30%;

	p {
		margin-top: 1rem;
		font-family: 'Nunito', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans',
			'Helvetica Neue', sans-serif;
		color: ${color.interface.darkGray};
	}
`;

export const noticeError = styled.p`
	font-family: 'Nunito', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans',
		'Helvetica Neue', sans-serif;
	color: ${color.interface.red};
`;
