/* eslint-disable no-shadow */
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
/* eslint-disable no-unused-vars */
/* eslint-disable no-plusplus */
import React, { createContext, useContext, useEffect, useMemo, useReducer, useState } from 'react';
import { olMap } from 'components/Map/ControlMap';
import Draw, { createBox } from 'ol/interaction/Draw';
import { Modify, Snap } from 'ol/interaction';
import { Vector as VectorSource } from 'ol/source';
import { Vector as VectorLayer } from 'ol/layer';
import { Style, Fill, Stroke, Icon, Circle as CircleStyle } from 'ol/style';
import { getLayerByName } from 'store/actions/mapActions';
import { StyleLayers } from 'components/Map/Styles/js/StyleLayers';
import { getOptions } from '../../components/Map/Utils';

export const ShapeStore = createContext();

export const initialState = {
	value: 'None',
	type: 'None',
	drawShapesIsActive: false
};

function reducer(state, action) {
	switch (action.type) {
		case 'DRAW_SQUARE':
			return {
				...state,
				...{ value: 'Square', type: 'Circle', drawShapesIsActive: true }
			};
		case 'DRAW_BOX':
			return {
				...state,
				...{ value: 'Box', type: 'Circle', drawShapesIsActive: true }
			};
		case 'DRAW_START':
			return {
				...state,
				...{ value: 'Star', type: 'Circle', drawShapesIsActive: true }
			};
		case 'DRAW_HEXAGON':
			return {
				...state,
				...{ value: 'Hexagon', type: 'Circle', drawShapesIsActive: true }
			};
		case 'DRAW_CIRCLE':
			return {
				...state,
				...{ value: 'Circle', type: 'Circle', drawShapesIsActive: true }
			};
		case 'DRAW_FEATURE':
			return {
				...state,
				...{
					type: action.payload,
					value: action.payload,
					drawShapesIsActive: true
				}
			};
		case 'DRAW_RESET':
			return {
				...state,
				...{ value: 'None', type: 'None', drawShapesIsActive: false }
			};
		default:
			return state;
	}
}

export const shapesSource = new VectorSource({ wrapX: false });
export const shapesVector = new VectorLayer({
	name: 'shapes_draw',
	base: true,
	source: shapesSource,
	label: 'Draw Shapes',
	style: [StyleLayers.drawStyle, StyleLayers.drawPointStyle],
	zIndex: 99999999999
});

export function ShapeStoreProvider({ children }) {
	const [state, dispatch] = useReducer(reducer, initialState);
	const [showModalDraw, setShowModalDraw] = useState(false);

	const modify = new Modify({
		source: shapesSource,
		style: new Style({
			image: new CircleStyle({
				radius: 5,
				stroke: new Stroke({
					color: 'rgb(255, 255, 255)'
				}),
				fill: new Fill({
					color: 'rgb(0, 0, 0)'
				})
			}),
			text: new Text({
				text: 'Arraste para editar',
				font: '800 14px Nunito,sans-serif',
				fill: new Fill({
					color: 'rgb(0, 0, 0)'
				}),
				backgroundFill: new Fill({
					color: 'rgb(255, 255, 255)'
				}),
				padding: [2, 2, 2, 2],
				textAlign: 'left',
				offsetX: 15
			})
		})
	});

	const { type } = state;
	const [propsInteraction, setPropsInteraction] = useState();

	const selected = new Style({
		fill: new Fill({
			color: '#eeeeee'
		}),
		stroke: new Stroke({
			color: 'rgba(255, 255, 255, 0.7)',
			width: 2
		})
	});

	function selectStyle(feature) {
		const color = feature.get('COLOR') || '#eeeeee';
		selected.getFill().setColor(color);
		return selected;
	}

	let interaction = null;
	let snap;
	let options = {
		clickTolerance: undefined,
		features: undefined,
		source: undefined,
		snapTolerance: undefined,
		type: undefined,
		maxPoints: undefined,
		minPoints: undefined,
		finishCondition: undefined,
		style: new Style({
			fill: new Fill({
				color: 'rgba(255, 255, 255, 0.6)'
			}),
			stroke: new Stroke({
				color: 'black',
				width: 2
			}),
			image: new Icon({
				crossOrigin: 'anonymous',
				src: 'assets/icons/svg/drawplus.svg'
			})
		}),
		geometryFunction: undefined,
		geometryName: undefined,
		condition: undefined,
		freehand: undefined,
		freehandCondition: undefined,
		wrapX: undefined,
		stopClick: true
	};

	// creates unique id's
	function uniqueID() {
		return Math.floor(Math.random() * Date.now());
	}

	const drawend = (e) => {
		const id = uniqueID();
		e.feature.setProperties({
			id,
			/* name: 'yourCustomName', */
			type: 'shapes_draw'
		});

		e.feature.setId(id);
		modify.setActive(true);
	};

	const drawstart = (e) => {
		modify.setActive(false);
	};

	function addInteraction() {
		options = getOptions(Object.assign(options, propsInteraction));
		const { type, value } = state;
		if (value !== 'None') {
			interaction = new Draw({
				source: shapesSource,
				type,
				geometryFunction: options.geometryFunction,
				stopClick: true,
				style: new Style({
					fill: new Fill({
						color: 'rgba(255, 255, 255, 0.6)'
					}),
					stroke: new Stroke({
						color: 'black',
						width: 2
					}),
					image: new Icon({
						crossOrigin: 'anonymous',
						src: 'assets/icons/svg/drawplus.svg'
					})
				})
			});
			olMap()?.addInteraction(interaction);
			snap = new Snap({ source: shapesSource });
			olMap()?.addInteraction(snap);

			interaction.on('drawstart', (evt) => {
				modify.setActive(false);
			});

			interaction.on('drawend', (e) => {
				const id = uniqueID();
				e.feature.setProperties({
					id,
					type: 'shapes_draw'
				});

				e.feature.setId(id);
				modify.setActive(true);
			});
		}
	}

	useEffect(() => {
		olMap()?.addLayer(shapesVector);
	}, []);

	useEffect(() => {
		olMap()?.addInteraction(modify);
	}, []);

	useEffect(() => {
		modify.setActive(true);
		addInteraction();
	}, []);

	useEffect(() => {
		if (propsInteraction) {
			addInteraction();
		}
	}, [propsInteraction]);

	const handleChangeInteractionType = (value) => {
		const vectorDraw = getLayerByName('shapes_draw');
		if (interaction) {
			olMap()?.removeInteraction(interaction);
			olMap()?.removeInteraction(snap);
			interaction = null;
		}
		let geometryFunction;
		if (value === 'Box') {
			if (state.value === 'Box') {
				dispatch({
					type: 'DRAW_RESET'
				});
			} else {
				geometryFunction = createBox();
				dispatch({
					type: 'DRAW_BOX'
				});
				setPropsInteraction({
					source: vectorDraw.getSource(),
					type,
					geometryFunction,
					onDrawend: drawend
				});
			}
		} else if (value === 'Circle') {
			if (state.value === 'Circle') {
				dispatch({
					type: 'DRAW_RESET'
				});
			} else {
				dispatch({
					type: 'DRAW_CIRCLE'
				});
				setPropsInteraction({
					source: vectorDraw.getSource(),
					type,
					onDrawstart: drawstart,
					onDrawend: drawend
				});
			}
		} else if (value === 'Point') {
			if (state.value === 'Point') {
				dispatch({
					type: 'DRAW_RESET'
				});
			} else {
				dispatch({
					type: 'DRAW_FEATURE',
					payload: value
				});
				setPropsInteraction({
					source: vectorDraw.getSource(),
					type: value,
					onDrawstart: drawstart,
					onDrawend: drawend
				});
			}
		} else if (value === 'LineString') {
			if (state.value === 'LineString') {
				dispatch({
					type: 'DRAW_RESET'
				});
			} else {
				dispatch({
					type: 'DRAW_FEATURE',
					payload: value
				});
				setPropsInteraction({
					source: vectorDraw.getSource(),
					type: value,
					onDrawstart: drawstart,
					onDrawend: drawend
				});
			}
		} else if (value === 'Polygon') {
			if (state.value === 'Polygon') {
				dispatch({
					type: 'DRAW_RESET'
				});
			} else {
				dispatch({
					type: 'DRAW_FEATURE',
					payload: value
				});
				setPropsInteraction({
					source: vectorDraw.getSource(),
					type: value,
					onDrawstart: drawstart,
					onDrawend: drawend
				});
			}
		}
	};

	const resetInteraction = () => {
		if (interaction) {
			olMap()?.removeInteraction(interaction);
			interaction = null;
			dispatch({
				type: 'DRAW_RESET'
			});
		}
	};

	useEffect(() => {
		const keyDownHandler = (event) => {
			if (event.key === 'Escape') {
				handleChangeInteractionType(state.value);
			}
		};
		document.addEventListener('keydown', keyDownHandler);

		return () => {
			document.removeEventListener('keydown', keyDownHandler);
		};
	}, [state.value]);

	const storevalue = useMemo(
		() => ({
			state,
			dispatch,
			handleChangeInteractionType,
			resetInteraction,
			showModalDraw,
			setShowModalDraw
		}),
		[state, showModalDraw]
	);

	return <ShapeStore.Provider value={storevalue}>{children}</ShapeStore.Provider>;
}

export const useShapeStore = () => useContext(ShapeStore);
