import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import { color } from 'styles';
import { svg } from 'lib/svgList';
import { urlLanding } from 'lib/urlLanding';
import MenuUser from '../../MenuUser';
import MenuLanding from '../../MenuLanding';

function ResponsiveAppBar({ open }) {
	return (
		<div style={styleConfig.container}>
			<AppBar style={styleConfig.bar}>
				<Toolbar disableGutters>
					<Box sx={{ flexGrow: 1 }}>
						<MenuLanding open={open} />
					</Box>
					<Box sx={{ flexGrow: 1 }}>
						<a href={urlLanding}>
							<img style={{ width: 110 }} alt="Logo" src={svg.img.logoSomaiLight} />
						</a>
					</Box>
					<MenuUser open={open} />
				</Toolbar>
			</AppBar>
		</div>
	);
}

const styleConfig = {
	container: {
		background: color.interface.white,
		padding: '0px 1px 1px 1px'
	},
	bar: {
		display: 'flex',
		position: 'inherit',
		background: color.interface.orange,
		boxShadow: '1px 1px 1px 1px rgba(0, 0, 0, 0.2)',
		borderRadius: '15px 15px 0px 0px',
		zIndex: 2
	}
};
export default ResponsiveAppBar;
