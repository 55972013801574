/* eslint-disable no-unused-vars */
import React, { memo } from 'react';

import Load from 'components/Load/Load';
import Consult from './Consult';
import * as S from './styled';

function OptionsMonitoring({ type = '', listDataUsers, loadingSystemUsers }) {
	return (
		<S.containerOption>
			{loadingSystemUsers ? <Load /> : <Consult typeMonitoringAndMenu={type} listDataUsers={listDataUsers} />}
		</S.containerOption>
	);
}

export default memo(OptionsMonitoring);
