import { measure } from '../typesActions/types';
import { dateToBR, timeToBR, filterDeleted } from 'helpers/utils';

const initialState = {
	loadingSaveMeasure: false,
	errorSave: false,
	messageErrorSave: '',
	loadingRead: false,
	list: [],
	errorRead: false,
	messageErrorRead: '',
	loadingDelete: false,
	errorDelete: false,
	messageErrorDelete: '',
	selectedMeasure: {}
};

const updateRegisteredAt = (item) => {
	const date = new Date(item.created_at);
	item.registeredAt = {
		date: dateToBR(date),
		time: timeToBR(date)
	};
	return item;
};

export default function measureReducer(state = initialState, action) {
	const { type, payload } = action;
	const { errors } = payload || {};
	const { message } = errors || {};

	switch (type) {
		// MEASURE_SAVE
		case measure.MEASURE_SAVE_REQUEST:
			return {
				...state,
				loadingSaveMeasure: true,
				errorSave: false,
				messageErrorSave: ''
			};
		case measure.MEASURE_SAVE_SUCCESS:
			updateRegisteredAt(payload);

			return {
				...state,
				loadingSaveMeasure: false,
				list: [...state.list, payload]
			};
		case measure.MEASURE_SAVE_FAILURE:
			return {
				...state,
				loadingSaveMeasure: false,
				errorSave: true,
				messageErrorSave: message
			};

		// MEASURE_READ
		case measure.MEASURE_READ_REQUEST:
			return {
				...state,
				loadingRead: true,
				errorRead: false,
				messageErrorRead: ''
			};
		case measure.MEASURE_READ_SUCCESS:
			const { list } = state;
			const { measures } = payload;
			list.forEach((item) => {
				const found = measures.some((w) => w.hash === item.hash);
				if (!found && !item.id) measures.push(item);
			});
			measures.forEach((w, i) => {
				const date = new Date(w.created_at);
				measures[i].registeredAt = {
					date: dateToBR(date),
					time: timeToBR(date)
				};
			});

			return {
				...state,
				loadingRead: false,
				list: measures
			};
		case measure.MEASURE_READ_FAILURE:
			return {
				...state,
				loadingRead: false,
				errorRead: true,
				messageErrorRead: message
			};

		// MEASURE_DELETE
		case measure.MEASURE_DELETE_REQUEST:
			return {
				...state,
				errorDelete: false,
				loadingDelete: true,
				messageErrorDelete: ''
			};
		case measure.MEASURE_DELETE_SUCCESS:
			return {
				...state,
				loadingDelete: false,
				list: filterDeleted(state.list, payload.hashis)
			};
		case measure.MEASURE_DELETE_FAILURE:
			return {
				...state,
				errorDelete: true,
				loadingDelete: false,
				messageErrorDelete: message
			};

		// ON_MAP
		case measure.MEASURE_ON_MAP:
			return {
				...state,
				selectedMeasure: payload
			};

		default:
			return state;
	}
}
