/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/jsx-no-constructed-context-values */
import { satelliteBing } from 'components/Map/BaseMaps';
import { olMap } from 'components/Map/ControlMap';
import { useContext, createContext, useState } from 'react';

const AttributionContext = createContext();

function AttributionContextProvider({ children }) {
	const [attributions, setAttributions] = useState();

	const handlerAttribution = () => {
		const layerActive = olMap()
			?.getLayers()
			.getArray()
			.find((layer) => layer.getVisible());
		if (layerActive) {
			// console.log(layerActive.get('name'));
			console.log(satelliteBing.getSource().getAttributions());
			satelliteBing.getSource().getAttributions();
			const source = layerActive.getSource();
			console.log(source.getAttributions());
			const attribution = source.getAttributions()();
			setAttributions(attribution);
		}
	};

	return (
		<AttributionContext.Provider value={{ attributions, setAttributions, handlerAttribution }}>
			{children}
		</AttributionContext.Provider>
	);
}

export { AttributionContext, AttributionContextProvider };

export const useAttributionContext = () => useContext(AttributionContext);
