import React from 'react';
import TerritoryDataTableTis from 'components/TerritoryData/TerritoryDataTable';
import TerritoryDataTableAmazon from 'components/TerritoryData/TerritoryDataTableAmazon';
import GenericAccordion from 'components/GenericAccordion';
import { isEmptyObject } from 'helpers/utils';
import { color } from 'styles/Theme';
import { useSelector } from 'react-redux';

export default function TerritoryData({ stateSelectedIndex, territory }) {
	const { informationTi } = useSelector((state) => state.tiSelectedTabTi);
	return (
		<GenericAccordion
			title="DADOS DO TERRITÓRIO"
			keyAccordion="dadosTerritorio"
			styleTitle={{
				color: `${color.interface.darkGray}`,
				fontFamily: 'Nunito',
				fontStyle: 'normal',
				fontWeight: '800',
				lineHeight: '0.875rem',
				fontSize: '0.625rem',
				letterSpacing: '0.1em',
				textTransform: 'uppercase'
			}}
		>
			{!isEmptyObject(informationTi) && territory === 'tis' && <TerritoryDataTableTis data={informationTi} />}

			{!isEmptyObject(stateSelectedIndex) && territory === 'amazonia' && (
				<TerritoryDataTableAmazon stateSelected={stateSelectedIndex} />
			)}
		</GenericAccordion>
	);
}
