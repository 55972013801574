import React from 'react';
import { Vector } from 'ol/layer';
import LegendVector from './Legends/LegendVector';
import LegendDeforestation from './Legends/LegendDeforestation';

const getLegend = (layer) => {
	const label = layer.get('label');
	const nameMapFile = layer.get('name');
	// VECTOR

	if (layer instanceof Vector) {
		return <LegendVector layer={layer} />;
	}

	if (nameMapFile === 'desmatamento') {
		return <LegendDeforestation layer={layer} />;
	}
	// para legenda dos heatmaps
	const low = 'Baixa';
	const high = 'Alta';

	const source = layer.getSource();

	// WMS
	const url = `${source?.getLegendUrl()}&SLD_VERSION=1.1.0&name=${label}&low=${low}&high=${high}`;

	return <img src={url} alt={`Legenda ${label}`} style={{ marginLeft: -6, marginRight: 5 }} />;
};

export default getLegend;
