import React, { useState } from 'react';
// import { svg } from 'lib/svgList';
import InputRegister from 'components/InputRegister';
import Grid from '@mui/material/Grid';
import { isColorStatus, especialCharMask } from 'helpers/utils';
import HelpIcon from '@mui/icons-material/Help';
import { color } from 'styles/Theme';
import Legend from './Legend';
import UpdateUsersADM from './UpdateUsersADM';
import * as S from './styled';

export default function ListUsers({ users = {} }) {
	const [isUser, setIsUser] = useState('');
	const [textFilter, setTextFilter] = useState('');
	const [stateModal, setStateModal] = useState(false);
	const [stateLegend, setStateLegend] = useState(false);

	if (textFilter) {
		users = users.filter((i) => {
			const name = especialCharMask(`${i.firstName} ${i.lastName}`);
			const nameOrg = especialCharMask(`${i.user_organization.name} - ${i.user_organization.initials}`);
			const email = especialCharMask(i.email);
			const situation = especialCharMask(i?.situation?.name);
			const role = especialCharMask(i?.user_role?.name);

			const newTextFilter = especialCharMask(textFilter);
			return (
				name.includes(newTextFilter) ||
				role.includes(newTextFilter) ||
				nameOrg.includes(newTextFilter) ||
				email.includes(newTextFilter) ||
				situation.includes(newTextFilter)
			);
		});
	}

	const handleEdit = (user) => {
		setStateModal(true);
		setIsUser(user);
	};

	return (
		<S.ContainerList>
			<S.ContainerSubTitle>
				<p>Total de usuários: {users?.length || 0}</p>

				<S.ButtonInfo
					onClick={() => {
						setStateLegend(true);
					}}
				>
					<HelpIcon sx={{ color: color.interface.orange, fontSize: 25 }} />
				</S.ButtonInfo>
			</S.ContainerSubTitle>
			<S.BoxFilter>
				<InputRegister type="text" placeholder="Pesquisar..." value={textFilter} setValue={setTextFilter} required />
			</S.BoxFilter>

			{users &&
				users.map((user) => (
					<S.Button key={user.id} onClick={() => handleEdit(user)}>
						<Grid container style={{ alignItems: 'center' }}>
							<Grid item xs={9} md={9}>
								<Grid item xs={12} md={12}>
									<div className="item-users-list">
										<span className="description-name">Nome:</span>
										<p style={configStyle.p}>{` ${user.firstName} ${user.lastName}`}</p>
									</div>
								</Grid>
								<Grid item xs={12} md={12}>
									<div className="item-users-list">
										<span className="description-name">Email:</span> <p style={configStyle.p}>{user.email}</p>
									</div>
								</Grid>
								<Grid item xs={12} md={12}>
									<div className="item-users-list">
										<span className="description-name">Tipo:</span> {user?.user_role?.name || 'Não possui'}
									</div>
								</Grid>
							</Grid>
							<Grid item xs={3} md={3}>
								<Grid item xs={12} md={12} style={{ marginBottom: 2 }}>
									<span className="description-name">Situação</span>
								</Grid>
								<Grid item xs={12} md={12}>
									<div
										style={{
											backgroundColor: isColorStatus(user?.situation?.id),
											color: 'white',
											paddingTop: 2,
											paddingBottom: 3,
											borderRadius: 10,
											fontSize: 12
										}}
									>
										{user?.situation?.name || 'Não informada'}
									</div>
								</Grid>
							</Grid>

							<Grid item xs={12} md={12} style={{ marginTop: 3 }} className="item-users-list">
								<span className="description-name">Organização:</span>{' '}
								<p style={configStyle.p}>
									{`${user?.user_organization?.name} - ${user?.user_organization?.initials}` || 'Não possui'}
								</p>
							</Grid>
						</Grid>
					</S.Button>
				))}
			<UpdateUsersADM user={isUser} stateModal={stateModal} setStateModal={setStateModal} />
			<Legend stateLegend={stateLegend} setStateLegend={setStateLegend} />
		</S.ContainerList>
	);
}

const configStyle = {
	p: {
		maxWidth: '100%',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap'
	}
};
