import React, { useEffect, useState } from 'react';
import GenericAccordion from 'components/GenericAccordion';
import { svg, png } from 'lib/svgList';
import { color } from 'styles/Theme';
import { useSelector, useDispatch } from 'react-redux';
import SwitchToggle from 'components/Buttons/SwitchToggleGeneral';
import { addVectorPoint } from 'store/actions/layersAction/addPointLayerOnMap/addPointLayerOnMap';
import { StyleLayers } from 'components/Map/Styles/js/StyleLayers';
import { removeLayerFromMap, setActivatedLayers, getMaxZIndex } from 'store/actions/mapActions';
import { handleCloseTooltip } from 'helpers/utils';
import DeforestationBarGraph from '../../Graphs/DeforestationGraph/DeforestationBarGraph';
import * as S from './styled';

export default function DeforestationAlert() {
	const dispatch = useDispatch();
	const { data: alertDeforestation } = useSelector((state) => state.deforestationDeterLast30Days);
	const [toggle, setToggle] = useState(false);
	const [data, setData] = useState([]);

	const lastZIndex = getMaxZIndex() + 1;

	const objPointConfig = {
		nameVector: 'tideforestationlast30days',
		label: 'Alerta de Desmatamento - DETER',
		zIndex: lastZIndex,
		icon: png.desmatamentoIlegalOrange,
		style: [StyleLayers.pointBackground, StyleLayers.focoDesmatamento],
		onVisiable: toggle
	};

	// esse titulo vai para as props do tooltip
	const title = 'Alerta de Desmatamento - DETER';

	// coloca titulo nos alertas para aparecer no tooltip
	useEffect(() => {
		if (alertDeforestation.length > 0) {
			const newData = alertDeforestation.map((item) => ({ ...item, title }));
			setData(newData);
		}
	}, [alertDeforestation]);

	// add layer ao mapa quando o toggle é true e remove a layer quando false (e também dispara setActivatedLayers, pra remover a layer da legenda)
	useEffect(() => {
		dispatch(addVectorPoint(data, objPointConfig));
		if (!toggle) {
			handleCloseTooltip();
		}
	}, [toggle]);

	// remove layer quando comp é destruido
	useEffect(
		() => () => {
			removeLayerFromMap(objPointConfig.nameVector);
			dispatch(setActivatedLayers());
		},
		[]
	);

	return (
		<GenericAccordion
			imgTitle={svg.icon.desmatamentoIlegalOrange}
			title="Alertas do desmatamento"
			styleTitle={{
				color: `${color.interface.darkGray}`,
				fontWeight: '300'
			}}
		>
			<S.container>
				<S.boxToggle>
					<SwitchToggle
						isChecked={toggle}
						setIsChecked={data.length > 0 ? setToggle : () => {}} // caso o dado seja vazio, não autorizar alterar o toggle
					/>
					{data.length === 0 ? (
						<p>
							{' '}
							<b>Não há registro</b> de desmatamento nos últimos 30 dias.
						</p>
					) : (
						<p>Mostrar alertas de desmatamento nos últimos 30 dias.</p>
					)}
				</S.boxToggle>

				<S.containerAccordionText>
					<h4>ALERTA DE DESMATAMENTO POR MÊS</h4>
					<p>(Área Desmatada (ha) X mês)</p>
				</S.containerAccordionText>

				<S.boxGraph>
					<DeforestationBarGraph />
				</S.boxGraph>
			</S.container>
		</GenericAccordion>
	);
}
