/* eslint-disable no-unused-vars */
import React from 'react';
import { actionsUsers } from 'helpers/utils';
import { useMeasureStore } from 'services/map/measure.context';
import { useShapeStore } from 'services/map/shapes.context';
import { useColorsContext } from 'services/color.context';
import * as S from '../../ControlButton/styles';

function RegularPolygon() {
	const { state, handleChangeInteractionType, showModalDraw, setShowModalDraw } = useShapeStore();
	const { value } = state;

	const { showModal, setShowModal } = useColorsContext();
	const { state: stateMeasure, resetInteraction, showModalMeasure, setShowModalMeasure } = useMeasureStore();
	const actionButton = value === 'Box' ? 'desativar' : 'ativar';

	const HandleControl = () => {
		if (stateMeasure.drawMeasuresIsActive) {
			resetInteraction();
		}
		if (showModalMeasure) {
			setShowModalMeasure(false);
		}
		if (showModal) {
			setShowModal(false);
		}
		if (showModalDraw) {
			setShowModalDraw(false);
		}
		handleChangeInteractionType('Box');

		actionsUsers({
			category: `plataforma/mapa/webgis`,
			action: `Click no botão ${actionButton}`,
			label: 'Desenhar Polígono regular'
		});
	};

	return (
		<S.Container>
			<button
				id="type"
				type="button"
				data-title="Desenhar Polígono regular"
				onClick={HandleControl}
				className={value === 'Box' ? 'active sm__tooltip' : 'sm__tooltip'}
			>
				<svg width="22" height="22" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M56 11H49V4C49 3.46957 48.7893 2.96086 48.4142 2.58579C48.0391 2.21071 47.5304 2 47 2C46.4696 2 45.9609 2.21071 45.5858 2.58579C45.2107 2.96086 45 3.46957 45 4V11H38C37.4696 11 36.9609 11.2107 36.5858 11.5858C36.2107 11.9609 36 12.4696 36 13C36 13.5304 36.2107 14.0391 36.5858 14.4142C36.9609 14.7893 37.4696 15 38 15H45V22C45 22.5304 45.2107 23.0391 45.5858 23.4142C45.9609 23.7893 46.4696 24 47 24C47.5304 24 48.0391 23.7893 48.4142 23.4142C48.7893 23.0391 49 22.5304 49 22V15H56C56.5304 15 57.0391 14.7893 57.4142 14.4142C57.7893 14.0391 58 13.5304 58 13C58 12.4696 57.7893 11.9609 57.4142 11.5858C57.0391 11.2107 56.5304 11 56 11Z"
						fill="#291F17"
					/>
					<path
						d="M47 29C46.4696 29 45.9609 29.2107 45.5858 29.5858C45.2107 29.9609 45 30.4696 45 31V46.92L44.92 47H13.08L13 46.92V15.08L13.08 15H29C29.5304 15 30.0391 14.7893 30.4142 14.4142C30.7893 14.0391 31 13.5304 31 13C31 12.4696 30.7893 11.9609 30.4142 11.5858C30.0391 11.2107 29.5304 11 29 11H13C11.9518 11.0396 10.9572 11.4737 10.2155 12.2155C9.47374 12.9572 9.0396 13.9518 9 15V47C9.0396 48.0482 9.47374 49.0428 10.2155 49.7845C10.9572 50.5263 11.9518 50.9604 13 51H45C46.0482 50.9604 47.0428 50.5263 47.7845 49.7845C48.5263 49.0428 48.9604 48.0482 49 47V31C49 30.4696 48.7893 29.9609 48.4142 29.5858C48.0391 29.2107 47.5304 29 47 29Z"
						fill="#291F17"
					/>
				</svg>
			</button>
		</S.Container>
	);
}

export default RegularPolygon;
