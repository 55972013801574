/* eslint-disable no-unused-vars */
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import GeoJSON from 'ol/format/GeoJSON';
import { olMap } from 'components/Map/ControlMap/index';
import { StyleLayers } from 'components/Map/Styles/js/StyleLayers';
import { color } from 'styles/Theme';
import { getLayerByName, onMouseOver, setActivatedLayers } from 'store/actions/mapActions';
import { setProgress } from 'store/actions/layersAction/toggleLayersTabTi/toggleLayersTabTi';

const sourceTis = new VectorSource({ wrapX: false });

const vectorTis = new VectorLayer({
	name: 'tabAmazonTis',
	source: sourceTis,
	style: StyleLayers.tabAmazonTis,
	label: 'Terras Indígenas',
	zIndex: 95000,
	haveTitle: false // Se mostra ou não o titulo da legenda
});

const updateSourceTis = (tis) => {
	const collection = {
		type: 'FeatureCollection',
		features: []
	};

	tis.forEach((ti) => {
		collection.features.push({
			type: 'Feature',
			geometry: ti.geom,
			properties: {
				type: 'tabAmazonTis',
				props: ti
			}
		});
	});

	const featuresTis = new GeoJSON({
		featureProjection: 'EPSG:3857'
	}).readFeatures(collection);

	sourceTis.clear(); // Limpa os recursos existentes
	sourceTis.addFeatures(featuresTis); // Adiciona os novos recursos
};

export const tabAmazonActionTis = (tis, visible) => async (dispatch) => {
	if (!tis || tis.length === 0) return;

	const isVectorExist = getLayerByName('tabAmazonTis');

	if (!isVectorExist) await olMap().addLayer(vectorTis);

	vectorTis.setVisible(visible);

	dispatch(setProgress(sourceTis, vectorTis.get('name'), false));

	if (visible) {
		updateSourceTis(tis);

		dispatch(setActivatedLayers());
	}

	// efeito de onMouseOver
	onMouseOver('tabAmazonTis', color.interface.darkGreen, `${color.interface.white}80`);
};
