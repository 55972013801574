import React from 'react';
import CookieConsent from 'react-cookie-consent';

import { color } from 'styles';

function ConsentCookie() {
	return (
		<CookieConsent
			location="bottom"
			buttonText="ENTENDO"
			cookieName="cookieConsent"
			style={{
				background: color.interface.orange,
				justifyContent: 'center',
				alignItems: 'center',
				borderRadius: '10px 10px 0px 0px',
				fontSize: 14,
				zIndex: 6000
			}}
			buttonStyle={{
				color: '#4e503b',
				fontSize: '13px',
				borderRadius: 10
			}}
			expires={150}
		>
			<span>
				Usamos cookies para garantir uma melhor experiência de navegação, bem como cookies de rastreamento para entender
				como você interage com o nosso site.
			</span>
		</CookieConsent>
	);
}
export default ConsentCookie;
