import React from 'react';
import * as S from './styled';
import { svg } from '../../../lib/svgList';

function BoxItemSelected({ text = 'limpar seleção', onClick = () => {}, style = undefined, styleText = undefined }) {
	return (
		<S.containerBoxItemSelected>
			<button style={style} type="button" onClick={() => onClick()}>
				<img alt="close" src={svg.icon.add} style={{ transform: 'rotate(45deg)' }} />
				<p style={styleText}>{text}</p>
			</button>
		</S.containerBoxItemSelected>
	);
}

export default BoxItemSelected;
