import { api } from 'services/api';
import { headers } from 'helpers/utils';
import { getUserLocalStorage } from 'services/session';
import { measure } from '../typesActions/types';

// Actions

export const saveMeasure = (values) => async (dispatch) => {
	dispatch({ type: measure.MEASURE_SAVE_REQUEST });

	try {
		const response = await api.post('/measure', values, {
			headers: headers()
		});
		dispatch({ type: measure.MEASURE_SAVE_SUCCESS, payload: response.data });
	} catch (error) {
		const { response } = error;
		if (response && response.status === 400) {
			const { message } = response.data;
			dispatch({
				type: measure.MEASURE_SAVE_FAILURE,
				payload: { errors: { message } }
			});
		} else {
			dispatch({
				type: measure.MEASURE_SAVE_FAILURE,
				payload: {
					errors: { message: 'Sem permissão para salvar' }
				}
			});
		}
	}
};

export const readMeasures = () => async (dispatch) => {
	const { user } = getUserLocalStorage();

	dispatch({ type: measure.MEASURE_READ_REQUEST });

	try {
		const response = await api.get(`/measures/user`, {
			headers: headers()
		});
		dispatch({ type: measure.MEASURE_READ_SUCCESS, payload: response.data });
	} catch (error) {
		const { response } = error;
		if (response && response.status === 400) {
			const { message } = response.data;
			dispatch({
				type: measure.MEASURE_READ_FAILURE,
				payload: { errors: { message } }
			});
		} else {
			dispatch({
				type: measure.MEASURE_READ_FAILURE,
				payload: {
					errors: { message: 'Sem permissão para salvar' }
				}
			});
		}
	}
};

export const destroyMeasures = (hashis) => async (dispatch) => {
	const { user } = getUserLocalStorage();

	dispatch({ type: measure.MEASURE_DELETE_REQUEST });

	try {
		await api.delete('/measures', {
			data: { hashis, userId: user.id },
			headers: headers()
		});
		dispatch({ type: measure.MEASURE_DELETE_SUCCESS, payload: { hashis } });
	} catch (error) {
		const { response } = error;
		if (response && response.status === 400) {
			const { message } = response.data;
			dispatch({
				type: measure.MEASURE_DELETE_FAILURE,
				payload: { errors: { message } }
			});
		} else {
			dispatch({
				type: measure.MEASURE_DELETE_FAILURE,
				payload: {
					errors: { message: 'Sem permissão para salvar' }
				}
			});
		}
	}
};

export const onMapMeasure = (data) => ({
	type: measure.MEASURE_ON_MAP,
	payload: data
});
