import React, { useEffect, memo } from 'react';
import { destroy, closeMessageAndError } from 'store/actions/organizationsActions';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'components/Modal';
import * as S from './styled';

function Destroy({ org = [], stateModal, setStateModal = () => {}, setIsitem }) {
	const dispatch = useDispatch();

	const { loadingDestroy, MessageMonitoring } = useSelector((state) => state.organizations);

	const handleClose = () => {
		setStateModal(false);
		setIsitem([]);
	};

	const formData = {
		id: org.id
	};

	const sendData = (e) => {
		e.preventDefault();
		dispatch(destroy(formData)).then(() => handleClose());
	};

	useEffect(() => {
		if (MessageMonitoring !== '') {
			setTimeout(() => {
				dispatch(closeMessageAndError());
				setStateModal(false);
			}, 6000);
		}
	}, [MessageMonitoring]);
	return (
		<Modal styleContent={{ zIndex: 200 }} isOpen={stateModal} setIsOpen={handleClose} useBtnEnd={false}>
			<S.Container>
				{MessageMonitoring ? (
					<p style={{ display: 'flex', justifyContent: 'center', padding: 20 }}>{MessageMonitoring}</p>
				) : (
					<form onSubmit={sendData} style={styledConfg.form}>
						<p style={{ marginBottom: 10 }}>Deseja realmente apagar a organização?</p>
						<S.BoxButton>
							<S.ButtonCancel type="button" onClick={() => handleClose()} disabled={loadingDestroy}>
								Cancelar
							</S.ButtonCancel>
							<S.Button type="submit" disabled={loadingDestroy}>
								{loadingDestroy ? 'Aguarde...' : 'Confirmar'}
							</S.Button>
						</S.BoxButton>
					</form>
				)}
			</S.Container>
		</Modal>
	);
}

const styledConfg = {
	form: {
		width: '100%',
		padding: 10
	}
};
export default memo(Destroy);
