import React from 'react';
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import Load from 'components/Load/Load';
import * as S from './styled';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

// MODELO DE LABEL
// const labels = [
// 	'January',
// 	'February',
// 	'March',
// 	'April',
// 	'May',
// 	'June',
// 	'July'
// ];

// MODELO DE DATASETARRAY
// data = [
// 	{
// 		label: 'Dataset 1',
// 		data: [10, 20, 30, 5],
// 		borderColor: 'rgb(255, 99, 132)',
// 		backgroundColor: 'rgba(255, 99, 132, 0.5)'
// 	},
// ]

export default function LineGraph({
	title = 'Ipam',
	labelsProps = [],
	datasetsProps = [],
	isLoading = false,
	useLegend = true,
	useLegendRound = false
}) {
	const options = {
		responsive: true,
		plugins: {
			legend: {
				display: useLegend,
				position: 'top'
			},
			title: {
				display: false,
				text: title
			}
		}
	};

	const data = {
		labels: [...labelsProps],
		datasets: [...datasetsProps]
	};

	function returnLineGraph() {
		if (isLoading) {
			return <Load />;
		}
		return datasetsProps.length !== 0 ? (
			<Line options={options} data={data} />
		) : (
			<p className="notes"> Não há dados para gerar gráfico</p>
		);
	}

	function returnlabel(array) {
		return array.map((item) => (
			<S.ballContainer key={Math.random() * 1000}>
				<S.ball color={item.backgroundColor} />
				<p>{item.label}</p>
			</S.ballContainer>
		));
	}
	return (
		<S.container>
			{useLegendRound && <S.roundContainer>{returnlabel(datasetsProps)}</S.roundContainer>}
			{returnLineGraph()}
		</S.container>
	);
}
