import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import LineGraph from 'components/Graphs/LineGraph/LineGraph';
import { returnDataGraph, isEmptyObject } from 'helpers/utils';
import { fireFocusByMonth } from 'store/actions/fireFocusByMonthAction ';

export default function FireMonthLineGraph() {
	const dispatch = useDispatch();
	const { fireMonth, isLoading: loadingFireMonthLineGraph } = useSelector((state) => state.fireFocusByMonth);
	const { tiSelected } = useSelector((state) => state.tiSelectedTabTi);

	const [monthCurrentYear, setMonthCurrentYear] = useState([]);
	const [monthPreviousYear, setMonthPreviousYear] = useState([]);
	const [countCurrentYear, setCountCurrentYear] = useState([]);
	const [countPreviousYear, setCountPreviousYear] = useState([]);

	// useEffect para observar a tiSelected, ou seja fará o dispatch se houve ti selecionada
	useEffect(() => {
		if (!isEmptyObject(tiSelected)) {
			dispatch(fireFocusByMonth(tiSelected.value));
		}
	}, [tiSelected]);

	useEffect(() => {
		if (fireMonth?.currentYear?.length > 0 || fireMonth?.previousYear?.length > 0) {
			setMonthCurrentYear(fireMonth.currentYear.map((item) => item.month));
			setMonthPreviousYear(fireMonth.previousYear.map((item) => item.month));
			setCountCurrentYear(fireMonth.currentYear.map((item) => item.count));
			setCountPreviousYear(fireMonth.previousYear.map((item) => item.count));
		}
	}, [fireMonth]);

	const monthCurrent = monthCurrentYear;
	const monthPrevious = monthPreviousYear;

	const lineData = [
		{
			label: '2022',
			data: countCurrentYear,
			borderColor: '#D44500',
			backgroundColor: '#D44500'
		},
		{
			label: '2021',
			data: countPreviousYear,
			borderColor: '#21543D',
			backgroundColor: '#21543D'
		}
	];
	return (
		<LineGraph
			labelsProps={(monthCurrent, monthPrevious)}
			datasetsProps={returnDataGraph(fireMonth, lineData)}
			isLoading={loadingFireMonthLineGraph}
			useLegend={false}
			useLegendRound
		/>
	);
}
