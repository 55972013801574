/* eslint-disable no-unused-vars */
import React from 'react';

function Input({ value, label, type = 'text', onChange, onFocus, onBlur, classes }) {
	return (
		<input className={`${type}-input input`} value={value} onChange={onChange} onFocus={onFocus} onBlur={onBlur} />
	);
}

export { Input };
