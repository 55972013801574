/* eslint-disable no-unused-vars */
import React from 'react';

import { useSelector } from 'react-redux';
import NavAccordion from 'components/NavAccordion';
import TerritoryData from './TerritoryData';
import Threats from './Threats';
import ThreatAndAlerts from './ThreatAndAlerts';
import * as S from './styled';
import Enterprise from './Enterprises';
import HumanOcupation from './HumanOcupation';
import Environment from './Environment';
import Climate from './Climate';

export default function Statistics({ stateSelectedIndex, territory }) {
	const { allLayers } = useSelector((state) => state.allLayersMap);

	return (
		<NavAccordion title="ESTATÍSTICAS" isInitiOpen>
			{allLayers?.length > 0 ? (
				<S.containerAccordionOutside>
					<TerritoryData stateSelectedIndex={stateSelectedIndex} territory={territory} />
					<Threats />
					<ThreatAndAlerts />
					<Enterprise />
					<HumanOcupation />
					<Environment />
					<Climate />
				</S.containerAccordionOutside>
			) : null}
		</NavAccordion>
	);
}
