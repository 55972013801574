/* eslint-disable no-unused-vars */
/* eslint-disable no-plusplus */
import { transform } from 'ol/proj';
import { DragZoom } from 'ol/interaction';
import { always } from 'ol/events/condition';
import { Style, Fill, Stroke } from 'ol/style';

export const mapCenter = [-62.7319245, -6.9033513];
export const mapZoom = 5.2;

const width = window.innerWidth;

// olmap
export const olMap = () => (document.getElementById('mapSomai') ? document.getElementById('mapSomai').data : null);

// zoom para um layer específico
export const zoomToExtentLayer = (extent, animationTime = 1000, padding = [150, 300, 150, 500], maxZoom = 15) => {
	const olmap = olMap();
	if (extent && olmap) {
		olmap.getView().fit(extent, {
			duration: animationTime,
			size: olmap.getSize(),
			maxZoom,
			padding
		});
	}
};

export const zoomToFeature = (
	feature,
	duration = 1000,
	padding = [width / 5, width / 5, width / 5, width / 5],
	maxZoom = 15
) => {
	const olmap = Map();
	olmap?.getView().fit(feature.getGeometry(), {
		duration,
		padding,
		maxZoom
	});
};

// zoom para controlar mapa
export const zoomInOutMap = (type) => {
	const olmap = olMap();
	if (olmap) {
		if (type === 'In') {
			olmap.getView().animate({
				zoom: olmap.getView().getZoom() + 1,
				duration: 250
			});
		} else {
			olmap.getView().animate({
				zoom: olmap.getView().getZoom() - 1,
				duration: 250
			});
		}
	}
};

const dragZoom = new DragZoom({
	condition: always,
	className: 'somai-drag_box',
	style: new Style({
		fill: new Fill({
			color: 'rgba(255, 255, 255, 0.6)'
		}),
		stroke: new Stroke({
			color: 'black',
			width: 6,
			lineDash: [10, 10]
		})
	})
});

export const DragToZoomInMap = () => {
	// dragZoom();
	const olmap = olMap();
	if (olmap) {
		olmap.addInteraction(dragZoom);
	}
};

export const applyLupa = () => {
	const olmap = olMap();
	if (olmap) {
		olmap.addInteraction(dragZoom);
	}
};

export const removeLupa = (e) => {
	const olmap = olMap();
	if (olmap) {
		olmap.removeInteraction(dragZoom);
	}
};

// zoom para posição inicial do mapa
export const zoomToExtentMap = (animationTime = 1500) => {
	const olmap = olMap();
	if (olmap)
		olmap.getView().animate({
			center: transform(mapCenter, 'EPSG:4326', 'EPSG:3857'),
			zoom: mapZoom,
			duration: animationTime
		});
};

// zoom para posição referenciada
export const zoomToRowMonitoring = (coordinate, zoom, duration = 1500) => {
	const olmap = olMap();
	if (olmap)
		olmap.getView().animate({
			center: transform(coordinate, 'EPSG:4326', 'EPSG:3857'),
			zoom,
			duration
		});
};

// Para fechar o tooltip apos atualizar a lista
export const clearOverlay = () => {
	const olmap = olMap();
	if (olmap) {
		olmap
			.getOverlays()
			.getArray()
			.forEach((overlay) => {
				overlay.setPosition(null);
			});
	}
};
