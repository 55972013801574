import React, { createContext, useContext, useMemo, useReducer, useState } from 'react';

export const ColorsContext = createContext();

export const initialState = {
	latestcolors: localStorage.getItem('latestcolors') ? JSON.parse(localStorage.getItem('latestcolors')) : [],
	latestgradients: localStorage.getItem('latestgradients') ? JSON.parse(localStorage.getItem('latestgradients')) : [],
	currentStyleItems: []
};

function reducer(state, action) {
	switch (action.type) {
		case 'ADD_RECENT_COLOR': {
			const newItem = action.payload;

			let latestcolors = [];

			const existItem = state.latestcolors.find((item) => item === newItem);

			if (existItem) {
				const filters = state.latestcolors.filter((item) => item !== newItem);

				latestcolors = [newItem, ...filters];
			} else {
				let newArray;
				if (state.latestcolors.length === 3) {
					const removed = state.latestcolors.slice(0, -1);
					newArray = [newItem, ...removed];
				} else {
					newArray = [newItem, ...state.latestcolors];
				}

				latestcolors = newArray;
			}

			localStorage.setItem('latestcolors', JSON.stringify(latestcolors));
			return { ...state, latestcolors };
		}
		case 'ADD_RECENT_GRADIENT': {
			const newItem = action.payload;

			let latestgradients = [];

			const existItem = state.latestgradients.find((item) => item === newItem);

			if (existItem) {
				const filters = state.latestgradients.filter((item) => item !== newItem);

				latestgradients = [newItem, ...filters];
			} else {
				let newArray;
				if (state.latestgradients.length === 3) {
					const removed = state.latestgradients.slice(0, -1);
					newArray = [newItem, ...removed];
				} else {
					newArray = [newItem, ...state.latestgradients];
				}

				latestgradients = newArray;
			}

			localStorage.setItem('latestgradients', JSON.stringify(latestgradients));
			return { ...state, latestgradients };
		}
		case 'ADD_RECENT_STYLE_COLOR': {
			const newItem = action.payload;
			const localStyleItems = state.currentStyleItems;

			const existItem = localStyleItems.find(
				(item) => item.className === newItem.className && item.styleIndex === newItem.styleIndex
			);

			const currentStyleItems = existItem
				? localStyleItems.map((style) => {
						if (style.className === newItem.className && style.styleIndex === newItem.styleIndex) {
							return existItem;
						}
						return style;
				  })
				: [...localStyleItems, newItem];
			return { ...state, currentStyleItems };
		}

		default:
			return state;
	}
}

export function ColorsContextProvider({ children }) {
	const [state, dispatch] = useReducer(reducer, initialState);
	const [showModal, setShowModal] = useState(false);
	const [nameLayer, setNameLayer] = useState('tabAmazonTis');

	const handleAddLastColor = (value) => {
		dispatch({ type: 'ADD_RECENT_COLOR', payload: value });
	};
	const handleAddcurrentStyleItems = (value) => {
		dispatch({ type: 'ADD_RECENT_STYLE_COLOR', payload: value });
	};
	const handleAddLayer = (value) => {
		setNameLayer(value);
	};

	const storevalue = useMemo(
		() => ({
			state,
			dispatch,
			handleAddLastColor,
			showModal,
			setShowModal,
			nameLayer,
			setNameLayer,
			handleAddLayer,
			handleAddcurrentStyleItems
		}),
		[showModal, state, nameLayer]
	);

	return <ColorsContext.Provider value={storevalue}>{children}</ColorsContext.Provider>;
}
export const useColorsContext = () => useContext(ColorsContext);
