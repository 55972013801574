/* eslint-disable no-plusplus */
import React, { useEffect, useState } from 'react';
import { svg } from 'lib/svgList';
import { useSelector } from 'react-redux';
import * as S from './styled';

// isIntitiOpen = se vc quiser que a modal começe aberta, é só passar essa props como true

// recebe a lista completa de menus e a key do acordion, se caso a key for true e o newfeature estiver como true mostra o aviso.
function isNewFeature(allLayers, keyAccordion) {
	for (let i = 0; i < allLayers.length; i++) {
		const item = allLayers[i];
		if (item.key === keyAccordion && item.newfeature === true) {
			return true;
		}
	}
	return false;
}

export default function GenericAccordion({
	title = 'Título',
	imgTitle = '',
	styleTitle = '',
	styleContainer = {},
	styleLine = '',
	children,
	onClick = () => {},
	isInitiOpen = false,
	widthIconTitleAccordion = 1,
	themeIcon = '',
	// Passar a mesma key do menu layers que vem do banco de dados caso queria que apareça o texto de novo recurso
	keyAccordion = ''
}) {
	const [open, setOpen] = useState(false);

	useEffect(() => {
		if (isInitiOpen) setOpen(true);
	}, []);

	const { allLayers } = useSelector((state) => state.allLayersMap);

	return (
		<S.accordionContainer
			style={styleContainer}
			onClick={() => {
				if (!open) {
					onClick();
				}
			}}
		>
			<S.btnTitle type="button" onClick={() => setOpen(!open)}>
				<S.TitleAndImg>
					{imgTitle !== '' ? (
						<S.imgAccordion widthIcon={widthIconTitleAccordion} src={imgTitle} alt="ilustração da opção" />
					) : null}
					<p style={styleTitle !== '' ? styleTitle : null}>{title}</p>
				</S.TitleAndImg>
				<S.btnTitleBoxRight>
					{isNewFeature(allLayers, keyAccordion) && (
						<S.newFeature>
							<p>NOVO RECURSO</p>
						</S.newFeature>
					)}
					{themeIcon === 'iconLessIsMore' ? (
						<S.imageBtnToggleOpenClose
							src={open ? svg.icon.zoomOutOrange : svg.icon.zoomInOrange}
							alt="abre e fecha opção"
							style={{ width: 30, height: 25 }}
						/>
					) : (
						<S.imageBtnToggleOpenClose
							src={svg.icon.rollScreem}
							alt="abre e fecha opção"
							style={open ? { transform: 'rotate(180deg)' } : { transform: 'rotate(0deg)' }}
						/>
					)}{' '}
				</S.btnTitleBoxRight>
			</S.btnTitle>

			<S.line style={styleLine !== '' ? styleLine : null} />

			<S.contentAccordion style={open ? { display: 'flex' } : { display: 'none' }}>{children || ''}</S.contentAccordion>
		</S.accordionContainer>
	);
}
