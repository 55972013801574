import React from 'react';
import LayersGroupByList from 'components/LayersGroupByList';
import GenericAccordion from 'components/GenericAccordion';
import { color } from 'styles/Theme';

export default function Dry({ climateList = [] }) {
	const type = 'seca';
	const { layers } = climateList;
	const aridityList = layers.filter((item) => item.type === type);

	return (
		<GenericAccordion
			title="Seca"
			styleTitle={{
				color: `${color.interface.darkGray}`,
				fontWeight: '500'
			}}
		>
			<LayersGroupByList layers={aridityList} showTitle={false} useToggle={false} />
		</GenericAccordion>
	);
}
