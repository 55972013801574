/* eslint-disable no-shadow */
/* eslint-disable no-unused-expressions */
import React from 'react';

import Gradient from './common/Gradient';

export function GradientColorPicker({ gradient, onStartChange, onChange, onEndChange }) {
	return (
		<Gradient
			points={gradient.points}
			updateColor={onChange}
			type={gradient.type}
			degree={gradient.degree}
			onChange={onChange}
			onStartChange={onStartChange}
			onEndChange={onEndChange}
		/>
	);
}
