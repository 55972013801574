import { user } from '../typesActions/types';

const initialState = {
	// Login
	loading: false,
	error: false,
	message: '',

	// Carrega perfis
	userProfiles: [],
	loadingProfiles: false,

	// Carrega roles
	roles: [],
	rolesPublic: [],
	loadingRoles: false,

	// Lista de usuarios perfil de gestor
	listUsers: [],
	loadingListUsers: false,
	messageListUsers: '',

	blockLogin: false,

	loadingUpdate: false,
	messageUpdate: '',

	loadingVerifyUpdate: false
};

export default function userReducer(state = initialState, action) {
	const { errors } = action?.payload || { erros: {} };

	switch (action.type) {
		// Realiza as ações para o login de usuarios

		case user.USER_AUTHENTICATE_REQUEST:
			return {
				...state,
				loading: true,
				error: false
			};
		case user.USER_AUTHENTICATE_SUCCESS:
			return {
				...state,
				loading: false,
				error: false,
				message: action.payload.message
			};
		case user.USER_AUTHENTICATE_FAILURE:
			return {
				...state,
				loading: false,
				message: errors.message,
				error: true
			};

		// Realiza as ações para o atualizar os dados do usuario ao carregar a plataforma

		case user.USERS_VERIFY_UPDATE_REQUEST:
			return {
				...state,
				loadingVerifyUpdate: true
			};
		case user.USERS_VERIFY_UPDATE_SUCCESS:
			return {
				...state,
				loadingVerifyUpdate: false
			};
		case user.USERS_VERIFY_UPDATE_FAILURE:
			return {
				...state,
				loadingVerifyUpdate: false
			};

		// Realiza as ações para o cadastro de novos usuarios

		case user.USER_SAVE_REQUEST:
			return {
				...state,
				loading: true,
				error: false
			};
		case user.USER_SAVE_SUCCESS:
			return {
				...state,
				loading: false,
				error: false,
				message: action.payload.message,
				blockLogin: action.payload.blockLogin
			};
		case user.USER_SAVE_FAILURE:
			return {
				...state,
				loading: false,
				message: errors.message,
				error: true
			};

		// Realiza as ações para o update de usuarios pelo ADM IPAM

		case user.USER_UPDATE_ADM_REQUEST:
			return {
				...state,
				loadingUpdate: true
			};
		case user.USER_UPDATE_ADM_SUCCESS:
			const { listUsers } = state;

			listUsers.forEach((g, i) => {
				if (g.id === action.params.id) {
					// Substitui apenas itens modificados devido vir os alertas, usos tradicionais apenas para gestor e adm parceiro
					listUsers[i].organization_id = action.payload.user.organization_id;
					listUsers[i].profile = action.payload.user.profile;
					listUsers[i].researcher_profile_id = action.payload.user.researcher_profile_id;
					listUsers[i].role_id = action.payload.user.role_id;
					listUsers[i].situation_id = action.payload.user.situation_id;
					listUsers[i].situation = action.payload.user.situation;
					listUsers[i].user_organization = action.payload.user.user_organization;
					listUsers[i].user_role = action.payload.user.user_role;
				}
			});

			return {
				...state,
				loadingUpdate: false,
				messageUpdate: action.payload.message
			};
		case user.USER_UPDATE_ADM_FAILURE:
			return {
				...state,
				loadingUpdate: false,
				messageUpdate: errors.message
			};

		// Realiza as ações para o update individual do usuario logado

		case user.USER_UPDATE_REQUEST:
			return {
				...state,
				loading: true,
				error: false
			};
		case user.USER_UPDATE_SUCCESS:
			return {
				...state,
				loading: false,
				error: false,
				message: action.payload.message
			};
		case user.USER_UPDATE_FAILURE:
			return {
				...state,
				loading: false,
				message: errors.message,
				error: true
			};

		// Carrega perfis de usuarios

		case user.USER_PROFILES_REQUEST:
			return {
				...state,
				loadingProfiles: true
			};
		case user.USER_PROFILES_SUCCESS:
			return {
				...state,
				loadingProfiles: false,
				userProfiles: action.payload
			};
		case user.USER_PROFILES_FAILURE:
			return {
				...state,
				loadingProfiles: false,
				message: errors.message
			};

		// Carrega roles de usuarios

		case user.ROLES_REQUEST:
			return {
				...state,
				loadingRoles: true
			};
		case user.ROLES_SUCCESS:
			const newRoles = action.payload.filter((item) => item.id === 2 || item.id === 3 || item.id === 5);
			return {
				...state,
				loadingRoles: false,
				roles: action.payload,
				rolesPublic: newRoles
			};
		case user.ROLES_FAILURE:
			return {
				...state,
				loadingRoles: false,
				message: errors.message
			};

		// Realiza as ações para trazer todos os usuarios para o ADM

		case user.USERS_LIST_REQUEST:
			return {
				...state,
				loadingListUsers: true
			};
		case user.USERS_LIST_SUCCESS:
			return {
				...state,
				loadingListUsers: false,
				listUsers: action.payload
			};
		case user.USERS_LIST_FAILURE:
			return {
				...state,
				loadingListUsers: false,
				messageListUsers: errors.messageAdmPartners
			};

		// Remove mensagem do reducer

		case user.USER_CLOSE_ERROR:
			return {
				...state,
				loading: false,
				message: '',
				error: false,
				blockLogin: false,
				loadingUpdate: false,
				messageUpdate: ''
			};

		default:
			return state;
	}
}
