import styled from 'styled-components';
import { color } from 'styles/Theme';

export const containerGeral = styled.div`
	padding-bottom: 2.5rem;
	width: 100%;
	background-color: ${color.interface.beige3};
`;

export const lineGray = styled.div`
	width: 100%;
	height: 0.5px;
	background-color: ${color.interface.lightGray};
`;

export const containerList = styled.div`
	margin-top: ${({ props }) => (props?.marginTop ? props.marginTop : '1rem')};
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 1rem;
	padding-left: ${({ props }) => (props?.paddingLeft ? props.paddingLeft : '1rem')};
	padding-right: ${({ props }) => (props?.paddingRight ? props.paddingRight : '1rem')};
`;
