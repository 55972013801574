import React from 'react';
import * as S from '../ControlButton/styles';

function TableLayout() {
	const HandleControl = () => {};
	return (
		<S.Container>
			<button type="button" title="Desenhar" onClick={HandleControl}>
				<svg width="22" height="22" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M54 8H10C8.96225 8.0702 7.98511 8.51416 7.24964 9.24964C6.51416 9.98512 6.0702 10.9622 6 12V52C6.0702 53.0378 6.51416 54.0149 7.24964 54.7504C7.98511 55.4858 8.96225 55.9298 10 56H54C55.0378 55.9298 56.0149 55.4858 56.7504 54.7504C57.4858 54.0149 57.9298 53.0378 58 52V12C57.9298 10.9622 57.4858 9.98512 56.7504 9.24964C56.0149 8.51416 55.0378 8.0702 54 8ZM54 12.17V23H34V12H53.83C53.8962 12.0463 53.9537 12.1038 54 12.17ZM34 27H54V37H34V27ZM30 37H10V27H30V37ZM10.17 12H30V23H10V12.17C10.0463 12.1038 10.1038 12.0463 10.17 12ZM10 51.83V41H30V52H10.17C10.1038 51.9537 10.0463 51.8962 10 51.83ZM53.83 52H34V41H54V51.83C53.9537 51.8962 53.8962 51.9537 53.83 52Z"
						fill="#291F17"
					/>
				</svg>
			</button>
		</S.Container>
	);
}

export default TableLayout;
