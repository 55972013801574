/* eslint-disable no-unused-vars */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-self-assign */
import { olMap } from 'components/Map/ControlMap/index';
import { Tile, Image } from 'ol/layer';
import View from 'ol/View';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import { ImageWMS, TileWMS, Raster } from 'ol/source';
import { mapType } from 'store/typesActions/types';
import { getLayerByName, setActivatedLayers, getMaxZIndex } from 'store/actions/mapActions';
import { getUseAndCoverageTis, getBurnedTis } from 'store/actions/threatsAction';
import { deforestedTisStyle, burnedTisStyle } from 'components/Map/Styles/js/StyleLayers';

/*
 * Progress load layers
 * -----------------------------------------------------
 */
export const loadStartTile = () => (dispatch) => {
	dispatch({ type: mapType.LOAD_LAYER_START });
};

export const loadEndTile = () => (dispatch) => {
	dispatch({ type: mapType.LOAD_LAYER_END });
};
// fecha os erros de carregamento
export const closeErrorTile = () => (dispatch) => {
	dispatch({ type: mapType.ERROR_LAYER_CLOSE });
};

export const loadStartImage = () => (dispatch) => {
	dispatch({ type: mapType.LOAD_IMAGE_START });
};

export const loadEndImage = () => (dispatch) => {
	dispatch({ type: mapType.LOAD_IMAGE_END });
};

export const loadStartInfo = () => (dispatch) => {
	dispatch({ type: mapType.LOAD_INFO_START });
};

export const loadEndInfo = () => (dispatch) => {
	dispatch({ type: mapType.LOAD_INFO_END });
};

export const setProgress = (source, layerName, isVisible) => (dispatch) => {
	let loading = 0;
	let loaded = 0;

	if (layerName === 'desmatamento' && isVisible) {
		loading += 1;
		if (loading === 1) dispatch(loadStartTile());
		if (loading >= 2) dispatch(loadStartImage());
	}

	// marks the start of an HTTP request for a tile
	source.on('tileloadstart', () => {
		loading += 1;
		if (loading === 1) dispatch(loadStartTile());
		if (loading >= 2) dispatch(loadStartImage());
	});

	// marks the end of an HTTP request for a tile
	source.on('tileloadend', () => {
		loaded += 1;
		if (loading === loaded) dispatch(loadEndTile());
		if (loading === loaded) dispatch(loadEndImage());
	});

	// error handling
	source.on('tileloaderror', () => {
		loaded += 1;
		if (loading === loaded) dispatch(loadError(layerName));
	});

	// marks the start of an HTTP request for an image
	source.on('imageloadstart', () => {
		loading += 1;
		if (loading === 1) dispatch(loadStartTile());
		if (loading >= 2) dispatch(loadStartImage());
	});

	// marks the end of an HTTP request for an image
	source.on('imageloadend', () => {
		loaded += 1;
		if (loading === loaded) dispatch(loadEndTile());
		if (loading === loaded) dispatch(loadEndImage());
	});

	source.on('imageloaderror', () => {
		loaded += 1;
		if (loading === loaded) dispatch(loadError(layerName));
	});

	source.on('beforeoperations', () => {
		if (layerName === 'desmatamento') {
			dispatch(loadEndTile());
			dispatch(loadEndImage());
		}
	});
};

export const loadError = (layerName) => (dispatch) => {
	let nameLayer = '';
	if (layerName) {
		const layerError = getLayerByName(layerName);
		nameLayer = layerError.get('name');
		olMap().removeLayer(layerError);
	}
	dispatch({ type: mapType.LOAD_LAYER_ERROR, payload: nameLayer });
};

const view = new View({
	center: [0, 0],
	zoom: 1
});

export const getLayerByMaxZIndex = () => (_, state) => {
	const { activatedLayers } = state()?.map || {};
	// const { isThereHTMLInfor, zIndex } = layer.getProperties();
	const maxZindex = getMaxZIndex();

	if (activatedLayers.length === 1) {
		return activatedLayers[0];
	}
	return activatedLayers.find((l) => l?.getProperties()?.zIndex === maxZindex);
};

// função para trazer o HTML do mapserver com as informações da tabela para o tooltip
export const handleLayerGetInfo = (tooltip) => (dispatch) => {
	olMap().on('singleclick', (evt) => {
		if (evt.dragging) return;

		// Coordenada do clique
		const { coordinate } = evt;

		const container = document.getElementById('tooltip-mapserver');

		if (!container) return;
		container.style.display = 'block';
		tooltip.setPosition(null);

		const wmsLayer = dispatch(getLayerByMaxZIndex());
		if (!wmsLayer) return;

		const rgbLayer = wmsLayer?.getData(evt.pixel);
		const hit = rgbLayer && rgbLayer[3] > 0; // transparent pixels have zero for rgbLayer[3]

		const { isThereHTMLInfor } = wmsLayer.getProperties();

		// aciona apenas se isThereHTMLInfor estiver como true no json que vem do back-end do somai e possuir o hit do rgb
		if (!hit || !isThereHTMLInfor) return;

		const wmsSource = wmsLayer.getSource();

		document.getElementById('info-mapserver').innerHTML = 'Carregando...';

		tooltip.setPosition(coordinate);

		const viewResolution = /** @type {number} */ (view.getResolution());
		const url = wmsSource.getFeatureInfoUrl(coordinate, viewResolution, 'EPSG:3857', { INFO_FORMAT: 'text/html' });

		if (url) {
			fetch(url)
				.then((response) => response.text())
				.then((res) => {
					const html = res || 'Não ha informação';
					// Adiciona o conteúdo recebido no tooltip do mapserver através do id
					document.getElementById('info-mapserver').innerHTML = html;
				})

				.catch(() => {
					tooltip.setPosition(null);
				});
		}
	});

	// Se o mouse estiver encima de uma camada que possui html o cursor fica como pointer
	olMap().on('pointermove', (evt) => {
		if (evt.dragging) {
			return;
		}
		const wmsLayer = dispatch(getLayerByMaxZIndex());
		if (!wmsLayer) return;
		const { isThereHTMLInfor } = wmsLayer.getProperties();

		const rgbLayer = wmsLayer?.getData(evt.pixel);
		const hit = rgbLayer && rgbLayer[3] > 0; // transparent pixels have zero for rgbLayer[3]
		olMap().getTargetElement().style.cursor = hit && isThereHTMLInfor ? 'pointer' : '';
	});
};

// -----------------------------------------------------
// Matriz de valores [R, G, B, A]
function rgb(pixel) {
	return Math.max(pixel[0], pixel[1], pixel[2]);
}

export const toggleLayersTabTi = (isVisible, itemLayer) => async (dispatch) => {
	const {
		name_mapfile: nameMapFile,
		type_mapfile: typeMapFile,
		name,
		// zIndex,
		haveTitle,
		maxPeriod,
		// minPeriod,
		class: classes,
		isThereHTMLInfor,
		link
	} = itemLayer;

	if (link) {
		const newlink = link.replace('HOST_MAPSERVER', process.env.REACT_APP_HOST_MAPSERVER);

		const [base, query] = newlink?.split('?');
		const params = query.split('&');
		let LAYERS = '';
		let YEAR = '';
		let url = base;

		params.forEach((p) => {
			if (p.indexOf('LAYERS') !== -1 || p.indexOf('layers') !== -1) {
				[, LAYERS] = p.split('=');
			}

			if (p.indexOf('YEAR') !== -1 || p.indexOf('year') !== -1) {
				[, YEAR] = p.split('=');
			}

			// para verificar se é do mapserver
			if (p.indexOf('MAP') !== -1) {
				url += `?${p}`;
			}
		});

		const TileAndImage = typeMapFile === 'image' ? ImageWMS : TileWMS;

		const sourceWms = new TileAndImage({
			url,
			params: { LAYERS, YEAR, TILED: true },
			serverType: 'mapserver',
			// crossOrigin para acessar mapserver remoto
			crossOrigin: 'anonymous'
		});

		let sourceRasterDeforestation = null;
		if (nameMapFile === 'desmatamento' && maxPeriod !== null) {
			// Gera o Raster do desmatamento
			// e aplica o "operation" os pixels com execução do slider
			sourceRasterDeforestation = new Raster({
				sources: [sourceWms],
				operation: (pixels, data) => {
					const pixel = pixels[0];
					const value = Math.max(pixel[0], pixel[1], pixel[2]);

					if (value >= data.threshold) {
						pixel[0] = pixel[0];
						pixel[1] = pixel[1];
						pixel[2] = pixel[2];
						pixel[3] = 255;
					} else {
						pixel[3] = 0;
					}
					return pixel;
				},
				lib: {
					rgb
				}
			});

			// valor mais elevado do Rbg em "COLORRANGE" no arquivo ".map" de desmatamento
			const highestValueRgb = 255;
			sourceRasterDeforestation.set('threshold', highestValueRgb);

			// beforeoperations do desmatamento PRODES
			sourceRasterDeforestation.on('beforeoperations', (event) => {
				sourceRasterDeforestation.changed();
				event.data.threshold = sourceRasterDeforestation.get('threshold');
			});
		}

		const ImageOrTile = typeMapFile === 'image' ? Image : Tile;
		// pega a ultima posição do index das camadas ativas
		const lastZIndex = getMaxZIndex() + 1;
		const src = sourceRasterDeforestation || sourceWms;
		const image = new ImageOrTile({
			source: src,
			classes,
			visible: isVisible,
			name: nameMapFile,
			label: name,
			// Sempre que ativar uma camada ela fica na frente
			zIndex: lastZIndex || 1,
			// Se mostra ou não o titulo da legenda ex: rasters
			haveTitle: haveTitle || false,
			isThereHTMLInfor: isThereHTMLInfor || false
		});

		dispatch(setProgress(src, image.get('name'), isVisible));

		const layer = getLayerByName(nameMapFile);

		if (layer) {
			await layer.setVisible(isVisible);
			await layer.setZIndex(lastZIndex);
		} else {
			await olMap().addLayer(image);
		}

		dispatch(setActivatedLayers());
	}
};

const setProps = (features, arrayData, propName, anotherProps = {}, type = null) => {
	features.map((feat) => {
		const props = feat.get('props');
		const { code } = props || {};

		const finded = arrayData.find((d) => d.code === code);

		const values = { ...finded, ...anotherProps };

		feat.setId(code);

		if (type) feat.set('type', type);
		feat.set(propName, values);

		return feat;
	});
};

export const toggleTisJson = (checkRadios, itemLayer) => async (dispatch, useState) => {
	const { key: itemKey } = itemLayer || {};

	const isVisible = checkRadios[itemKey];

	// inicia o load
	dispatch(loadStartInfo());

	const layerTis = getLayerByName('tabAmazonTis');
	let layerTisThreats = getLayerByName('tisThreats');

	// cria e adiciona ao mapa o layer "layerTisThreats" se não existir
	if (layerTis && !layerTisThreats) {
		// cria uma cópia dos recursos (features) do Vector Layer "layerTis"
		const features = layerTis
			.getSource()
			.getFeatures()
			.map((feature) => feature.clone());

		// cria um novo "Source" de dados para a camada copiada
		const source = new VectorSource({
			features
		});

		layerTisThreats = new VectorLayer({
			name: 'tisThreats',
			source,
			visible: false
		});

		await olMap().addLayer(layerTisThreats);
	}

	const features = layerTisThreats?.getSource()?.getFeatures();
	const [feature0] = features || [];

	// --- SE FOR DADOS DE DESMATAMENTO --- //
	if (itemKey === 'desmatamento') {
		// se não existir "propsDeforestation" nas features,
		// busca os dados de desmatamento e adiciona nas features
		if (feature0 && !feature0.get('propsDeforestation')) {
			await dispatch(getUseAndCoverageTis());

			const { deforestation, error } = useState().threatsTis;

			if (!error) {
				const totalPercDeforested = deforestation.reduce((sum, item) => sum + item.percentage_deforested, 0);
				const averageDeforested = totalPercDeforested / deforestation.length;

				const anotherProps = { avg_percentage_deforested: averageDeforested };

				setProps(features, deforestation, 'propsDeforestation', anotherProps, 'tisThreats');
			}
		}

		layerTisThreats.setStyle(deforestedTisStyle);
	}

	// --- SE FOR DADOS DE FOGO/QUEIMADA --- //
	if (itemKey === 'fogo') {
		// se não existir "propsBurned" nas features,
		// busca os dados de queimada e adiciona nas features
		if (feature0 && !feature0.get('propsBurned')) {
			await dispatch(getBurnedTis());

			const { burned, error } = useState().threatsTis;

			if (!error) {
				const totalPercBurned = burned.reduce((sum, item) => sum + item.percentage_burned, 0);
				const averageBurned = totalPercBurned / burned.length;

				const anotherProps = { avg_percentage_burned: averageBurned };

				setProps(features, burned, 'propsBurned', anotherProps, 'tisThreats');
			}
		}

		layerTisThreats.setStyle(burnedTisStyle);
	}

	layerTisThreats?.setZIndex(layerTis.getZIndex() + 1);
	layerTisThreats?.setVisible(isVisible);

	// finaliza o load
	dispatch(loadEndInfo());
};
