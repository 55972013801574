import React from 'react';
import { useSelector } from 'react-redux';
import ThreatsDonoutGraph from '../Graphs/ThreatsGraph/ThreatsDonoutGraph';
import LegendMap from './LegendMap';
import List10 from './List10';
import * as S from './styled';

export default function DeforestationData() {
	const { deforestation, isLoading, error } = useSelector((state) => state.threatsTis);

	// ordena os dados da TI mais desmatada para o menos desmatada
	deforestation.sort((a, b) => b.percentage_deforested - a.percentage_deforested);

	// soma desmatamento de todas a tis
	const totalDeforested = deforestation?.reduce((ac, d) => ac + d.deforested, 0);

	// soma vegetação de todas a tis
	const totalVegetation = deforestation?.reduce((ac, d) => ac + d.current_vegetation, 0);

	// pega os dez primeiros e ronomeia "deforested" para "data" e "percentage_deforested" para "percentage"
	const topTen = deforestation.slice(0, 10).map(({ deforested: d, percentage_deforested: pd, ...rest }) => ({
		...rest,
		data: d,
		percentage: pd
	}));

	return (
		<S.container>
			<LegendMap
				labels={[
					'Desmatou mais que a média de todas as TIs',
					'Desmatou menos que a média de todas as TIs',
					'Desmatou menos de 5% da média de todas as TIs'
				]}
			/>
			<S.line />
			<ThreatsDonoutGraph
				isLoading={isLoading}
				error={error}
				labels={['Conservado', 'Desmatado']}
				data={[totalVegetation, totalDeforested]}
				title="Desmatamento e vegetação conservada nas TIs da Amazônia Legal"
			/>
			<S.line />
			<List10 title="10 Terras Indígenas mais desmatadas" topTen={topTen} />
		</S.container>
	);
}
