import * as React from 'react';
import Popover from '@mui/material/Popover';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import * as S from './styled';

export default function BalloonDoubtsGlobal({ header = '', content = '', footer = '', positionRight = 0 }) {
	const [anchorEl, setAnchorEl] = React.useState(null);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);
	const id = open ? 'simple-popover' : undefined;

	const windowSize = window.innerWidth;

	return (
		<S.container positionRight={positionRight}>
			<AiOutlineInfoCircle color="#69625D" onClick={handleClick} />
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left'
				}}
				style={{
					marginLeft: windowSize <= 777 ? 0 : 20
				}}
			>
				<S.boxInformation>
					{header && (
						<>
							<S.title>{header}</S.title>
							<S.line />
						</>
					)}

					{content && typeof content === 'string' && <S.content> {content}</S.content>}

					{content?.length > 0 &&
						typeof content === 'object' &&
						content.map((row) => (
							<div key={row.id}>
								<S.content style={{ marginTop: 10 }}>{row.p}</S.content>
							</div>
						))}

					{footer && (
						<>
							<S.line />
							<S.font>{footer}</S.font>
						</>
					)}
				</S.boxInformation>
			</Popover>
		</S.container>
	);
}
