/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { actionsUsers } from 'helpers/utils';
import { useMeasureStore } from 'services/map/measure.context';
import SaveMap from './DownloadMap';
import BaseMapsToggleList from './BaseMapsToggleList';
import CamadaLayer from './CamadaLayer';
// import ImportLayers from './ImportLayers';
import Draw from './Draw';
import Measure from './Measure';
import Paint from './Paint';
import TableLayout from './TableLayout';
import ShareLayout from './ShareLayout';
import ControlButton from './ControlButton';

import { applyLupa, removeLupa, zoomInOutMap, zoomToExtentMap } from '../ControlMap';
import InfoContainer from './Info';
import MapInfoMobile from './MapInfo/mobile';
import DeleteAllShapes from './DeleteAllShapes';

function ControlesMap({ mapSomai }) {
	const [active, setActive] = useState(true);
	const { showFeatures } = useMeasureStore();
	const handlerZoomToExtent = () => {
		zoomToExtentMap();
		actionsUsers({
			category: `plataforma/mapa`,
			action: 'Click no botão',
			label: 'Mostrar o mapa completo'
		});
	};

	const handlerDragToZoomInMap = () => {
		if (active) {
			document.body.style.cursor = 'all-scroll';
			applyLupa();
		} else {
			document.body.style.cursor = 'default';
			removeLupa();
		}
		setActive(!active);
	};
	return (
		<>
			<ControlButton title="Mais zoom" Action={() => zoomInOutMap('In')} type="In" directAction />
			<ControlButton title="Menos zoom" Action={() => zoomInOutMap('Out')} type="Out" directAction />
			<ControlButton title="Mostrar o mapa completo" Action={() => handlerZoomToExtent()} type="Extent" directAction />
			<ControlButton
				title="Arraste para ampliar"
				Action={() => handlerDragToZoomInMap()}
				type="Drag"
				active={active}
				directAction={false}
			/>
			<BaseMapsToggleList mapSomai={mapSomai} />
			<CamadaLayer />
			{/* <ImportLayers mapSomai={mapSomai} /> */}
			{showFeatures && (
				<>
					<Draw />
					<Paint />
					<Measure />
				</>
			)}

			{/* <TableLayout /> */}
			<SaveMap />
			<ShareLayout />
			{showFeatures && <DeleteAllShapes />}
			<MapInfoMobile mapSomai={mapSomai} />
			<InfoContainer />
		</>
	);
}

export default ControlesMap;
