import React, { useRef } from 'react';

import { Hue } from './Hue';
import { Saturation } from './Saturation';
import { Alpha } from './Alpha';

import { useColorManipulation } from '../../hooks/useColorManipulation';
import { useStyleSheet } from '../../hooks/useStyleSheet';
import { formatClassName } from '../../utils/format';

export function AlphaColorPicker({ className, colorModel, color = colorModel.defaultColor, onChange, ...rest }) {
	const nodeRef = useRef(null);

	useStyleSheet(nodeRef);

	const [hsva, updateHsva] = useColorManipulation(colorModel, color, onChange);

	const nodeClassName = formatClassName(['somai-react-colorful', className]);

	return (
		<div {...rest} ref={nodeRef} className={nodeClassName}>
			<Saturation hsva={hsva} onChange={updateHsva} />
			<Hue hue={hsva.h} onChange={updateHsva} />
			<Alpha hsva={hsva} onChange={updateHsva} className="somai-react-colorful__last-control" />
		</div>
	);
}
