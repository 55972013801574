import React from 'react';
import { svg } from 'lib/svgList';
import ItensMenuPanel from 'components/ItensMenuPanel';
import OptionsMonitoring from 'components/OptionsMonitoring';
import * as S from './styled';

export default function DataUsers({ user }) {
	if (user?.tracks?.length > 0) {
		user.tracks.forEach((item) => {
			item.type = 'tracks';
		});
	}

	if (user?.where_i_been?.length > 0) {
		user.where_i_been.forEach((item) => {
			item.type = 'where_i_been';
		});
	}

	const initialOptions = [
		{
			title: 'Alertas',
			icon: svg.icon.warningBlack,
			function: () => {},
			content: <OptionsMonitoring type="alert" dataMonitoredUsers={user.alerts} managerWatching />,
			isOpen: false
		},
		{
			title: 'Usos Tradicionais',
			icon: svg.icon.mapLocation,
			function: () => {},
			content: <OptionsMonitoring type="traditionalUse" dataMonitoredUsers={user.traditional_uses} managerWatching />,
			isOpen: false
		},
		{
			title: 'Trajetos',
			icon: svg.icon.route,
			function: () => {},
			content: <OptionsMonitoring type="tracker" dataMonitoredUsers={user.tracks} managerWatching />,
			isOpen: false
		},
		{
			title: 'Localizações',
			icon: svg.icon.locationRound,
			function: () => {},
			content: <OptionsMonitoring type="whereIBeen" dataMonitoredUsers={user.where_i_been} managerWatching />,
			isOpen: false
		}
	];

	return (
		<S.containerGeralMonitoring>
			<S.lineGray />
			<ItensMenuPanel confStyled={confStyled} initialOptions={initialOptions} />
		</S.containerGeralMonitoring>
	);
}

const confStyled = {
	container: {
		paddingLeft: '0.5rem',
		paddingRight: 'none',
		marginTop: 'none'
	},
	theme: 'dark'
};
