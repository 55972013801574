/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-expressions */
import React, { useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';

import { generateGradientStyle, getRightValue, rgbToHsv } from '../../../utils';
import { useMount } from '../../../hooks';

import { AlphaGrandientColorPicker } from '../AlphaGrandientColorPicker';
import Hex from './Hex';
import Preview from './Preview';

const ColorViewContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: #69625d;
	margin-top: 0.7rem;
`;

const ColorViewInput = styled.div`
	display: flex;
	align-items: center;
	input {
		-moz-appearance: textfield;
		max-width: 4rem;
		height: 1.5rem;
		border: 2px solid #d9d5d2;
		border-radius: 3px;
		margin-left: 0.2rem;
		/* margin: 0.75rem 0rem 0rem 0.1rem; */
		text-align: center;
		color: #69625d;
		background-color: #f8f5f3;
	}
`;

function Gradient({ points, type, degree, onChange, onStartChange, onEndChange }) {
	const [activePointIndex, setActivePointIndex] = useState(0);
	const [gradientPoints, setGradientPoints] = useState(points);
	const [activePoint, setActivePoint] = useState(gradientPoints[0]);
	const [colorRed, setColorRed] = useState(activePoint.red);
	const [colorGreen, setColorGreen] = useState(activePoint.green);
	const [colorBlue, setColorBlue] = useState(activePoint.blue);
	const [colorAlpha, setColorAlpha] = useState(activePoint.alpha);
	const [colorHue, setColorHue] = useState(0);
	const [colorSaturation, setColorSaturation] = useState(100);
	const [colorValue, setColorValue] = useState(100);
	const [gradientType, setGradientType] = useState(type);
	const [gradientDegree, setGradientDegree] = useState(degree);

	const actions = {
		onChange,
		onStartChange,
		onEndChange
	};

	useMount(() => {
		const { hue, saturation, value } = rgbToHsv({
			red: colorRed,
			green: colorGreen,
			blue: colorBlue
		});

		setColorHue(hue);
		setColorSaturation(saturation);
		setColorValue(value);
	});

	const removePoint = useCallback(
		(index = activePointIndex) => {
			if (gradientPoints.length <= 2) {
				return;
			}

			const localGradientPoints = gradientPoints.slice();
			localGradientPoints.splice(index, 1);

			setGradientPoints(localGradientPoints);
			console.log('localGradientPoints 1', localGradientPoints);

			if (index > 0) {
				setActivePointIndex(index - 1);
			}

			onChange &&
				onChange({
					points: localGradientPoints,
					type: gradientType,
					degree: gradientDegree,
					style: generateGradientStyle(localGradientPoints, gradientType, gradientDegree)
				});
		},
		[gradientPoints, activePointIndex, gradientType, gradientDegree, onChange]
	);

	const keyUpHandler = useCallback(
		(event) => {
			if (event.keyCode === 46 || event.keyCode === 8) {
				removePoint(activePointIndex);
			}
		},
		[activePointIndex, removePoint]
	);

	useEffect(() => {
		document.addEventListener('keyup', keyUpHandler);

		return () => {
			document.removeEventListener('keyup', keyUpHandler);
		};
	});

	const changeGradientControl = useCallback(
		({ type, degree }, actionName = 'onChange') => {
			type = getRightValue(type, gradientType);
			degree = getRightValue(degree, gradientDegree);

			setGradientType(type);
			setGradientDegree(degree);

			const action = actions[actionName];

			action &&
				action({
					points: gradientPoints,
					type,
					degree,
					style: generateGradientStyle(gradientPoints, type, degree)
				});
		},
		[actions, gradientPoints, gradientDegree, gradientType]
	);

	const changeActivePointIndex = useCallback(
		(index) => {
			setActivePointIndex(index);

			const localGradientPoint = gradientPoints[index];
			const { red, green, blue, alpha } = localGradientPoint;
			setActivePoint(localGradientPoint);

			setColorRed(red);
			setColorGreen(green);
			setColorBlue(blue);
			setColorAlpha(alpha);

			const { hue, saturation, value } = rgbToHsv({ red, green, blue });

			setColorHue(hue);
			setColorSaturation(saturation);
			setColorValue(value);
		},
		[gradientPoints]
	);
	const updateColor = useCallback(
		({ red, green, blue, alpha, hue, saturation, value }, actionName = 'onChange') => {
			red = getRightValue(red, colorRed);
			green = getRightValue(green, colorGreen);
			blue = getRightValue(blue, colorBlue);
			alpha = getRightValue(alpha, colorAlpha);
			hue = getRightValue(hue, colorHue);
			saturation = getRightValue(saturation, colorSaturation);
			value = getRightValue(value, colorValue);

			const localGradientPoints = gradientPoints.slice();

			localGradientPoints[activePointIndex] = {
				...localGradientPoints[activePointIndex],
				red,
				green,
				blue,
				alpha
			};

			setColorRed(red);
			setColorGreen(green);
			setColorBlue(blue);
			setColorAlpha(alpha);
			setColorHue(hue);
			setColorSaturation(saturation);
			setColorValue(value);
			setGradientPoints(localGradientPoints);

			const action = actions[actionName];

			action &&
				action({
					points: localGradientPoints,
					type: gradientType,
					degree: gradientDegree,
					style: generateGradientStyle(localGradientPoints, gradientType, gradientDegree)
				});
		},
		[
			colorRed,
			colorGreen,
			colorBlue,
			colorAlpha,
			colorHue,
			colorSaturation,
			colorValue,
			activePointIndex,
			gradientPoints,
			actions,
			gradientType,
			gradientDegree
		]
	);

	const updateGradientLeft = useCallback(
		(left, index, actionName = 'onChange') => {
			const localGradientPoints = gradientPoints.slice();
			localGradientPoints[index].left = left;

			setGradientPoints(localGradientPoints);
			console.log('localGradientPoints 2', localGradientPoints);

			const action = actions[actionName];

			action &&
				action({
					points: localGradientPoints,
					type: gradientType,
					degree: gradientDegree,
					style: generateGradientStyle(localGradientPoints, gradientType, gradientDegree)
				});
		},
		[actions, gradientPoints, gradientDegree, gradientType]
	);

	const addPoint = useCallback(
		(left) => {
			const localGradientPoints = gradientPoints.slice();

			localGradientPoints.push({
				...localGradientPoints[activePointIndex],
				left
			});

			setGradientPoints(localGradientPoints);
			setActivePointIndex(localGradientPoints.length - 1);

			console.log('localGradientPoints 3', localGradientPoints);
			onChange &&
				onChange({
					points: localGradientPoints,
					type: gradientType,
					degree: gradientDegree,
					style: generateGradientStyle(localGradientPoints, gradientType, gradientDegree)
				});
		},
		[onChange, gradientPoints, activePointIndex, gradientType, gradientDegree]
	);

	return (
		<>
			<AlphaGrandientColorPicker
				red={colorRed}
				green={colorGreen}
				blue={colorBlue}
				alpha={colorAlpha}
				hue={colorHue}
				saturation={colorSaturation}
				value={colorValue}
				updateRgb={updateColor}
				isGradient
				type={gradientType}
				degree={gradientDegree}
				points={gradientPoints}
				activePointIndex={activePointIndex}
				changeGradientControl={changeGradientControl}
				changeActivePointIndex={changeActivePointIndex}
				updateGradientLeft={updateGradientLeft}
				addPoint={addPoint}
				removePoint={removePoint}
			/>

			<ColorViewContainer>
				<ColorViewInput>
					#
					<Hex red={colorRed} green={colorGreen} blue={colorBlue} alpha={colorAlpha} updateRgb={updateColor} />
				</ColorViewInput>
				<Preview
					red={colorRed}
					green={colorGreen}
					blue={colorBlue}
					alpha={colorAlpha}
					points={points}
					gradientDegree={degree}
					gradientType={type}
					isGradient
				/>
			</ColorViewContainer>
		</>
	);
}
export default Gradient;
