import styled from 'styled-components';
import { color } from 'styles';

export const BoxInput = styled.div`
	box-sizing: border-box;
	min-width: 0;
	display: flex;
	align-items: center;
	height: ${({ props }) => (props.disabled ? '' : '55px')};
	padding: ${({ props }) => (props.disabled ? '0 10px' : '0 22px')};
	background: ${({ props }) => (props.disabled ? color.interface.beige2 : color.interface.whiteAbsolute)};
	border: ${({ props }) =>
		!props.disabled ? `1.5px solid ${props.isError ? color.interface.red : color.interface.inputs}` : 'none'};
	border-radius: 10px;
	margin-bottom: 7px;
	font-weight: 400;

	input[type='date'] {
		font-weight: 400;
		text-transform: uppercase;
		font-size: 16px;
	}
`;

export const Input = styled.input`
	flex: 1 1 auto;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	line-height: 20px;
	font-weight: 400;
	border: none;
	padding: 0;
	background: transparent;
	color: ${color.system.font};
	font-size: 1rem;

	::placeholder {
		color: ${color.interface.inputs};
		font-size: 0.875rem;
	}

	:focus {
		outline: none;
		border: none;
	}
`;

export const ContainerDivError = styled.div`
	justify-content: flex-start;
	width: 100%;
	display: -ms-flexbox;
	display: flex;
	font-weight: 700;
	font-size: 0.625rem;
	color: ${color.interface.red};
	padding-left: 10px;
	height: 10px;
`;
