import React from 'react';
import Modal from 'components/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { getUserLocalStorage } from 'services/session';
import GridBox from 'components/Grid/Grid';
import Select from 'react-select';
import Row from 'components/Grid/Row';
import InputRegister from 'components/InputRegister';

import {
	filterProfileUsers,
	filterOrganizationUsers,
	filterSearchUsers,
	setStateModalMultFilter
} from 'store/actions/webGisActions';

import { sortAndFilterList } from 'helpers/utils';
import { replaceArrayProps, filterUserProfilesByUserRole, filterUserOrganization } from './utils';
import LettersSlider from './LettersSlider';
import SystemUsersTable from './SystemUsersTable';
import * as S from './styled';

function ModalMultiFilter() {
	const dispatch = useDispatch();

	const {
		systemUsers,
		filterSearchUser,
		filterLetterUser,
		filterOrganizationUser,
		filterProfileUser,
		stateModalMultiFilter,
		filterPeriod
	} = useSelector((state) => state.webGis);

	const filterList = sortAndFilterList(
		systemUsers,
		filterLetterUser,
		filterSearchUser,
		filterProfileUser,
		filterOrganizationUser,
		filterPeriod
	);

	const { rolesPublic } = useSelector((state) => state.user);
	const { listOrg } = useSelector((state) => state.organizations);

	const session = getUserLocalStorage();
	const roleManager = session?.user?.user_role?.role || '';

	if (roleManager === 'admin') return null;
	return (
		<Modal
			styleContent={styles.modal}
			styleProps={{ overflow: 'hidden' }}
			isOpen={stateModalMultiFilter}
			isClose={() => dispatch(setStateModalMultFilter(false))}
			useBtnEnd={false}
		>
			<GridBox>
				<Row>
					<S.BoxFilter>
						<S.Title>
							<p> Filtro por usuários </p>
						</S.Title>
					</S.BoxFilter>
				</Row>
				<Row>
					<S.BoxFilter>
						<S.line />
					</S.BoxFilter>
				</Row>
				<Row>
					<S.BoxFilter>
						<InputRegister
							type="text"
							styled={{ height: 38, width: '100%', margin: 2 }}
							placeholder="Pesquisar"
							value={filterSearchUser}
							setValue={(e) => {
								dispatch(filterSearchUsers(e));
							}}
							required
						/>
					</S.BoxFilter>

					<S.BoxFilter>
						<Select
							isMulti
							name="Por perfil"
							placeholder="Por perfil"
							styles={styles.select}
							options={replaceArrayProps(filterUserProfilesByUserRole(rolesPublic, filterList))}
							className="basic-multi-select"
							classNamePrefix="Perfil"
							defaultValue={filterProfileUser}
							onChange={(e) => {
								dispatch(filterProfileUsers(e));
							}}
						/>
					</S.BoxFilter>

					<S.BoxFilter>
						<Select
							isMulti
							name="Por organização"
							placeholder="Por organização"
							styles={styles.select}
							options={replaceArrayProps(filterUserOrganization(listOrg, filterList))}
							className="basic-multi-select"
							classNamePrefix="Organização"
							defaultValue={filterOrganizationUser}
							onChange={(e) => {
								dispatch(filterOrganizationUsers(e));
							}}
						/>
					</S.BoxFilter>
					<S.BoxFilter style={{ justifyContent: 'center' }}>
						<LettersSlider systemUsers={filterList} />
					</S.BoxFilter>
				</Row>
			</GridBox>

			<SystemUsersTable systemUsers={filterList} />
		</Modal>
	);
}

const styles = {
	modal: {
		width: '1024px',
		maxWidth: '90%',
		overflow: 'none',
		zIndex: 200
	},
	select: {
		container: (base) => ({
			...base,
			width: '100%'
		}),
		valueContainer: (base) => ({
			...base,
			width: 200,
			maxHeight: 60,
			overflow: 'auto'
		}),
		menu: (base) => ({
			...base,
			fontFamily: 'Nunito',
			fontWeight: 400,
			fontSize: 12
		}),
		control: (baseStyles, state) => ({
			...baseStyles,
			fontFamily: 'Nunito',
			fontWeight: 400,
			fontSize: 14,
			borderRadius: 10,
			border: '1.5px solid #a69d95',
			// This line disable the blue border
			boxShadow: state.isFocused ? 0 : 0,
			'&:hover': {
				border: state.isFocused ? 0 : 0
			}
		})
	}
};
export default ModalMultiFilter;
