import React, { useState, useEffect, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InputRegister from 'components/InputRegister';
import { emailRequestOrgRegistration, closeMessageAndError } from 'store/actions/organizationsActions';
import Modal from 'components/Modal';
import Check from 'components/Buttons/Check';
import CheckAnimation from 'components/Animations/CheckAnimation/CheckAnimation';
import UncheckAnimation from 'components/Animations/UncheckAnimation/UncheckAnimation';
import * as S from '../Register/Register.Styled';

function SubmitOrganization({ orgModal, setOrgModal, emailRequester, firstName, lastName }) {
	const dispatch = useDispatch();

	const { loadingSendMail, messageSendMail, statusMail } = useSelector((state) => state.organizations);

	const [nameOrg, setNameOrg] = useState('');
	const [emailOrg, setEmailOrg] = useState('');

	const [isChecked, setIsChecked] = useState(false);
	const [isError, setIsError] = useState(false);

	const formData = {
		nameOrg,
		emailOrg,
		nameRequester: `${firstName.concat(' ', lastName)}`,
		emailRequester
	};

	useEffect(() => {
		if (nameOrg !== '' && isChecked) {
			setIsError(false);
		}
	}, [nameOrg, isChecked]);

	const handleSubmit = () => {
		if (isChecked && nameOrg) {
			dispatch(emailRequestOrgRegistration(formData));
			setIsError(false);
		} else {
			setIsError(true);
		}
	};

	const onClear = () => {
		dispatch(closeMessageAndError());
	};

	return (
		<Modal
			styleContent={{ width: '500px', maxWidth: '90%' }}
			isOpen={orgModal}
			isClose={onClear}
			setIsOpen={setOrgModal}
			useBtnEnd={false}
		>
			{!statusMail ? (
				<div style={{ width: '100%' }}>
					<S.Label>Nome da organização*</S.Label>
					<InputRegister
						type="text"
						autocomplete="off"
						value={nameOrg}
						setValue={setNameOrg}
						isError={!nameOrg && isError}
						required
					/>
					<S.Label>Email da organização</S.Label>
					<InputRegister
						type="email"
						autocomplete="off"
						value={emailOrg}
						setValue={setEmailOrg}
						isError={!emailOrg && isError}
						required
					/>
					<S.Label>Nome do solicitante</S.Label>
					<InputRegister type="text" autocomplete="off" value={`${firstName.concat(' ', lastName)}`} disabled />
					<S.Label>E-mail de contato do solicitante</S.Label>
					<InputRegister type="email" autocomplete="off" value={emailRequester} disabled />
					<Check
						isChecked={isChecked}
						setIsChecked={setIsChecked}
						isError={!isChecked && isError}
						text="Declaro que os dados fornecidos são verdadeiros*"
					/>

					<S.Button onClick={() => handleSubmit()} style={{ marginTop: 20 }} type="button">
						{loadingSendMail ? 'Aguarde...' : 'Enviar solicitação'}
					</S.Button>
				</div>
			) : null}

			{statusMail === 200 ? <CheckAnimation /> : null}
			{statusMail === 400 ? <UncheckAnimation /> : null}

			{statusMail ? <S.Message>{messageSendMail}</S.Message> : null}
		</Modal>
	);
}

export default memo(SubmitOrganization);
