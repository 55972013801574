import React, { useEffect, useState } from 'react';

import { firstLetterUperCase } from 'helpers/utils';
import Item from './Item';

export default function ListAccordion({ listDataUsers, typeData }) {
	// alertList fica a lista de cada tipo (sem repetir) de alerta que o usuário possui disponivel.
	const [alertsList, setAlertsList] = useState([]);

	// essa função pega a lista com todos os alertas que o usuário possui, e retorna uma lista apenas com os tipos de alerta que o usuário possui
	async function getTypesFromUserAlerts(allAlerts) {
		// pegando o tipo dos alertas no array de alertas
		if (typeof allAlerts === 'object' && allAlerts.length > 0) {
			const listAllAlerts = await allAlerts.map((item) => {
				const newName = firstLetterUperCase(item.name);
				return {
					id: item.id,
					title: newName,
					type: item.type,
					typeRegister: item.typeRegister
				};
			});
			const listTypesNoRepeat = [];

			// tirando os alertas repetidos
			await listAllAlerts.forEach((item) => {
				const verifyIfExist = listTypesNoRepeat.filter((alertItem) => alertItem.type === item.type);
				if (verifyIfExist.length === 0) {
					listTypesNoRepeat.push(item);
				}
			});

			setAlertsList(listTypesNoRepeat.sort((a, b) => a.title.localeCompare(b.title)));
		}
	}

	useEffect(() => {
		if (typeof listDataUsers === 'object' && listDataUsers.length > 0) {
			getTypesFromUserAlerts(listDataUsers);
		}

		return () => {
			setAlertsList([]);
		};
	}, [listDataUsers]);

	return <Item alertsList={alertsList} typeData={typeData} />;
}
