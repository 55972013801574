import { Circle as CircleStyle, Fill, Stroke, Text, Style, Icon } from 'ol/style';
import { png, svg } from 'lib/svgList';
import { color } from 'styles/Theme';

export const StyleLayers = {
	// Globais
	pointBackground: new Style({
		image: new CircleStyle({
			radius: 30,
			fill: new Fill({ color: '#FFFFFF' }),
			stroke: new Stroke({ color: '#9F9A9799', width: 0.5 })
		}),
		label: 'Cor de fundo'
	}),
	remove: new Style({
		image: ''
	}),

	// Draw Vector
	drawStyle: new Style({
		fill: new Fill({
			color: 'rgba(255, 255, 255, 0.6)'
		}),
		stroke: new Stroke({
			color: 'black',
			width: 2
		})
	}),
	drawPointStyle: new Style({
		image: new Icon({
			crossOrigin: 'anonymous',
			src: `${svg.icon.point}`
		})
	}),

	// Alertas
	fire: new Style({
		image: new Icon({
			scale: 0.042,
			anchor: [0.5, 0.5],
			crossOrigin: 'anonymous',
			src: `${png.fogoOrange}`
		}),
		label: 'Fogo'
	}),
	deforestation: new Style({
		image: new Icon({
			scale: 0.055,
			anchor: [0.5, 0.5],
			crossOrigin: 'anonymous',
			src: `${png.desmatamentoIlegalOrange}`
		})
	}),
	illegalHunting: new Style({
		image: new Icon({
			scale: 0.042,
			anchor: [0.5, 0.5],
			crossOrigin: 'anonymous',
			src: `${png.cacaIlegalOrange}`
		})
	}),
	woodExploitation: new Style({
		image: new Icon({
			scale: 0.042,
			anchor: [0.5, 0.5],
			crossOrigin: 'anonymous',
			src: `${png.exploracaoIlegalOrange}`
		})
	}),
	illegalMining: new Style({
		image: new Icon({
			scale: 0.042,
			anchor: [0.5, 0.5],
			crossOrigin: 'anonymous',
			src: `${png.garimpoIlegalOrange}`
		})
	}),
	invasionLand: new Style({
		image: new Icon({
			scale: 0.042,
			anchor: [0.5, 0.5],
			crossOrigin: 'anonymous',
			src: `${png.invasaoTiOrange}`
		})
	}),
	illegalFishing: new Style({
		image: new Icon({
			scale: 0.042,
			anchor: [0.5, 0.5],
			crossOrigin: 'anonymous',
			src: `${png.pescaIlegalOrange}`
		})
	}),
	riscoClimatico: new Style({
		image: new Icon({
			scale: 0.042,
			anchor: [0.5, 0.5],
			crossOrigin: 'anonymous',
			src: `${png.eventosClimaticos}`
		})
	}),
	alertPovosIsolados: new Style({
		image: new Icon({
			scale: 0.042,
			anchor: [0.5, 0.5],
			crossOrigin: 'anonymous',
			src: `${png.povosIsolados}`
		})
	}),
	otherAlerts: new Style({
		image: new Icon({
			scale: 0.032,
			anchor: [0.5, 0.5],
			crossOrigin: 'anonymous',
			src: `${png.outrosOrange}`
		})
	}),
	// Usos Tradicionais
	aldeia: new Style({
		image: new Icon({
			scale: 0.042,
			anchor: [0.5, 0.5],
			crossOrigin: 'anonymous',
			src: `${png.aldeiaOrange}`
		})
	}),
	useTradPovosIsolados: new Style({
		image: new Icon({
			scale: 0.042,
			anchor: [0.5, 0.5],
			crossOrigin: 'anonymous',
			src: `${png.povosIsolados}`
		})
	}),
	garimpo: new Style({
		image: new Icon({
			scale: 0.042,
			anchor: [0.5, 0.5],
			crossOrigin: 'anonymous',
			src: `${png.garimpo}`
		})
	}),
	caca: new Style({
		image: new Icon({
			scale: 0.042,
			anchor: [0.5, 0.5],
			crossOrigin: 'anonymous',
			src: `${png.cacaOrange}`
		})
	}),
	coleta: new Style({
		image: new Icon({
			scale: 0.042,
			anchor: [0.5, 0.5],
			crossOrigin: 'anonymous',
			src: `${png.coletaOrange}`
		})
	}),
	pesca: new Style({
		image: new Icon({
			scale: 0.042,
			anchor: [0.5, 0.5],
			crossOrigin: 'anonymous',
			src: `${png.pescaOrange}`
		})
	}),
	roca: new Style({
		image: new Icon({
			scale: 0.042,
			anchor: [0.5, 0.5],
			crossOrigin: 'anonymous',
			src: `${png.rocaOrange}`
		})
	}),
	outrosUsotradiconal: new Style({
		image: new Icon({
			scale: 0.042,
			anchor: [0.5, 0.5],
			crossOrigin: 'anonymous',
			src: `${png.outrosOrange}`
		})
	}),
	whereIBeen: new Style({
		image: new Icon({
			scale: 0.5,
			anchor: [0.5, 0.5],
			crossOrigin: 'anonymous',
			src: `${png.locationRound}`
		})
	}),
	tracks: new Style({
		stroke: new Stroke({ color: color.interface.orange, width: 6 }),
		fill: new Fill({ color: '#FFFFFF' })
	}),
	getPoint: new Style({
		image: new Icon({
			scale: 0.5,
			anchor: [0.5, 0.5],
			crossOrigin: 'anonymous',
			src: `${png.locationRound}`
		})
	}),

	// Tab Amazon, não mudar os nomes 'tabAmazon' e 'tabAmazonTis' pq a função para gerar a legenda do backend estão atrelados a esses nomes
	tabAmazonTis: [
		new Style({
			stroke: new Stroke({
				color: color.interface.white,
				width: 1
			})
		}),
		new Style({
			stroke: new Stroke({
				color: color.interface.green,
				width: 0.6
			}),
			fill: new Fill({ color: `${color.interface.white}15` }),
			label: 'Limite dos estados'
		})
	],

	tabAmazon: new Style({
		stroke: new Stroke({
			color: color.interface.green,
			width: 1
		}),
		fill: new Fill({ color: `${color.interface.white}03` }), // 99% transparência
		label: 'Terras indígenas'
	}),

	tabAmazonTisGlobal: new Style({
		stroke: new Stroke({
			color: color.interface.darkGreen,
			width: 0.6
		}),

		label: 'tisFixo'
	}),

	tiSelectedTabTi: new Style({
		stroke: new Stroke({
			color: color.interface.orange,
			width: 3
		}),
		fill: new Fill({ color: `${color.interface.white}05` }),
		label: 'Limites'
	}),

	// Foco de calor
	focoCalor: new Style({
		image: new Icon({
			scale: 0.04,
			anchor: [0.5, 0.5],
			crossOrigin: 'anonymous',
			src: `${png.fogoOrange}`
		})
	}),

	focoDesmatamento: new Style({
		image: new Icon({
			scale: 0.06,
			anchor: [0.5, 0.4],
			crossOrigin: 'anonymous',
			src: `${png.desmatamentoIlegalOrange}`
		})
	}),

	distance: new Style({
		stroke: new Stroke({
			color: '#1E90FF', // Azul
			width: 3
		}),
		text: new Text({
			font: '12px Calibri,sans-serif',
			fill: new Fill({
				color: '#1E90FF'
			}),
			stroke: new Stroke({
				color: '#fff',
				width: 2
			})
		})
	}),
	area: new Style({
		stroke: new Stroke({
			color: '#32CD32', // Verde
			width: 3
		}),
		fill: new Fill({
			color: 'rgba(50, 205, 50, 0.3)' // Verde com transparência
		}),
		text: new Text({
			font: '12px Calibri,sans-serif',
			fill: new Fill({
				color: '#32CD32'
			}),
			stroke: new Stroke({
				color: '#fff',
				width: 2
			})
		})
	})
};

export const deforestedTisStyle = (feature) => {
	const styles = {
		low: new Style({
			stroke: new Stroke({
				color: color.interface.gray,
				width: 1
			}),
			fill: new Fill({ color: `${color.interface.green}` })
		}),
		average: new Style({
			stroke: new Stroke({
				color: color.interface.gray,
				width: 1
			}),
			fill: new Fill({ color: `${color.interface.beige}` })
		}),
		high: new Style({
			stroke: new Stroke({
				color: color.interface.gray,
				width: 1
			}),
			fill: new Fill({ color: `${color.interface.red}` })
		})
	};

	if (!feature) return styles;

	const propsDeforestation = feature.get('propsDeforestation');
	const percDeforested = propsDeforestation.percentage_deforested;
	const avgPercDeforested = propsDeforestation.avg_percentage_deforested;

	if (percDeforested / avgPercDeforested < 0.05) {
		return styles.low;
	}
	if (percDeforested <= avgPercDeforested) {
		return styles.average;
	}
	return styles.high;
};

export const burnedTisStyle = (feature) => {
	const styles = {
		low: new Style({
			stroke: new Stroke({
				color: color.interface.gray,
				width: 1
			}),
			fill: new Fill({ color: `${color.interface.green}` })
		}),
		average: new Style({
			stroke: new Stroke({
				color: color.interface.gray,
				width: 1
			}),
			fill: new Fill({ color: `${color.interface.beige}` })
		}),
		high: new Style({
			stroke: new Stroke({
				color: color.interface.gray,
				width: 1
			}),
			fill: new Fill({ color: `${color.interface.red}` })
		})
	};

	if (!feature) return styles;

	const propsBurned = feature.get('propsBurned');
	const percBurned = propsBurned.percentage_burned;
	const avgPercBurned = propsBurned.avg_percentage_burned;

	if (percBurned / avgPercBurned < 0.05) {
		return styles.low;
	}
	if (percBurned <= avgPercBurned) {
		return styles.average;
	}
	return styles.high;
};

export const selectedFeatureStyle = (defaultStyle) => {
	const outside = new Style({
		stroke: new Stroke({
			color: '#ffffff',
			width: 5,
			zIndex: 100
		}),
		fill: defaultStyle.getFill()
	});

	const inside = new Style({
		stroke: new Stroke({
			color: '#4B96A4',
			width: 3,
			zIndex: 1000
		}),
		fill: defaultStyle.getFill()
	});

	return [outside, inside];
};
