import React, { useEffect, useState } from 'react';
import { svg } from 'lib/svgList';
import { headers, takePrintMap } from 'helpers/utils';
import { api } from 'services/api';
import Load from 'components/Load/Load';
import * as S from './styled';

export default function BtnGeneratePDF({ resetBtnPdf = false, setResetBtnPdf = () => {}, values = {} }) {
	const [isLoad, setIsLoad] = useState(false);
	const [isError, setIsError] = useState(false);
	const [link, setLink] = useState('');

	const generatePDF = async () => {
		setIsLoad(true);
		setIsError(false);
		setLink('');

		// caso tenha dados de geom, nao precisa enviar, por que pesa a requisição atoa
		if ('geom' in values) delete values.geom;

		values.mapImage = takePrintMap();

		api
			.post(`/createPDF/painel/uniqueAlertMonitoring`, values, {
				headers: headers()
			})
			.then((res) => {
				const { data } = res;
				setIsLoad(false);
				setIsError(false);
				setLink(data?.url || '');
			})
			.catch(() => {
				setIsLoad(false);
				setIsError(true);
				setLink('');
			});
	};

	useEffect(() => {
		if (resetBtnPdf) {
			setIsLoad(false);
			setIsError(false);
			setLink('');
			setTimeout(() => setResetBtnPdf(false), 500);
		}
	}, [resetBtnPdf]);

	return (
		<S.container>
			{!isLoad && link === '' && (
				<S.btnReport
					onClick={() => {
						generatePDF();
					}}
				>
					<button type="submit">
						Gerar relatório
						<img src={svg.icon.download} style={{ width: 14 }} alt="botão para gerar relatório do item selecionado." />
					</button>
				</S.btnReport>
			)}

			{!isLoad && !isError && link !== '' && (
				<a style={{ textDecoration: 'none' }} target="_blank" rel="noreferrer" download="relatorio" href={link}>
					<S.btnReport>
						<button type="submit">Acessar</button>
					</S.btnReport>
				</a>
			)}

			{isError && <S.notice>Erro! Tente novamente.</S.notice>}

			{isLoad && (
				<S.boxLoad>
					<Load />
				</S.boxLoad>
			)}
		</S.container>
	);
}
