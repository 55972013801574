import { editDataUserOnMap } from 'store/typesActions/types';

const initialState = {
	dataToEdit: {},
	isEdit: false,
	messageIsEdit: ''
};

export default function deforestationSadSumReducer(state = initialState, action) {
	switch (action.type) {
		case editDataUserOnMap.INIT_EDIT_DATA_ON_MAP:
			return {
				...state,
				dataToEdit: action.payload,
				isEdit: true,
				messageIsEdit: 'Clique no ponto azul no centro do icone e arraste para alterar a localização'
			};

		case editDataUserOnMap.FINISHED_EDIT_DATA_ON_MAP:
			return {
				...state,
				dataToEdit: {},
				isEdit: false,
				messageIsEdit: ''
			};
		case editDataUserOnMap.CLOSE_MESSAGE:
			return {
				...state,
				messageIsEdit: ''
			};
		default:
			return state;
	}
}
