import { api } from 'services/api';
import { monitoringDataType } from '../typesActions/types';
import { getUserLocalStorage } from 'services/session';
import { headers } from 'helpers/utils';

export const setMonitoringFormActivated = (situation) => {
	return {
		type: monitoringDataType.MONITORING_FORM_ACTIVATED,
		payload: situation
	};
};

export const getDataMonitoringAction = () => {
	return { type: monitoringDataType.MONITORING_REQ };
};

export const clearDataMonitoringAction = () => {
	return { type: monitoringDataType.MONITORING_CLEAR_DATA };
};

export const successDataMonitoringAction = (data) => {
	return { type: monitoringDataType.MONITORING_REC_SUCCESS, payload: data };
};

export const updateDataMonitoringAction = (data) => {
	return { type: monitoringDataType.MONITORING_UPDATE, payload: data };
};

export const errorDataMonitoringAction = (data) => {
	return { type: monitoringDataType.MONITORING_REC_ERROR, payload: data };
};

export const saveDataMonitoring =
	({ route, params, typeAlert }) =>
	async (dispatch) => {
		dispatch({ type: monitoringDataType.SAVE_REGISTER_REQUEST });
		try {
			const res = await api.post(route, params, {
				headers: headers()
			});
			const { data } = res || {};
			dispatch({
				type: monitoringDataType.SAVE_REGISTER_SUCCESS,
				payload: {
					data,
					message: 'Salvo com sucesso',
					type: typeAlert
				}
			});
			return data;
		} catch (error) {
			const { response } = error;
			if (response && response.status === 400) {
				const { message } = response.data;
				dispatch({
					type: monitoringDataType.SAVE_REGISTER_FAILURE,
					payload: { errors: { message } }
				});
			} else {
				dispatch({
					type: monitoringDataType.SAVE_REGISTER_FAILURE,
					payload: {
						errors: { message: 'Sem permissão para salvar' }
					}
				});
			}
		}
	};

export const resetsaveDataMonitoring = () => {
	return { type: monitoringDataType.SAVE_REGISTER_RESET };
};

export const editDataMonitoring =
	({ route, params, typeAlert }) =>
	async (dispatch) => {
		dispatch({ type: monitoringDataType.EDIT_REGISTER_REQUEST });
		try {
			const res = await api.put(route, params, {
				headers: headers()
			});
			const { data } = res || {};
			dispatch({
				type: monitoringDataType.EDIT_REGISTER_SUCCESS,
				payload: {
					data,
					message: 'Atualizado com sucesso',
					type: typeAlert
				}
			});
			return data;
		} catch (error) {
			const { response } = error;
			if (response && response.status === 400) {
				const { message } = response.data;
				console.log(message);
				const testm = 'error';
				dispatch({
					type: monitoringDataType.EDIT_REGISTER_FAILURE,
					payload: { errors: { message: 'error' } }
				});
			} else {
				dispatch({
					type: monitoringDataType.EDIT_REGISTER_FAILURE,
					payload: {
						errors: { message: 'Sem permissão para editar' }
					}
				});
			}
		}
	};

export const destroy = (params) => async (dispatch) => {
	const { user } = getUserLocalStorage();
	params.userId = user.id;

	dispatch({ type: monitoringDataType.REMOVE_REGISTER_REQUEST });
	try {
		const res = await api.delete(`/${params.routeMonitoring}/delete_many`, {
			data: params,
			headers: headers()
		});
		const { data } = res || {};
		dispatch({
			type: monitoringDataType.REMOVE_REGISTER_SUCCESS,
			payload: {
				hashis: params.hashis,
				message: 'Registro apagado com succeso.'
			}
		});
		return data;
	} catch (error) {
		const { response } = error;
		if (response && response.status === 400) {
			const { message } = response.data;
			dispatch({
				type: monitoringDataType.REMOVE_REGISTER_FAILURE,
				payload: { errors: { message } }
			});
		} else {
			dispatch({
				type: monitoringDataType.REMOVE_REGISTER_FAILURE,
				payload: {
					errors: { message: 'Sem permissão para deletar' }
				}
			});
		}
	}
};

export const approve = (params) => async (dispatch) => {
	const { id, status, routeMonitoring, approvedBy } = params;

	const body = {
		approve: status === 'approved' ? true : false,
		approvedBy
	};

	const endpoint = routeMonitoring === 'alerts' ? `approve_alert/${id}` : `approve_traditional_uses/${id} `;

	dispatch({ type: monitoringDataType.APPROVE_ITEM_REQUEST });
	try {
		const res = await api.put(`/${endpoint}`, body, { headers: headers() });
		const { data } = res || {};
		dispatch({
			type: monitoringDataType.APPROVE_ITEM_SUCCESS,
			payload: data
		});
		return data;
	} catch (error) {
		const { response } = error;
		if (response && response.status === 400) {
			const { message } = response.data;
			dispatch({
				type: monitoringDataType.APPROVE_ITEM_FAILURE,
				payload: { errors: { message } }
			});
		} else {
			dispatch({
				type: monitoringDataType.APPROVE_ITEM_FAILURE,
				payload: {
					errors: { message: 'Sem permissão para editar' }
				}
			});
		}
	}
};

export const closeErrorDestroy = () => {
	return { type: monitoringDataType.CLOSE_ERROR_DESTROY };
};

export const addRouteMonitoring = (params) => {
	return { type: monitoringDataType.ADD_ROUTE_DESTROY, payload: params };
};

export const toogleIsRegister = (params) => {
	return { type: monitoringDataType.OPEN_IS_REGISTER, payload: params };
};

// actions do filtro

export const addFilterTi = (data) => {
	return { type: monitoringDataType.ADD_FILTER_TI_MONITORING, payload: data };
};

export const removeFilterTi = () => {
	return { type: monitoringDataType.REMOVE_FILTER_TI_MONITORING };
};

export const addFilterType = (data) => {
	return { type: monitoringDataType.ADD_FILTER_TYPE_MONITORING, payload: data };
};

export const removeFilterType = () => {
	return { type: monitoringDataType.REMOVE_FILTER_TYPE_MONITORING };
};

export const addFilterDate = (data) => {
	return { type: monitoringDataType.ADD_FILTER_DATE_MONITORING, payload: data };
};

export const removeFilterDate = () => {
	return { type: monitoringDataType.REMOVE_FILTER_DATE_MONITORING };
};

export const geoDataDownload = (params) => async (dispatch) => {
	const { user } = getUserLocalStorage();
	params.userId = user.id;
	dispatch({ type: monitoringDataType.DOWNLOAD_ITEM_REQUEST });
	try {
		const res = await api.post(`/${params.routeMonitoring}/download`, params, {
			headers: headers(),
			responseType: 'blob'
		});

		const fileURL = window.URL.createObjectURL(new Blob([res.data]));
		const fileLink = document.createElement('a');
		fileLink.href = fileURL;
		fileLink.setAttribute('download', params.nameFile);
		document.body.appendChild(fileLink);
		fileLink.click();

		dispatch({
			type: monitoringDataType.DOWNLOAD_ITEM_SUCCESS,
			payload: { message: 'Download realizado com succeso.' }
		});
	} catch (error) {
		dispatch({
			type: monitoringDataType.DOWNLOAD_ITEM_FAILURE,
			payload: { message: 'Erro ao fazer o Download. Tente novamente.' }
		});
	}
};
