/* eslint-disable arrow-parens */
/* eslint-disable prettier/prettier */
import React, { useEffect, useState, memo } from 'react';
import { isEmptyObject, returnTextOfTypes, returnReqEndPoint, componentsViewUsers } from 'helpers/utils';
import { useDispatch, useSelector } from 'react-redux';
import {
	setMonitoringFormActivated,
	getDataMonitoringAction,
	successDataMonitoringAction,
	errorDataMonitoringAction,
	addRouteMonitoring,
	resetsaveDataMonitoring
} from 'store/actions/monitoringDataAction';
import AddAlertOrTraditionalUse from 'components/AddAlertOrTraditionalUse';
import { initialListAction } from 'store/actions/dataUserToShowOnMapAction';
import { api } from 'services/api';
import { zoomToExtentMap } from 'components/Map/ControlMap';
import Load from 'components/Load/Load';
import TotalAlerts from './TotalAlerts';
import Export from './Export';
import Consult from './Consult';
import ItemEditFromMap from './ItemEditFromMap/index.js';
import * as S from './styled';

// type = o tipo do alerta que estamos trabalhando em cima dele. Como: alert, whereIBeen e etc
// dataMonitoredUsers = caso pretenda usar este componente no modo 'monitor olhando os dados do seu monitorado' passe a array de alertas
// managerWatching = caso passe dataMonitoredUsers, obrigatoriamente passe essa props como true. Isto é utilizado pra saber se estamos olhando nossos próprios alertas, ou se estamos olhando alertas de terceiros

function OptionsMonitoring({ type = '', dataMonitoredUsers = [], managerWatching = false }) {
	const dispatch = useDispatch();

	// retorna os textos que vão para o componente TotalAlerts
	const textByType = returnTextOfTypes(type);

	// Apenas para jogar na tela o loading quando estiver carregando os dados da requisição
	const { isLoading, dataMonitoring, success } = useSelector((state) => state.monitoring);

	const { dataUser: onMap } = useSelector((state) => state.dataUserToShowOnMap);

	// para saber se o menu de editar estará aberto ou não
	const { isEdit } = useSelector((state) => state.editDataUserOnMap);

	// dados do usuário logado usado para fazer requisição dos alertas do mesmo
	const [dataUser, setDataUser] = useState({});

	const [modalAdd, setModalAddState] = useState(false);

	const setModalAdd = (bool) => {
		dispatch(setMonitoringFormActivated(bool));
		setModalAddState(bool);
	};

	useEffect(() => {
		if (success) {
			// resetForm()
			setModalAdd(false);
			dispatch(resetsaveDataMonitoring());
		}
	}, [success]);

	// atualiza lista que mostra no mapa, conforme a do redux dataMonitoring
	useEffect(() => {
		dispatch(initialListAction(dataMonitoring));
	}, [dataMonitoring, onMap]);

	useEffect(() => {
		if (isEdit) {
			setModalAdd(false);
		}
	}, [isEdit]);

	// Add a rota para as apis
	useEffect(async () => {
		if (type !== '') {
			dispatch(addRouteMonitoring(returnReqEndPoint(type)));
		}
	}, [type]);

	// requisição para pegar dados dos alertas dependendo do tipo DO USUÁRIO LOGADO
	// este useEffect só será executado caso NÃO haja dados de alerta passados por props, ou seja, o usuário está olhando os próprios alertas
	useEffect(async () => {
		if (type !== '' && !isEmptyObject(dataUser) && !managerWatching) {
			// necessário essa atribuição pq as chaves dos alertas do retorno da requisição mudam como: alerts, where_I_Been e etc.
			const typeAlert = returnReqEndPoint(type);
			dispatch(getDataMonitoringAction());
			await api
				.get(`/${typeAlert}/user/${dataUser.user.id}`, {
					headers: { Authorization: `Bearer ${dataUser.token}` }
				})
				.then((res) => {
					// res.data[typeAlert] por que [typeAlert] é o nome da chave do objeto res.data
					// antes de ser jogado a lista no redux, preciso add o campo 'isChecked'
					if (res.data && typeof res.data[typeAlert] === 'object' && res.data[typeAlert].length > 0) {
						const newList = res.data[typeAlert].map((item) => {
							item.isChecked = true;

							// Adiciona o typeData no trajeto e onde estive para montar o estilo do icone e features
							if (typeAlert === 'tracks' || typeAlert === 'where_i_been') {
								item.type = typeAlert;
							}

							return item;
						});

						dispatch(successDataMonitoringAction(newList));
					} else {
						dispatch(successDataMonitoringAction([]));
					}
				})
				.catch((erro) => {
					const { response: res } = erro;
					dispatch(errorDataMonitoringAction(res?.data?.message || 'Erro desconhecido, tente novamente mais tarde.'));
				});
		}
	}, [type, dataUser]);

	// pegando dados dos alertas por props e jogando no redux
	// este useEffect só será executado caso haja dados de alerta passados por props, ou seja, o gerente está olhando os alertas de seus monitores
	useEffect(() => {
		if (type !== '' && managerWatching) {
			const newList = dataMonitoredUsers.map((item) => {
				item.isChecked = true;
				return item;
			});
			dispatch(successDataMonitoringAction(newList));
			dispatch(initialListAction(newList));
		}
	}, [type, dataMonitoredUsers]);

	// pegar os dados do  usuário assim que o componente é montado
	useEffect(() => {
		const user = JSON.parse(localStorage.getItem('user'));
		setDataUser(user);
		return () => {
			setDataUser({});

			// voltar o zoom inicial pro mapa quando o comp e destruido
			zoomToExtentMap();
		};
	}, []);

	useEffect(() => {
		componentsViewUsers({
			page: `plataforma/painel/monitoramento`,
			title: textByType.text
		});
	}, []);

	return (
		<S.containerOption>
			{isLoading ? (
				<Load />
			) : (
				<>
					{/* essa div com none e initial precisa existir pra sumir com o painel por baixo da tela inicial */}
					<div
						style={{
							width: '100%',
							display: isEdit ? 'none' : 'initial'
						}}
					>
						{modalAdd ? (
							<S.containerAddData>
								<AddAlertOrTraditionalUse type={type} setModalAdd={setModalAdd} resetForm={() => setModalAdd(false)} />
							</S.containerAddData>
						) : (
							<>
								<TotalAlerts
									managerMonitoring={managerWatching}
									type={type}
									nameBtn={textByType.textBtn}
									nameTotal={textByType.text}
									modalAdd={modalAdd}
									setModalAdd={setModalAdd}
								/>
								<Export typeMonitoringAndMenu={type} />
								<Consult typeMonitoringAndMenu={type} />
							</>
						)}
					</div>

					{isEdit && <ItemEditFromMap type={type} />}
				</>
			)}
		</S.containerOption>
	);
}

export default memo(OptionsMonitoring);
