import React from 'react';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import Popover from '@mui/material/Popover';
import { actionsUsers } from 'helpers/utils';
import {
	FacebookShareButton,
	FacebookIcon,
	TelegramShareButton,
	TelegramIcon,
	TwitterShareButton,
	TwitterIcon,
	WhatsappShareButton,
	WhatsappIcon,
	LinkedinShareButton,
	LinkedinIcon,
	EmailShareButton,
	EmailIcon
} from 'react-share';
import Typography from '@mui/material/Typography';
import { svg } from 'lib/svgList';
import { inforSomai } from './config';

export default function ShareSocial({ sizeIcon }) {
	const [anchorEl, setAnchorEl] = React.useState(null);

	const handlePopoverOpen = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handlePopoverClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);

	const handleGA = ({ label }) => {
		actionsUsers({
			category: `plataforma/menuLateral/compartilhamento`,
			action: 'Click no botão',
			label
		});
	};
	return (
		<Grid item>
			<Typography aria-owns={open ? 'mouse-over-popover' : undefined} aria-haspopup="true" onClick={handlePopoverOpen}>
				<IconButton onClick={handlePopoverOpen} edge="start">
					<img alt="Share" src={svg.icon.shareOrange} style={{ width: 45 }} />
				</IconButton>
			</Typography>
			<Popover
				id="mouse-over-popover"
				sx={{ zIndex: 300000 }}
				open={open}
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: 'center',
					horizontal: 'left'
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'left'
				}}
				onClose={handlePopoverClose}
				disableRestoreFocus
			>
				<Grid sx={{ padding: 1 }}>
					<Grid item xs={12}>
						<FacebookShareButton
							style={{ margin: 3 }}
							url={inforSomai.url}
							quote={inforSomai.title}
							hashtag={inforSomai.hashtag}
							onClick={() => handleGA({ label: 'FacebookShareButton' })}
						>
							<FacebookIcon size={sizeIcon} round />{' '}
						</FacebookShareButton>

						<TwitterShareButton
							style={{ margin: 3 }}
							url={inforSomai.url}
							title={inforSomai.title}
							onClick={() => handleGA({ label: 'TwitterShareButton' })}
						>
							<TwitterIcon size={sizeIcon} round />
						</TwitterShareButton>

						<TelegramShareButton
							style={{ margin: 3 }}
							url={inforSomai.url}
							title={inforSomai.title}
							onClick={() => handleGA({ label: 'TelegramShareButton' })}
						>
							<TelegramIcon size={sizeIcon} round />
						</TelegramShareButton>
					</Grid>
					<Grid item xs={12}>
						<WhatsappShareButton
							style={{ margin: 3 }}
							url={inforSomai.url}
							title={inforSomai.title}
							separator=":: "
							onClick={() => handleGA({ label: 'WhatsappShareButton' })}
						>
							<WhatsappIcon size={sizeIcon} round />
						</WhatsappShareButton>

						<LinkedinShareButton
							style={{ margin: 3 }}
							url={inforSomai.url}
							onClick={() => handleGA({ label: 'LinkedinShareButton' })}
						>
							<LinkedinIcon size={sizeIcon} round />
						</LinkedinShareButton>

						<EmailShareButton
							style={{ margin: 3 }}
							url={inforSomai.url}
							subject="Conheça o SOMAI"
							body={inforSomai.title}
							onClick={() => handleGA({ label: 'EmailShareButton' })}
						>
							<EmailIcon size={sizeIcon} round />
						</EmailShareButton>
					</Grid>
				</Grid>
			</Popover>
		</Grid>
	);
}
