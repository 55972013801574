import React from 'react';
import Load from 'components/Load/Load';
import styled from 'styled-components';

// Passe apenas a posição da seta de 1 a 5 (pode ser float)

export default function FireRisk({ positionProps = 0, isLoading = false }) {
	function returnFireRisk() {
		if (isLoading) {
			return <Load />;
		}
		return positionProps === 0 ? (
			<p className="notes">Não há dado para calcular risco de fogo</p>
		) : (
			<>
				<Gradiente />
				<DescriptionContainer>
					<GetTogetherDescription>
						<Line />
						<p className="notes">MÍNIMO</p>
						<Arrow style={Math.round(positionProps) !== 1 ? { display: 'none' } : null} />
					</GetTogetherDescription>
					<GetTogetherDescription>
						<Line />
						<p className="notes">BAIXO</p>
						<Arrow style={Math.round(positionProps) !== 2 ? { display: 'none' } : null} />
					</GetTogetherDescription>
					<GetTogetherDescription>
						<Line />
						<p className="notes">MÉDIO</p>
						<Arrow style={Math.round(positionProps) !== 3 ? { display: 'none' } : null} />
					</GetTogetherDescription>
					<GetTogetherDescription>
						<Line />
						<p className="notes">ALTO</p>
						<Arrow style={Math.round(positionProps) !== 4 ? { display: 'none' } : null} />
					</GetTogetherDescription>
					<GetTogetherDescription>
						<Line />
						<p className="notes">CRÍTICO</p>
						<Arrow style={Math.round(positionProps) !== 5 ? { display: 'none' } : { display: 'flex' }} />
					</GetTogetherDescription>
				</DescriptionContainer>
			</>
		);
	}
	return <Container>{returnFireRisk()}</Container>;
}

const Container = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	margin-bottom: 10px;
	margin-top: 5px;
`;

const Arrow = styled.div`
	height: 0.8rem;
	width: 0.8rem;
	border-left: 0.5rem solid transparent;
	border-right: 0.5rem solid transparent;
	border-bottom: 0.5rem solid #291f17;
	transform: rotate(180deg);
	position: absolute;
	transform: translateY(-520%) rotate(180deg);
`;

const Gradiente = styled.div`
	margin-top: 1rem;
	width: 100%;
	height: 2rem;
	border: 5px solid white;
	border-radius: 15px;
	background-image: linear-gradient(
		to right,
		#3d9246,
		#639c3f,
		#86a639,
		#a9ae38,
		#cdb43c,
		#daac34,
		#e7a430,
		#f49a30,
		#ed811c,
		#e6660a,
		#de4700,
		#d51800
	);
`;

const DescriptionContainer = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-top: 0.5rem;
`;

const GetTogetherDescription = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	p {
		font-size: 0.6rem;
		margin-top: 0.5rem;
		font-family: 'nunito-bold';
		color: #807368;
	}
`;

const Line = styled.div`
	width: 0.12rem;
	height: 1rem;
	background-color: #807368;
`;
