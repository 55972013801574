/* eslint-disable no-unused-vars */
import React, { useEffect, useState, memo } from 'react';
import { removeLayerFromMap, clearVector } from 'store/actions/mapActions';
import { deleteInformation } from 'store/actions/tiSelectedAction';
import { componentsViewUsers } from 'helpers/utils';
import { useDispatch, useSelector } from 'react-redux';
import ButtonList from 'components/ButtonList';
import Users from 'pages/TabPanel/Users';
import { getUserLocalStorage } from 'services/session';

import { clearDataMonitoringAction } from 'store/actions/monitoringDataAction';
import { deleteListAction } from 'store/actions/dataUserToShowOnMapAction';
import { getSystemUsers } from 'store/actions/webGisActions';
import { hideshowfeatures } from 'components/Map/Utils';
import { useMeasureStore } from 'services/map/measure.context';
import MonitoringACI from './MonitoringACI';
import Layers from './Layers';
import Territory from './Territory';
import Statistics from './Statistics';
import * as S from './styled';

function TabTis() {
	const dispatch = useDispatch();
	const [territory, setTerritory] = useState('amazonia');
	const [stateSelectedIndex, setStateSelectedIndex] = useState({});
	const [isSession, setIsSession] = useState({});
	const currentTab = useSelector((state) => state.isTabRef);
	const { loading } = useSelector((state) => state.user);
	const { showFeatures, setShowFeatures } = useMeasureStore();

	useEffect(() => {
		setShowFeatures(true);
	}, []);
	useEffect(() => {
		setIsSession(getUserLocalStorage());
	}, [loading]);

	useEffect(() => {
		if (isSession?.token && currentTab === 'tab1') {
			dispatch(getSystemUsers());
		}
	}, [isSession, currentTab]);
	useEffect(() => {
		hideshowfeatures('measure_draw', true);
		hideshowfeatures('shapes_draw', true);
	}, []);

	useEffect(
		() =>
			// Quando desmonta o componente limpa os estados abaixo
			() => {
				removeLayerFromMap('tiSelectedTabTi');
				dispatch(deleteInformation());
				dispatch(clearDataMonitoringAction());
				dispatch(deleteListAction());
				clearVector('monitoring');
				clearVector('getPoint');
			},
		[]
	);
	useEffect(() => {
		componentsViewUsers({
			page: 'plataforma/territorio',
			title: 'TAB Territorio'
		});
	}, []);

	return (
		<S.containerTis>
			<Territory
				territory={territory}
				setTerritory={setTerritory}
				stateSelectedIndex={stateSelectedIndex}
				setStateSelectedIndex={setStateSelectedIndex}
			/>
			<ButtonList
				buttonName="Dados no ACI"
				buttonText="Acesse dados do aplicativo Alerta Clima Indígena (ACI)."
				content={isSession && isSession?.token !== '' ? <MonitoringACI /> : <Users />}
				defaultOpen={false}
			/>
			<ButtonList
				buttonName="Dados Oficiais"
				defaultOpen
				theme="light"
				content={
					<>
						<Layers />
						<Statistics territory={territory} stateSelectedIndex={stateSelectedIndex} />
					</>
				}
			/>
		</S.containerTis>
	);
}

export default memo(TabTis);
