import React, { useState, useEffect, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateByADM, closeErrorUser } from 'store/actions/userActions';
import {
	messageAlertUpdateApprove,
	messageAlertUpdateRecuse,
	waitingForApproval,
	filterRoles,
	nameButtonForSituation
} from 'helpers/utils';
import InputRegister from 'components/InputRegister';
import { getUserLocalStorage } from 'services/session';
import Modal from 'components/Modal';
import Select from 'components/Buttons/Select/Select';
// import Search from 'components/Buttons/Search/Search';
import { color } from 'styles';
import * as S from './styled';

function UpdateUsersADM({ user, stateModal, setStateModal = () => {} }) {
	const dispatch = useDispatch();

	const [session, setSession] = useState({});
	const roleManager = session?.user?.user_role?.role || '';

	useEffect(() => {
		setSession(getUserLocalStorage());
	}, []);

	const { loadingUpdate, roles, loadingRoles, messageUpdate } = useSelector((state) => state.user);

	// const { listOrg } = useSelector((state) => state.organizations);

	const [role, setRole] = useState([]);
	const [profileUser, setProfileUser] = useState([]);
	const [organization, setOrganization] = useState([]);
	const [adjustmentSituation, setAdjustmentSituation] = useState('');

	// verifica se o perfil esta em ajuste / aprovação

	const inApprove = user.situation_id === 3 || user.situation_id === 4 || user.situation_id === 6;

	useEffect(() => {
		// Constroi a organização do usuario para o select
		if (user && user?.user_organization?.id) {
			setOrganization({
				value: user?.user_organization.id,
				label: user?.user_organization.name
			});
		}
		// Constroi o perfil do usuario para o select
		if (user && user?.profile?.id) {
			setProfileUser({
				value: user?.profile.id,
				label: user?.profile.name
			});
		}
		// Constroi a role do usuario para o select

		if (user && user?.user_role?.id) {
			setRole({
				value: user?.user_role.id,
				label: user?.user_role.name
			});
		}
	}, [user]);

	// Nome de quem editou o registro
	const {
		firstName,
		lastName,
		email,
		user_role: userRole
	} = session?.user || {
		firstName: '',
		lastName: ''
	};

	// Dados para envio
	const formData = {
		id: user.id,
		role_id: role?.value,
		researcher_profile_id: profileUser.value,
		organization_id: organization?.value || null,
		// Se caso for menor de idade a situação muda de 6 para 4 direcionando o usuario para a organização verificar se ele faz parte da mesma
		situation_id: user?.situation_id === 6 && user?.user_role?.id !== 5 ? 4 : 1,
		adjustmentSituation,
		editedBy: {
			name: `${firstName} ${lastName}`,
			email,
			typeManager: `${userRole?.name?.toLowerCase()}`
		}
	};

	// Apaga erro de requisição no reducer
	const handleClose = () => {
		dispatch(closeErrorUser());
		setAdjustmentSituation('');
	};

	const sendData = (e) => {
		e.preventDefault();
		dispatch(updateByADM(formData))
			.then(() => {})
			.catch(() => {});
	};

	const name = `${user.firstName} ${user.lastName}`;

	const nameButtonRecuseAndProve = adjustmentSituation === 1 ? 'Confirmar' : 'Confirmar recusa';
	// const nameButtonSubmit = inApprove ? 'Aprovar' : 'Atualizar';

	return (
		<Modal
			styleContent={{
				width: '700px',
				maxWidth: '90%',
				height: 'auto',
				zIndex: 200
			}}
			isOpen={stateModal}
			isClose={handleClose}
			setIsOpen={setStateModal}
			useBtnEnd={false}
		>
			{messageUpdate || (
				<form onSubmit={sendData} style={styledConfg.form}>
					<S.Label>Nome</S.Label>
					<InputRegister type="text" value={name} disabled />

					<S.Label>E-mail</S.Label>
					<InputRegister type="email" value={user.email} disabled />

					<S.Label>Organização</S.Label>
					<InputRegister type="text" value={organization?.label || ''} disabled />

					{/* Verifica se aprova ou edita */}
					{waitingForApproval(user.situation_id) ? (
						<>
							<S.Label>Tipo de usuário</S.Label>
							<InputRegister type="text" value={role?.label || ''} disabled />
						</>
					) : (
						<>
							<S.Label>Tipo de usuário</S.Label>

							<Select
								itemSelected={role}
								setItemSelected={setRole}
								options={filterRoles(roleManager, roles)}
								style={styledConfg.select}
								isLoading={loadingRoles}
								isLineOnTheList
								displayListInitial
							/>
						</>
					)}

					<S.BoxButton>
						{adjustmentSituation !== '' ? (
							<div
								style={{
									marginTop: 10
								}}
							>
								{/* Descrição para aprovar ou recusar */}
								{adjustmentSituation === 1 ? (
									<span className="text2" style={{ fontSize: 14 }}>
										{messageAlertUpdateApprove(user.situation_id, name)}
									</span>
								) : null}

								{adjustmentSituation === 2 ? (
									<span className="text2" style={{ fontSize: 14 }}>
										{messageAlertUpdateRecuse(user.situation_id, name)}
									</span>
								) : null}

								<div
									style={{
										display: 'flex',
										justifyContent: 'center',
										width: '100%'
									}}
								>
									<S.ButtonCancel type="button" onClick={() => setAdjustmentSituation('')}>
										Cancelar
									</S.ButtonCancel>
									<S.ButtonConfirm type="submit">
										{loadingUpdate ? 'Aguarde...' : nameButtonRecuseAndProve}
									</S.ButtonConfirm>
								</div>
							</div>
						) : (
							<div
								style={{
									display: 'flex',
									justifyContent: 'center',
									width: '100%'
								}}
							>
								{/* Mostra apenas para status em ajuste e não permiti recusar perfil id 4 e 6 */}
								{inApprove && user.situation_id !== 4 && user.situation_id !== 6 ? (
									<S.ButtonCancel type="button" onClick={() => setAdjustmentSituation(2)}>
										Recusar
									</S.ButtonCancel>
								) : null}
								<S.Button type="button" onClick={() => setAdjustmentSituation(1)}>
									{nameButtonForSituation(user?.situation_id, user?.user_role?.id)}
								</S.Button>
							</div>
						)}
					</S.BoxButton>
				</form>
			)}
		</Modal>
	);
}

const styledConfg = {
	select: {
		width: '100%',
		height: 55,
		color: color.interface.inputs,
		fontSize: 16,
		fontFamily: 'Nunito',
		border: `1.5px solid ${color.interface.inputs}`,
		padding: '0 22px',
		borderRadius: 10
	},
	form: {
		width: '100%',
		height: '100%',
		padding: 10
	}
};
export default memo(UpdateUsersADM);
