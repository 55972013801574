import React, { useEffect, useState } from 'react';
import { ReactMic } from 'react-mic';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import StopIcon from '@mui/icons-material/Stop';
import * as S from './styled';

export default function AudioRecorder({ audioRecorder = null, isAudio = '', setAudioRecorder = () => {} }) {
	const [isRecording, setIsRecording] = useState(false);
	const [audioByMonitoring, setAudioByMonitoring] = useState('');

	function start() {
		setIsRecording(true);
		setAudioRecorder(null);
		setAudioByMonitoring('');
	}

	function stop() {
		setIsRecording(false);
	}

	const onStop = (recordedBlob) => {
		setIsRecording(false);

		setAudioRecorder(recordedBlob);
	};

	useEffect(() => {
		if (isAudio && isAudio.length > 0) {
			setAudioByMonitoring(isAudio);
		} else {
			setAudioByMonitoring('');
		}
	}, [isAudio]);

	return (
		<S.containerRecorder>
			<S.invisiableRecorder>
				<ReactMic
					record={isRecording}
					className="sound-wave"
					onStop={onStop}
					onData={console.log}
					strokeColor="#000000"
					backgroundColor="#FF4081"
				/>
			</S.invisiableRecorder>
			<S.btnRecorder>
				<button
					type="button"
					onClick={() => {
						if (!isRecording) {
							start();
						} else {
							stop();
						}
					}}
				>
					<div
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center'
						}}
					>
						{!isRecording ? (
							<>
								<RecordVoiceOverIcon style={{ width: 22 }} sx={{ mr: 1 }} /> Gravar
							</>
						) : (
							<>
								<StopIcon sx={{ mr: 1 }} /> Parar
							</>
						)}
					</div>
				</button>

				{audioRecorder === null && isRecording ? <S.animationRecorder>Gravando...</S.animationRecorder> : null}
			</S.btnRecorder>
			{audioRecorder !== null ? (
				<audio controls>
					<source src={audioRecorder?.blobURL} type={audioRecorder?.blob?.type} />
					<track src={audioRecorder?.blobURL} kind="captions" label="gravação do audio" />
				</audio>
			) : null}

			{audioByMonitoring && audioRecorder === null ? (
				<audio controls>
					<source src={audioByMonitoring} type="audio/mpeg" />
					<track src={audioByMonitoring} kind="captions" srcLang="pt" label="Alerta Clima Indígena (ACI)" />
					Seu navegador não suporta o elemento de áudio.
				</audio>
			) : null}
		</S.containerRecorder>
	);
}
