import styled from 'styled-components';

export const containerList = styled.div`
	margin-top: ${({ props }) => (props?.marginTop ? props.marginTop : '1rem')};
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 1rem;
	padding-left: ${({ props }) => (props?.paddingLeft ? props.paddingLeft : '1rem')};
	padding-right: ${({ props }) => (props?.paddingRight ? props.paddingRight : '1rem')};
	padding-bottom: 2.5rem;
`;
