/* eslint-disable import/no-extraneous-dependencies */

import React from 'react';
import Load from 'components/Load/Load';
import CheckAnimation from 'components/Animations/CheckAnimation/CheckAnimation';
import UncheckAnimation from 'components/Animations/UncheckAnimation/UncheckAnimation';
import Button from 'components/Buttons/Button/Button';
import PropTypes from 'prop-types';
import * as S from './styled';

export default function PackReq({ isReq, errorReq, linkPdf, text, textBtn, functionBtn }) {
	return (
		<S.containerPack>
			{isReq && (
				<>
					<S.pEffect>{text}</S.pEffect>
					<Load />
				</>
			)}
			{!isReq && !errorReq && (
				<>
					<CheckAnimation />
					<S.textP style={{ marginBottom: '10S.textPx' }}>PDF gerado com sucesso! </S.textP>

					{linkPdf && (
						<a style={{ textDecoration: 'none' }} target="_blank" rel="noreferrer" download="relatorio" href={linkPdf}>
							<Button onClick={functionBtn} text={textBtn}>
								{textBtn}
							</Button>
						</a>
					)}
				</>
			)}

			{!isReq && errorReq && (
				<>
					<UncheckAnimation />
					<S.textP> Erro tente novamente mais tarde.</S.textP>
				</>
			)}
		</S.containerPack>
	);
}

PackReq.propTypes = {
	/**
	 * Estado para controlar se a requisção está sendo feita ou não
	 */

	isReq: PropTypes.bool,
	/**
	 * Ca
	 */

	errorReq: PropTypes.bool,
	/**
	 * Cor de fundo do load.
	 */

	linkPdf: PropTypes.string,
	/**
	 * Cor de fundo do load.
	 */

	text: PropTypes.string,
	/**
	 * Cor de fundo do load.
	 */

	textBtn: PropTypes.string,
	/**
	 * Cor de fundo do load.
	 */

	functionBtn: PropTypes.func
	/**
	 * Cor de fundo do load.
	 */
};

PackReq.defaultProps = {
	isReq: false,
	errorReq: false,
	linkPdf: null,
	text: 'Carregando...',
	textBtn: 'Acessar',
	functionBtn: () => {}
};
