import React from 'react';
import { useDispatch } from 'react-redux';
import { updateSystemUsers, setStateModalMultFilter } from 'store/actions/webGisActions';
import { checkIfAllChecked } from './utils';
import * as S from './styled';

function SeeAll({ systemUsers }) {
	const dispatch = useDispatch();

	const handleSelectAll = (event) => {
		if (event.target.checked) {
			// atualiza o estado do array systemUsers com os itens marcados
			const updatedSystemUsers = systemUsers.map((user) => ({
				...user,
				isChecked: true
			}));

			dispatch(updateSystemUsers(updatedSystemUsers));
		} else {
			// atualiza o estado do array systemUsers com os itens desmarcados
			const updatedSystemUsers = systemUsers.map((user) => ({
				...user,
				isChecked: false
			}));

			dispatch(updateSystemUsers(updatedSystemUsers));
		}
	};

	return (
		<S.BoxBottom>
			<button className="see-all-button" type="button" onClick={() => dispatch(setStateModalMultFilter(true))}>
				<p>VER TODOS</p>
				<svg xmlns="http://www.w3.org/2000/svg" width="10" height="8" fill="none" viewBox="0 0 10 8">
					<path
						fill="#69625D"
						d="M9.824 4.367a.506.506 0 0 0 .13-.167.462.462 0 0 0 0-.4.506.506 0 0 0-.13-.167L5.657.113A.596.596 0 0 0 4.9.16a.478.478 0 0 0-.149.344c0 .127.054.25.15.343l3.137 2.65H.556a.587.587 0 0 0-.393.147A.48.48 0 0 0 0 4a.48.48 0 0 0 .163.356.587.587 0 0 0 .393.147h7.482L4.9 7.153a.478.478 0 0 0-.15.343c0 .128.053.25.15.344a.596.596 0 0 0 .757.048l4.166-3.52Z"
					/>
				</svg>
			</button>

			<input type="checkbox" onChange={handleSelectAll} checked={checkIfAllChecked(systemUsers)} />
		</S.BoxBottom>
	);
}

export default SeeAll;
