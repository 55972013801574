import React from 'react';
import styled, { css } from 'styled-components';
import { CgClose } from 'react-icons/cg';
import { svg } from 'lib/svgList';

const ModalWrapper = styled.div`
	position: relative;
`;

const ModalContent = styled.div`
	padding: 10px 0;
`;

const ShareItemContent = styled.div`
	display: flex;
	align-items: baseline;
	justify-content: space-between;
	-webkit-box-shadow: 0px 3px 1px #d9d5d2;
	-moz-box-shadow: 0px 3px 1px #d9d5d2;
	box-shadow: 0px 3px 1px #d9d5d2;
	margin-top: 16px;

	p {
		color: #006946;
		font-weight: 700;
		font-size: 18px;
	}
`;

const Title = styled.h1`
	margin-top: 0;
	font-size: 1.6rem;
	word-break: keep-all;
	font-size: 24px;
	font-weight: 700;
	color: #291f17;
`;

const CloseModalButton = styled(CgClose)`
	cursor: pointer;
	position: absolute;
	top: -10px;
	right: -18px;
	width: 38px;
	height: 38px;
	padding: 6px;
	z-index: 10;
	background-color: #d44500;
	border-radius: 50%;
	color: #fff;
	@media (max-width: 450px) {
		& {
			right: -5px;
			width: 30px;
			height: 30px;
		}
	}
`;

const socialButton = css`
	height: 30px !important;
	width: 30px;
	color: #006946;
	cursor: pointer;
	/* border: 2px solid #006946;
	border-radius: 50px; */
	//padding: 10px;
	transition: 0.3s;
	display: flex;
	align-items: center;
	img {
		width: 100% !important;
		height: 40px !important;
	}
	//justify-content: center;
`;

const SocilaButton = styled.a`
	${socialButton}
`;
const ModalFooter = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 30px;
	button {
		cursor: pointer;
		font-size: 10px;
		color: #006946;
		font-weight: 800;
		width: 75px !important;
		height: 15px !important;
		background: transparent;
		-webkit-box-shadow: 0px 3px 1px #006946;
		-moz-box-shadow: 0px 3px 1px #006946;
		box-shadow: rgb(0, 105, 70) 0px 3px 0px;
		border: 0px;
		background-color: none !important;
		&:hover {
			background: none !important;
			border: 0 !important;
		}
	}
`;

function isMobileOrTablet() {
	return /(android|iphone|ipad|mobile)/i.test(navigator.userAgent);
}

const item = [
	{
		name: 'Facebook',
		href: 'https://www.facebook.com/sharer/sharer.php?u=https://plataforma.somai.org.br/',
		urlIcon: svg.icon.facebookGreenIcon
	},
	{
		name: 'Twitter',
		href: 'https://twitter.com/intent/tweet?text=https://plataforma.somai.org.br/',
		urlIcon: svg.icon.twitterGreenIcon
	},
	{
		name: 'Whatsapp',
		href:
			// eslint-disable-next-line no-useless-concat
			`https://${isMobileOrTablet() ? 'api' : 'web'}.whatsapp.com/send?` + `text="https://plataforma.somai.org.br/"`,
		urlIcon: svg.icon.whatsappGreenIcon
	}
	/* {
		name: 'Link',
		href: 'https://www.instagram.com/ipam_amazonia/',
		urlIcon: svg.icon.linkGreenIcon
	} */
];

function ShareModal({ onClose }) {
	const closeModal = () => {
		onClose();
	};

	return (
		<ModalWrapper>
			<div className="somai-confirm-alert-body" style={{ maxWidth: 300 }}>
				<Title>Compartilhe</Title>
				<ModalContent>
					{item.map((i) => (
						<ShareItemContent key={i.href}>
							<p>{i.name} </p>
							<SocilaButton aria-hidden target="_blank" rrel="canonical" href={i.href}>
								<img src={i.urlIcon} alt={i.name} />
							</SocilaButton>
						</ShareItemContent>
					))}
				</ModalContent>
				<ModalFooter>
					<button onClick={closeModal} type="button">
						CANCELAR
					</button>
				</ModalFooter>
			</div>

			<CloseModalButton aria-label="Close modal" onClick={closeModal} />
		</ModalWrapper>
	);
}

export default ShareModal;
