import React, { useState, useLayoutEffect } from 'react';
import GenericAccordion from 'components/GenericAccordion';
import { color } from 'styles/Theme';
import { useDispatch, useSelector } from 'react-redux';
import { editListAction } from 'store/actions/dataUserToShowOnMapAction';
import Check from 'components/Buttons/Check';
import { clearVector, onLoadMonitoring } from 'store/actions/mapActions';
import ShowDataListAccordion from './ShowDataListAccordion';
import ShowDataOnlyList from './ShowDataOnlyList';

import * as S from './styled';

export default function Consult({ typeMonitoringAndMenu = '' }) {
	const dispatch = useDispatch();
	const { dataMonitoring } = useSelector((state) => state.monitoring);
	const [checkAllAlerts, setCheckAllAlerts] = useState(true);

	function putOrClearAllPointsOnMap() {
		if (checkAllAlerts) {
			const newList = dataMonitoring.map((item) => {
				item.isChecked = false;
				return item;
			});
			dispatch(editListAction(newList));

			// funções necessárias para tirar e colocar layers no mapa
			clearVector('monitoring');

			setTimeout(() => dispatch(onLoadMonitoring(newList)), 500);
		} else {
			const newList = dataMonitoring.map((item) => {
				item.isChecked = true;
				return item;
			});
			dispatch(editListAction(newList));

			// funções necessárias para tirar e colocar layers no mapa
			clearVector('monitoring');

			setTimeout(() => dispatch(onLoadMonitoring(newList)), 500);
		}
	}

	// Se todos os itens da lista for preenchido marca o check como true
	const verifyUpdate = dataMonitoring.every((elem) => elem.isChecked);

	useLayoutEffect(() => {
		if (verifyUpdate) {
			setCheckAllAlerts(true);
		}
	}, [verifyUpdate]);

	// retonar a lista ou aviso de lista vazia
	function returnListOrMessage() {
		if (dataMonitoring.length > 0) {
			return (
				<S.containerInsideAccordion
					style={
						typeMonitoringAndMenu === 'tracker' || typeMonitoringAndMenu === 'whereIBeen' ? { width: '100%' } : null
					}
				>
					{typeMonitoringAndMenu !== 'tracker' && typeMonitoringAndMenu !== 'whereIBeen' ? (
						<S.checkAll>
							<p>{checkAllAlerts ? 'Desmarcar' : 'Marcar'} todos os items do mapa</p>

							<Check
								isChecked={checkAllAlerts}
								setIsChecked={setCheckAllAlerts}
								onClick={() => {
									putOrClearAllPointsOnMap();
								}}
							/>
						</S.checkAll>
					) : null}

					{/* componente que retona opção de lista de alertas e gráficos */}
					{typeMonitoringAndMenu === 'alert' || typeMonitoringAndMenu === 'traditionalUse' ? (
						<ShowDataListAccordion typeData={typeMonitoringAndMenu} />
					) : null}

					{/* componente que retonar somente lista listrada */}
					{typeMonitoringAndMenu === 'tracker' || typeMonitoringAndMenu === 'whereIBeen' ? (
						<ShowDataOnlyList typeData={typeMonitoringAndMenu} />
					) : null}
				</S.containerInsideAccordion>
			);
		}
		// caso o usuário não tenha nenhum alerta
		return (
			<S.containerInsideAccordion
				style={typeMonitoringAndMenu === 'tracker' || typeMonitoringAndMenu === 'whereIBeen' ? { width: '100%' } : null}
			>
				<S.listEmptyNotice>Não há nenhum item para ser listado.</S.listEmptyNotice>
			</S.containerInsideAccordion>
		);
	}

	return (
		<S.containerConsult>
			<GenericAccordion
				title="CONSULTA"
				styleTitle={{ color: color.interface.darkGray, fontWeight: '900' }}
				styleLine={{
					backgroundColor: color.interface.darkGray,
					fontWeight: '900',
					height: '1px'
				}}
				isInitiOpen
			>
				{returnListOrMessage()}
			</GenericAccordion>
		</S.containerConsult>
	);
}
