import React from 'react';
import LayersGroupByList from 'components/LayersGroupByList';
import GenericAccordion from 'components/GenericAccordion';
import { color } from 'styles/Theme';
import { useSelector } from 'react-redux';
import RainLineGraph from '../../Graphs/ClimateGraphs/RainLineGraph';
import * as S from './styled';

export default function Rain({ climateList = [] }) {
	const type = 'chuva';
	const { layers } = climateList;
	const aridityList = layers.filter((item) => item.type === type);
	const { rainApi } = useSelector((state) => state.rain);
	return (
		<GenericAccordion
			title="Chuva"
			styleTitle={{
				color: `${color.interface.darkGray}`,
				fontWeight: '500'
			}}
		>
			<LayersGroupByList layers={aridityList} showTitle={false} useToggle={false} />
			{/* Para mostrar o grafico apenas quando tiver um TI selecionada */}
			{rainApi?.length > 0 && (
				<>
					<S.containerAccordionText>
						<h4>chuva</h4>
						<p>(Milímetros x Ano)</p>
					</S.containerAccordionText>
					<S.boxGraph>
						<RainLineGraph />
					</S.boxGraph>
				</>
			)}
		</GenericAccordion>
	);
}
