import React, { useState } from 'react';
import TitleMenu from 'components/TitleMenu';
import Modal from 'components/Modal';

import TitleTab from 'components/TitleTab';

export default function ItensMenuModal({ title, subTitle = '', icon = '', content = '', theme = '', isStyle = {} }) {
	const [isOpenItem, setIsOpenItem] = useState(false);

	return (
		<>
			<TitleMenu title={subTitle} icon={icon} onClick={() => setIsOpenItem(true)} theme={theme} isStyle={isStyle} />

			<Modal styleContent={{ width: '150vh' }} isOpen={isOpenItem} setIsOpen={setIsOpenItem} useBtnEnd={false}>
				<TitleTab title={title} />
				{content}
			</Modal>
		</>
	);
}
