import { allLayersMap } from 'store/typesActions/types';

const initialState = {
	allLayers: [],
	message: '',
	isReq: false,
	isErrorReq: false,
	activeToggleTis: true
};

export default function allLayersMapReducer(state = initialState, action) {
	switch (action.type) {
		case allLayersMap.REQ_All_LAYERS_MAP:
			return {
				...state,
				isReq: true,
				isErrorReq: false
			};

		case allLayersMap.SUCCESS_All_LAYERS_MAP:
			return {
				...state,
				isReq: false,
				message: 'Lista de links baixada com sucesso.',
				isErrorReq: false,
				allLayers: action.payload
			};

		case allLayersMap.FAIL_GET_All_LAYERS_MAP:
			return {
				...state,
				allLayers: [],
				message: 'Houve algum problema para carregar as camadas do mapa. Por favor, atualize a página.',
				isReq: false,
				isErrorReq: true
			};

		case allLayersMap.TOGGLE_TIS:
			return {
				...state,
				activeToggleTis: !state.activeToggleTis
			};

		default:
			return state;
	}
}
