import DonutGraph from 'components/Graphs/DonutGraph/DonutGraph';
import React from 'react';
import PropTypes from 'prop-types';
import Load from 'components/Load/Load';
import { color } from 'styles/Theme';
import { formatNumber } from 'helpers/utils';
import * as S from './styled';

export default function ThreatsDonoutGraph({ isLoading, error, labels, data, title, source }) {
	const { green: color01, orange: color02 } = color.interface;

	return (
		<S.graphContainer>
			<b>{title}</b>

			{error && <S.notice>Erro, tente novamente mais tarde</S.notice>}

			{isLoading && <Load />}

			{!error && !isLoading && (
				<>
					<S.content>
						<p style={{ color: color01 }}>
							{formatNumber(data[0], 1, '.', ',')} <span>ha</span>
						</p>
						<span>{labels[0]}</span>
						<p style={{ color: color02 }}>
							{formatNumber(data[1], 1, '.', ',')} <span>ha</span>
						</p>
						<span>{labels[1]}</span>
					</S.content>
					<S.graph>
						<DonutGraph
							labels={labels}
							// backgroundColor={[`${color01}33`, `${color02}33`]}
							// borderColor={[color01, color02]}
							dataSets={data}
						/>
						<p>Fonte: {source}</p>
					</S.graph>
				</>
			)}
		</S.graphContainer>
	);
}

ThreatsDonoutGraph.propTypes = {
	isLoading: PropTypes.bool,
	error: PropTypes.bool,
	labels: PropTypes.arrayOf(PropTypes.string),
	data: PropTypes.arrayOf(PropTypes.number),
	title: PropTypes.string,
	source: PropTypes.string
};

ThreatsDonoutGraph.defaultProps = {
	isLoading: false,
	error: false,
	labels: [],
	data: [],
	title: '',
	source: 'PRODES'
};
