/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useState } from 'react';
import { generateSolidStyle, updateGradientActivePercent } from 'components/ipam-react-color/utils';
import { useMouseEvents } from 'components/ipam-react-color/hooks';
import { formatClassName } from '../../utils/format';

export function GradientPointer({
	point,
	top = 0.5,
	activePointIndex,
	index,
	width,
	positions,
	changeActivePointIndex,
	updateGradientLeft,
	removePoint,
	className,
	color
}) {
	const activeClassName = activePointIndex === index ? ' active' : '';

	const [style, setStyle] = useState({});

	useEffect(() => {
		const style = generateSolidStyle(point.red, point.green, point.blue, point.alpha);

		setStyle({ backgroundColor: style });
	}, [point]);

	const nodeClassName = formatClassName([className, activeClassName]);

	const pointStyle = {
		top: `${top * 100}%`,
		left: `${point.left * (width / 100) /* - 6 */}px`
	};

	const mouseDownHandler = useCallback(
		(event) => {
			changeActivePointIndex(index);

			const startX = event.pageX;
			const startY = event.pageY;
			const offsetX = startX - positions.x;

			updateGradientLeft(point.left, index, 'onStartChange');

			return {
				startX,
				startY,
				offsetX
			};
		},
		[point.left, index, positions, changeActivePointIndex, updateGradientLeft]
	);

	const changeObjectPositions = useCallback(
		(event, { startX, offsetX }) => {
			const moveX = event.pageX - startX;
			offsetX += moveX;
			// update point percent
			const left = updateGradientActivePercent(offsetX, width);

			return {
				positions: {
					offsetX,
					startX: event.pageX
				},
				left
			};
		},
		[width]
	);

	const mouseMoveHandler = useCallback(
		(event, { startX, offsetX }) => {
			const { positions, left } = changeObjectPositions(event, {
				startX,
				offsetX
			});

			updateGradientLeft(left, index, 'onChange');

			return positions;
		},
		[index, changeObjectPositions, updateGradientLeft]
	);

	const mouseUpHandler = useCallback(
		(event, { startX, offsetX }) => {
			const { positions, left } = changeObjectPositions(event, {
				startX,
				offsetX
			});

			updateGradientLeft(left, index, 'onEndChange');

			return positions;
		},
		[index, changeObjectPositions, updateGradientLeft]
	);

	const mouseEvents = useMouseEvents(mouseDownHandler, mouseMoveHandler, mouseUpHandler);

	const onMouseDown = (event) => {
		changeActivePointIndex(index);
		mouseEvents(event);
	};

	const pointerClickHandler = (event) => {
		event.stopPropagation();
	};
	/* to right, */
	return (
		<div
			className={nodeClassName}
			onClick={pointerClickHandler}
			style={pointStyle}
			onMouseDown={onMouseDown}
			onDoubleClick={() => removePoint(index)}
		>
			<div className="somai-react-colorful__pointer-fill" style={style}>
				<span className={`child-point${activeClassName}`} />
			</div>
		</div>
	);
}
