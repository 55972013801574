import styled from 'styled-components';
import { color } from 'styles/Theme';

export const Grid = styled.div`
	word-break: break-all;
`;

export const Row = styled.div`
	display: flow-root;
	margin-bottom: 7px;
`;

const media = {
	xs: (styles) => `
      @media only screen and (max-width: 480px) {
        ${styles}
      }
    `
};

export const Col = styled.div`
	flex: ${(props) => props.size};

	${(props) =>
		props.collapse &&
		media[props.collapse](`
    display: none;
  `)}
`;

export const Title = styled.h6`
	text-transform: uppercase;
	letter-spacing: 0;
	text-align: left;
	color: ${color.interface.orange};
	font-size: 0.75rem;
	margin-bottom: 3px;
	/* para ajudar a calcular a quebra de linha do P, que no caso da invasão de terra precisa ser diferente das outras */
	max-width: ${(props) => props.pWidth};
`;

export const EditedBy = styled.h5`
	color: ${color.interface.gray};
	font-size: 0.55rem;
`;

export const IconClose = styled.button`
	background: ${color.interface.orange};
	width: 24px;
	height: 24px;
	padding: 4px;
	margin: 2px;
	align-items: center;
	justify-content: center;
	display: flex;
	border-radius: 20px;
	position: absolute;
	cursor: pointer;
	right: -12px;
	top: -10px;
`;

export const IconDelete = styled.button`
	background: ${color.interface.orange};
	padding: 4px;
	margin: 2px;
	align-items: center;
	justify-content: center;
	display: flex;
	border-radius: 20px;
	position: absolute;
	cursor: pointer;
	right: -12px;
	top: 80px;
`;

export const IconEdit = styled.button`
	background: ${color.interface.orange};
	padding: 5px;
	margin: 2px;
	align-items: center;
	justify-content: center;
	display: flex;
	border-radius: 20px;
	position: absolute;
	cursor: pointer;
	right: -12px;
	top: 20px;
`;

export const IconDownload = styled.button`
	background: ${color.interface.orange};
	padding: 5px;
	margin: 2px;
	align-items: center;
	justify-content: center;
	display: flex;
	border-radius: 20px;
	position: absolute;
	cursor: pointer;
	right: -12px;
	top: ${(props) => (props.currentTab === 'tab1' ? 20 : 50)}px;
`;

export const IconDownloadImage = styled.button`
	background: ${color.interface.orange};
	padding: 10px;
	margin: 2px;
	align-items: center;
	justify-content: center;
	display: flex;
	border-radius: 20px;
	position: absolute;
	cursor: pointer;
	right: 40px;
	bottom: 100px;
`;

export const ButtonConfirm = styled.button`
	justify-content: center;
	align-items: center;
	font-size: 1rem;
	border: none;
	outline: none;
	background: ${color.interface.orange};
	color: ${color.interface.white};
	height: 40px;
	font-weight: 700;
	margin-top: 20px;
	cursor: pointer;
	border-radius: 30px;
	border: 1px solid ${color.interface.orange} !important;
	width: 150px;
`;

export const ButtonCancel = styled.button`
	justify-content: center;
	align-items: center;
	font-size: 1rem;
	border: none;
	outline: none;
	background: ${color.interface.lightBrown};
	color: ${color.interface.white};
	height: 40px;
	font-weight: 700;
	margin-top: 20px;
	cursor: pointer;
	border-radius: 30px;
	border: 1px solid ${color.interface.lightBrown} !important;
	margin-right: 20px;
	width: 150px;
`;

export const MediaContainer = styled.div`
	text-align: center;
	max-width: 100%;
	max-height: 80vh;
`;

export const ImageContainer = styled.img`
	max-width: 100%;
	max-height: 70vh;
	object-fit: contain;
	margin-bottom: 10px;
`;

export const ButtonContainer = styled.div`
	margin-top: 10px;
`;

export const NavigationButton = styled.button`
	margin: 0 10px;
	cursor: pointer;
`;

export const ImageCounter = styled.span`
	margin: 0 10px;
`;
