export const svg = {
	icon: {
		opacityIcon: 'assets/icons/svg/opacity.svg',
		soundIcon: 'assets/icons/svg/sound.svg',
		facebookGreenIcon: 'assets/icons/svg/facebookGreen.svg',
		twitterGreenIcon: 'assets/icons/svg/twitterGreen.svg',
		whatsappGreenIcon: 'assets/icons/svg/whatsappGreen.svg',
		linkGreenIcon: 'assets/icons/svg/linkGreen.svg',
		menuGreen: 'assets/icons/svg/menuGreen.svg',
		arrowOrange: 'assets/icons/svg/arrowOrange.svg',
		arrowDark: 'assets/icons/svg/arrowDark.svg',
		access: 'assets/icons/svg/access.svg',
		add: 'assets/icons/svg/add.svg',
		backwardOrange: 'assets/icons/svg/backwardOrange.svg',
		barGraph1: 'assets/icons/svg/barGraph1.svg',
		barGraph2: 'assets/icons/svg/barGraph2.svg',
		calendar: 'assets/icons/svg/calendar.svg',
		carouselDown: 'assets/icons/svg/carouselDown.svg',
		carouselUp: 'assets/icons/svg/carouselUp.svg',
		carouselUpWhite: 'assets/icons/svg/carouselUpWhite.svg',
		hideShow: 'assets/icons/svg/hidearrowright.svg',
		check: 'assets/icons/svg/check.svg',
		close: 'assets/icons/svg/close.svg',
		closeOrange: 'assets/icons/svg/closeOrange.svg',
		closeBold: 'assets/icons/svg/closeBold.svg',
		closeWhite: 'assets/icons/svg/closeWhite.svg',
		contact: 'assets/icons/svg/contact.svg',
		copy: 'assets/icons/svg/copy.svg',
		cut1: 'assets/icons/svg/cut1.svg',
		cut2: 'assets/icons/svg/cut2.svg',
		download: 'assets/icons/svg/download.svg',
		downloadDoc: 'assets/icons/svg/downloadDoc.svg',
		downloadDark: 'assets/icons/svg/downloadDark.svg',
		downloadOrange: 'assets/icons/svg/downloadOrange.svg',
		mapDownloadWhite: 'assets/icons/svg/mapDownloadWhite.svg',
		downloadGray: 'assets/icons/svg/downloadGray.svg',
		edit: 'assets/icons/svg/edit.svg',
		editWhite: 'assets/icons/svg/editWhite.svg',
		facebookOrange: 'assets/icons/svg/facebookOrange.svg',
		file: 'assets/icons/svg/file.svg',
		fileOrange: 'assets/icons/svg/fileOrange.svg',
		forwardBlack: 'assets/icons/svg/forwardBlack.svg',
		forwardOrange: 'assets/icons/svg/forwardOrange.svg',
		glossary: 'assets/icons/svg/glossary.svg',
		help: 'assets/icons/svg/help.svg',
		helpCircle: 'assets/icons/svg/helpCircle.svg',
		indigenousLand: 'assets/icons/svg/indigenousLand.svg',
		instagramOrange: 'assets/icons/svg/instagramOrange.svg',
		location: 'assets/icons/svg/location.svg',
		maximize: 'assets/icons/svg/maximize.svg',
		menuMobile: 'assets/icons/svg/menumobile.svg',
		minimize: 'assets/icons/svg/minimize.svg',
		plataform: 'assets/icons/svg/plataform.svg',
		plataformOrange: 'assets/icons/svg/plataformOrange',
		point: 'assets/icons/svg/point.svg',
		print: 'assets/icons/svg/print.svg',
		profile1: 'assets/icons/svg/profile1.svg',
		profileOrange: 'assets/icons/svg/profileOrange.svg',
		refresh: 'assets/icons/svg/refresh.svg',
		rollScreem: 'assets/icons/svg/rollScreem.svg',
		rollScreemOrange: 'assets/icons/svg/rollScreemOrange.svg',
		search1: 'assets/icons/svg/search1.svg',
		search2: 'assets/icons/svg/search2.svg',
		searchBold: 'assets/icons/svg/searchBold.svg',
		share: 'assets/icons/svg/share.svg',
		shareOrange: 'assets/icons/svg/shareOrange.svg',
		substract: 'assets/icons/svg/substract.svg',
		twitterOrange: 'assets/icons/svg/twitterOrange.svg',
		upBold: 'assets/icons/svg/upBold.svg',
		user: 'assets/icons/svg/user.svg',
		userEnter: 'assets/icons/svg/userEnter.svg',
		visualize: 'assets/icons/svg/visualize.svg',
		volume: 'assets/icons/svg/volume.svg',
		volumeOrange: 'assets/icons/svg/volumeOrange.svg',
		warning: 'assets/icons/svg/warning.svg',
		warningBlack: 'assets/icons/svg/warningBlack.svg',
		warningOrange: 'assets/icons/svg/warningOrange.svg',
		users: 'assets/icons/svg/users.svg',
		usersOrange: 'assets/icons/svg/usersOrange.svg',
		userBlack: 'assets/icons/svg/userBlack.svg',
		target: 'assets/icons/svg/target.svg',
		setLocation: 'assets/icons/svg/setLocation.svg',
		route: 'assets/icons/svg/route.svg',
		routeOrange: 'assets/icons/svg/routeOrange.svg',
		retract: 'assets/icons/svg/retract.svg',
		maximizeOrange: 'assets/icons/svg/maximizeOrange.svg',
		mapLocation: 'assets/icons/svg/mapLocation.svg',
		mapLocationOrange: 'assets/icons/svg/mapLocationOrange.svg',
		locationRound: 'assets/icons/svg/locationRound.svg',
		locationRoundOrange: 'assets/icons/svg/locationRoundOrange.svg',
		menuWhite: 'assets/icons/svg/menuWhite.svg',
		xis: 'assets/icons/svg/x.svg',
		padlock: '/assets/icons/svg/padlock.svg',
		userOrange: 'assets/icons/svg/userOrange.svg',
		upOrange: 'assets/icons/svg/upOrange.svg',
		aldeiaOrange: 'assets/icons/svg/ic_aldeia.svg',
		alertOrange: 'assets/icons/svg/ic_alert.svg',
		cacaOrange: 'assets/icons/svg/ic_caca.svg',
		cacaIlegalOrange: 'assets/icons/svg/ic_caca_ilegal.svg',
		coletaOrange: 'assets/icons/svg/ic_coleta.svg',
		desmatamentoIlegalOrange: 'assets/icons/svg/ic_desmatamento_ilegal.svg',
		exploracaoIlegalOrange: 'assets/icons/svg/ic_exploração_ilegal.svg',
		fogoOrange: 'assets/icons/svg/ic_fogo_ilegal.svg',
		garimpoIlegalOrange: 'assets/icons/svg/ic_garimpo_ilegal.svg',
		invasaoTiOrange: 'assets/icons/svg/ic_invasaoti_ilegal.svg',
		outrosOrange: 'assets/icons/svg/ic_outros.svg',
		pescaOrange: 'assets/icons/svg/ic_pesca.svg',
		pescaIlegalOrange: 'assets/icons/svg/ic_pesca_ilegal.svg',
		rocaOrange: 'assets/icons/svg/ic_roca.svg',
		acceptAlert: 'assets/icons/svg/acceptAlert.png',
		deniedAlert: 'assets/icons/svg/deniedAlert.png',
		waitingAlert: 'assets/icons/svg/waitingAlert.png',
		exclamationCircleOrange: 'assets/icons/svg/exclamationCircleOrange.svg',
		expandOrange: 'assets/icons/svg/expandOrange.svg',
		toRecallOrange: 'assets/icons/svg/toRecallOrange.svg',
		eventosClimaticos: 'assets/icons/svg/eventosClimaticos.svg',
		garimpo: 'assets/icons/svg/garimpo.svg',
		povosIsolados: 'assets/icons/svg/povosIsolados.svg',
		aciLogo: 'assets/icons/svg/aciLogo.svg',
		zoomInOrange: 'assets/icons/svg/zoomInOrange.svg',
		zoomOutOrange: 'assets/icons/svg/zoomOutOrange.svg',
		camadaLayer: 'assets/icons/svg/camadaLayer.svg',
		position: 'assets/icons/svg/position.svg',
		expandir: 'assets/icons/svg/expandir.svg',
		recolher: 'assets/icons/svg/recolher.svg',
		fechar: 'assets/icons/svg/fechar.svg',
		legend: 'assets/icons/svg/legend.svg'
	},
	img: {
		logoSomai: '/assets/imgs/logoSomai.svg',
		logoSomaiLight: '/assets/imgs/logoSomaiLight.svg',
		logoSomaiMultiColor: '/assets/imgs/logoSomaiMultiColor.svg',
		iconeTeste: '/assets/imgs/icone_teste.png',
		exempleImage: '/assets/imgs/exemple_image.png'
	}
};

export const png = {
	aldeiaOrange: 'assets/icons/png/ic_aldeia.png',
	alertOrange: 'assets/icons/png/ic_alert.png',
	cacaOrange: 'assets/icons/png/ic_caca.png',
	cacaIlegalOrange: 'assets/icons/png/ic_caca_ilegal.png',
	coletaOrange: 'assets/icons/png/ic_coleta.png',
	desmatamentoIlegalOrange: 'assets/icons/png/ic_desmatamento_ilegal.png',
	exploracaoIlegalOrange: 'assets/icons/png/ic_exploração_ilegal.png',
	fogoOrange: 'assets/icons/png/ic_fogo_ilegal.png',
	garimpoIlegalOrange: 'assets/icons/png/ic_garimpo_ilegal.png',
	eventosClimaticos: 'assets/icons/png/eventosClimaticos.png',
	garimpo: 'assets/icons/png/garimpo.png',
	povosIsolados: 'assets/icons/png/povosIsolados.png',
	invasaoTiOrange: 'assets/icons/png/ic_invasaoti_ilegal.png',
	outrosOrange: 'assets/icons/png/ic_outros.png',
	pescaOrange: 'assets/icons/png/ic_pesca.png',
	pescaIlegalOrange: 'assets/icons/png/ic_pesca_ilegal.png',
	rocaOrange: 'assets/icons/png/ic_roca.png',
	locationRound: 'assets/icons/png/locationRound.png',
	route: 'assets/icons/png/route.png',
	focoCalor: 'assets/icons/png/ic_focos_calor.png',
	focoDesmatamento: 'assets/icons/png/ic_desmatamento.png'
};
