/* eslint-disable no-unused-expressions */
/* eslint-disable no-unused-vars */
import React, { useRef } from 'react';

import { formatClassName } from '../../utils/format';
import GradientPoints from './GradientPoints';

import HueGrandient from './GradientPoints/GradientPoint/HueGrandient';
import AlphaGradient from './GradientPoints/GradientPoint/AlphaGradient';
import Picking from './GradientPoints/GradientPoint/Picking';

export function AlphaGrandientColorPicker({
	className,
	red,
	green,
	blue,
	alpha,
	hue,
	saturation,
	value,
	isGradient,
	type,
	degree,
	points,
	activePointIndex,
	updateRgb,
	changeActivePointIndex,
	updateGradientLeft,
	addPoint,
	removePoint,
	hsva
}) {
	const nodeRef = useRef(null);

	const nodeClassName = formatClassName(['somai-react-colorful', className]);

	return (
		<div /* ref={nodeRef} */ className={nodeClassName}>
			<Picking
				red={red}
				green={green}
				blue={blue}
				hue={hue}
				saturation={saturation}
				value={value}
				updateRgb={updateRgb}
			/>
			<GradientPoints
				type={type}
				degree={degree}
				points={points}
				activePointIndex={activePointIndex}
				changeActivePointIndex={changeActivePointIndex}
				updateGradientLeft={updateGradientLeft}
				addPoint={addPoint}
				removePoint={removePoint}
				hsva={hsva}
			/>
			<div className="preview">
				<div className="color-hue-alpha">
					<HueGrandient hue={hue} saturation={saturation} value={value} updateRgb={updateRgb} />
					<AlphaGradient alpha={alpha} red={red} green={green} blue={blue} updateRgb={updateRgb} />
				</div>
			</div>
		</div>
	);
}
