import * as React from 'react';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

export default function ActionAlerts({ severity = 'info', message = '', closeAction }) {
	return message ? (
		<Stack
			sx={{
				minWidth: 300,
				position: 'absolute',
				right: 10,
				top: 10,
				zIndex: 999
			}}
			spacing={2}
		>
			<Alert severity={severity} onClose={() => closeAction()}>
				{message}
			</Alert>
		</Stack>
	) : null;
}
