import React from 'react';
import NavAccordion from 'components/NavAccordion';
import { useSelector } from 'react-redux';
import { getUserLocalStorage } from 'services/session';
import { sortAndFilterList } from 'helpers/utils';
import MultiFilter from 'components/MultiFilter';
import FilterDate from './FilterDate';
import * as S from './styled';

function Filters() {
	const { systemUsers, filterSearchUser, filterLetterUser, filterOrganizationUser, filterProfileUser, filterPeriod } =
		useSelector((state) => state.webGis);

	const session = getUserLocalStorage();
	const roleManager = session?.user?.user_role?.role || '';
	return (
		<NavAccordion title="FILTROS">
			<S.Container>
				{systemUsers?.length > 1 && roleManager !== 'admin' && (
					<>
						<S.Text>VER DADOS DE</S.Text>
						<MultiFilter
							systemUsers={sortAndFilterList(
								systemUsers,
								filterLetterUser,
								filterSearchUser,
								filterProfileUser,
								filterOrganizationUser,
								filterPeriod
							)}
						/>
						<S.line />
					</>
				)}
				<S.Text>PERÍODO</S.Text>
				<FilterDate />
				<S.line />{' '}
			</S.Container>
		</NavAccordion>
	);
}

export default Filters;
