import styled from 'styled-components';
import { color } from 'styles/Theme';

export const container = styled.div`
	margin-top: ${({ props }) => (props?.marginTop ? props.marginTop : '1rem')};
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 1rem;
	padding-left: ${({ props }) => (props?.paddingLeft ? props.paddingLeft : '1rem')};
	padding-right: ${({ props }) => (props?.paddingRight ? props.paddingRight : '1rem')};
`;

export const Box = styled.div`
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 100;
	background-color: ${color.interface.white};
	padding: 1rem;
	padding-top: 2rem;
	padding-bottom: 10rem;
`;
