import { deforestationType } from 'store/typesActions/types';

const initialState = {
	data: {},
	isLoading: false,
	error: false,
	message: ''
};

export default function deforestationReducer(state = initialState, action) {
	const { errors } = action?.payload || { erros: {} };

	switch (action.type) {
		case deforestationType.DEFORESTATION_REC:
			return {
				...state,
				isLoading: true,
				error: false,
				message: 'Buscando dados do desmatamento'
			};

		case deforestationType.DEFORESTATION_REC_SUCCESS:
			return {
				data: action.payload,
				isLoading: false,
				error: false,
				message: ''
			};

		case deforestationType.DEFORESTATION_REC:
			return {
				...state,
				data: [],
				isReq: false,
				error: true,
				message: 'Erro ao buscar por dados, tente novamente mais tarde'
			};
		default:
			return state;
	}
}
