import React, { useEffect, useState } from 'react';
import { actionsUsers } from 'helpers/utils';
import { useDispatch, useSelector } from 'react-redux';
import { toggleLayersTabTi, toggleTisJson } from 'store/actions/layersAction/toggleLayersTabTi/toggleLayersTabTi';
import { setThreatSelected } from 'store/actions/threatsAction';
import * as S from './styled';
import { color } from '../../../styles/Theme';
import Check from '../Check';

// Passe o estado e a função para alterar checado e não checado
// useToggle é para decidir se o comp de carregar layer vai com toggle ou checkbox
export default function SwitchToggleLayers({ itemLayer, setIsActiveted = () => {}, useToggle = true }) {
	const idInput = Math.random() * 10;
	const dispatch = useDispatch();
	const { activated, tisJson, name: itemName, key: itemkey } = itemLayer;

	// const isLoadMap = useSelector((state) => state.isLoadMap);
	const { checkRadios } = useSelector((state) => state.threatsTis);
	const { clickWithoutButton, activatedLayers, loadingLayerError, nameLayer } = useSelector((state) => state.map);

	const [isChecked, setIsChecked] = useState(activated);

	// Desabilita todas as layers ao trocar de tab
	useEffect(
		() => () => {
			dispatch(toggleLayersTabTi(false, itemLayer));
		},
		[]
	);

	// Seta false no item atual caso der erro
	useEffect(() => {
		if (loadingLayerError && nameLayer === itemLayer.name_mapfile) {
			setIsChecked(false);
		}
	}, [loadingLayerError]);

	// Apaga todas as camadas ativas se a lista das camadas ativas no reducer ficar vazia
	useEffect(() => {
		if (activatedLayers?.length === 0 && clickWithoutButton) {
			setIsChecked(false);
			setIsActiveted(false);
			dispatch(toggleLayersTabTi(false, itemLayer));
		}
	}, [clickWithoutButton]);

	const status = !isChecked ? 'Ativou' : 'desativou';
	const handleGA = () => {
		actionsUsers({
			category: 'plataforma/tabTis/camadas',
			action: `${status} a Visualização de camada`,
			label: itemName
		});
	};

	const check = (itemkeySel) => {
		if (tisJson && tisJson === true) {
			Object.keys(checkRadios).forEach((key) => {
				if (key !== itemkeySel) checkRadios[key] = false;
			});

			checkRadios[itemkeySel] = !checkRadios[itemkeySel];

			dispatch(setThreatSelected(checkRadios));
			dispatch(toggleTisJson(checkRadios, itemLayer));
		} else {
			setIsChecked(!isChecked);
			dispatch(toggleLayersTabTi(!isChecked, itemLayer));
		}
	};

	return (
		<S.containerSwitch useToggle={useToggle}>
			{useToggle ? (
				<S.labelFather
					style={isChecked ? { backgroundColor: color.interface.green } : null}
					htmlFor={idInput}
					onChange={() => {
						setIsActiveted(!isChecked);
						check(itemkey);
						handleGA();
					}}
				>
					<input type="checkbox" id={idInput} defaultChecked={isChecked} />
					<span style={isChecked ? { right: '0%' } : { left: '0%' }} />
				</S.labelFather>
			) : (
				<button
					type="submit"
					onClick={() => {
						setIsActiveted(!isChecked);
						check(itemkey);
						handleGA();
					}}
				>
					<Check
						isChecked={tisJson && tisJson === true ? checkRadios[itemkey] : isChecked}
						widthCheck="0.9rem"
						inputType={tisJson && tisJson === true ? 'radio' : 'checkbox'}
					/>
				</button>
			)}
		</S.containerSwitch>
	);
}
