import styled from 'styled-components';
import { CgClose } from 'react-icons/cg';
import { color } from 'styles/Theme';

export const ModalWrapper = styled.div`
	position: relative;
`;

export const ModalContent = styled.div`
	padding: 10px 0;
`;

export const Title = styled.h1`
	margin-top: 0;
	font-size: 1.6rem;
	word-break: keep-all;
	font-weight: 700;
	color: #291f17;
`;

export const CloseModalButton = styled(CgClose)`
	cursor: pointer;
	position: absolute;
	top: -10px;
	right: -18px;
	width: 38px;
	height: 38px;
	padding: 6px;
	z-index: 10;
	background-color: #d44500;
	border-radius: 50%;
	color: #fff;
	@media (max-width: 450px) {
		& {
			right: -5px;
			width: 30px;
			height: 30px;
		}
	}
`;

export const ModalFooter = styled.div`
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: center;
	margin-top: 15px;
`;

export const itemForm = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 1rem;
	/* background-color: aqua; */
`;

export const ButtonControl = styled.button`
	display: flex;
	justify-content: center;
	align-items: center;
	width: fit-content;
	width: 100%;
	height: 100%;
	background: none;
	cursor: pointer;
	:hover {
		text-decoration: none;
		background-color: #e6dcd3;
		height: 85%;
		border-radius: 4px;
	}
	&.active {
		height: 85%;
		background-color: #e6dcd3;
		border-radius: 4px;
	}
`;

export const errorInputEmpity = styled.p`
	font-size: 0.9rem;
	color: ${color.interface.orange};
`;
