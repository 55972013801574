import React from 'react';
import { svg } from 'lib/svgList';
import { useSelector } from 'react-redux';
import { formatNumber } from 'helpers/utils';

import * as S from './styled';

export default function TerritoryDataTableTis({ data = {}, styleTiName = '' }) {
	const tiSelectedRedux = useSelector((state) => state.tiSelectedTabTi.tiSelected);
	const defaultValue = 'Sem informação';

	const { name, etnia_nome: etnia, fase_ti: faseTi, populacao, uf_sigla: uf, isolados, area_ha: areaHa } = data;

	const areaHaFormatted = areaHa ? formatNumber(areaHa, 1, '.', ',') : null;

	return (
		<S.containerTotal>
			{'label' in tiSelectedRedux ? (
				<>
					<S.containerTitle>
						<p style={styleTiName !== '' ? styleTiName : null}>{name || defaultValue}</p>
					</S.containerTitle>
					<S.containerList>
						<ul>
							<li>
								<img src={svg.icon.cut1} alt="cut" />
								Área Total: {areaHaFormatted || defaultValue} ha
							</li>

							<li>
								<img src={svg.icon.barGraph2} alt="graph" />
								População Indígena: {populacao?.toLocaleString('pt-BR') || defaultValue}
							</li>
							<li>
								<img src={svg.icon.profile1} alt="people" />
								Povo(s): {etnia || defaultValue}
							</li>
							<li>
								<img src={svg.icon.location} alt="location" />
								Presença de povos isolados: {isolados ? 'Sim' : 'Não' || defaultValue}
							</li>
							<li>
								<img src={svg.icon.edit} alt="pen" />
								Situação jurídica: {faseTi || defaultValue}
							</li>
							<li>
								<img src={svg.icon.target} alt="paper" />
								Estado(s): {uf || defaultValue}
							</li>
						</ul>
					</S.containerList>
				</>
			) : (
				<S.notice>Selecione uma terra indígena para visualizar dados do território.</S.notice>
			)}
		</S.containerTotal>
	);
}
