import React, { useEffect, useRef, useState } from 'react';
import { color } from 'styles/Theme';
import { svg } from 'lib/svgList';
import { AiOutlineFileText } from 'react-icons/ai';
import Button from 'components/Buttons/Button/Button';
import { useDispatch, useSelector } from 'react-redux';
import { addRefRouteAction } from 'store/actions/isRouteRefActions';
import { addRefTabAction } from 'store/actions/isTabRefActions';
import { accept, noAccept } from 'store/actions/termsOfUserAcceptAction';
import { terms } from 'helpers/termsOfUse';
import { toggleOpenOrCloseNavBar } from 'store/actions/isNavBarOpenAction';
import * as S from './styled';

// passe função que será executada assim que o termo foi aceito

export default function TermsOfUse({ acceptTerm = () => {} }) {
	const termsOfUseIsAccept = useSelector((state) => state.termsOfUseIsAccept);
	const dispatch = useDispatch();
	const termDiv = useRef();
	const [isRespondedTerms, setIsRespondedTerms] = useState(false);

	// função pra ejetar na div html ao inves de string
	function ejectHtmlText() {
		termDiv.current.innerHTML = terms.text;
	}

	// função ejata html sendo chamada
	useEffect(() => {
		if (!isRespondedTerms) {
			ejectHtmlText();
		}
	}, [isRespondedTerms]);

	return (
		<S.containerTermsOfUse>
			<S.titleOrange>
				<S.centralizeImg>
					<AiOutlineFileText color={color.interface.orange} />
				</S.centralizeImg>
				<S.groupTitleLasUpdate>
					<h3>{terms.title}</h3>
					<S.lastUpdate>
						<p>Última atualização em {terms.lastUpdate}</p>
					</S.lastUpdate>
				</S.groupTitleLasUpdate>
			</S.titleOrange>

			{isRespondedTerms ? <S.line /> : null}

			{!isRespondedTerms && (
				<>
					<S.termsOfService ref={termDiv} />
					<Button
						text="Aceitar"
						style={{
							borderRadius: '20px',
							marginTop: '1rem'
						}}
						onClick={() => {
							dispatch(accept());
							acceptTerm();
						}}
					/>
					<Button
						text="Recusar"
						style={{
							borderRadius: '20px',
							marginBottom: '2rem',
							backgroundColor: `${color.interface.white}`,
							color: `${color.interface.orange}`,
							border: `1px solid ${color.interface.orange}`,
							marginTop: '1rem',
							transition: 'all ease 0.5s'
						}}
						onClick={() => {
							dispatch(noAccept());
							setIsRespondedTerms(true);
						}}
					/>
				</>
			)}

			{!termsOfUseIsAccept && isRespondedTerms && (
				<>
					<S.titleRecuseTerms>VOCÊ RECUSOU OS TERMOS DE USO</S.titleRecuseTerms>
					<S.subTitleRecuseTerms>É necessário aceitar o termo para cadastrar-se.</S.subTitleRecuseTerms>

					<S.backtoTheTerm type="button" onClick={() => setIsRespondedTerms(false)}>
						<img src={svg.icon.upOrange} alt="seta" />
						<p> VOLTAR AO TERMO</p>
					</S.backtoTheTerm>
					<S.backtoTheMap
						type="button"
						onClick={() => {
							dispatch(addRefTabAction('tab1'));
							dispatch(toggleOpenOrCloseNavBar({ status: true }));
							dispatch(addRefRouteAction('login'));
						}}
					>
						<img src={svg.icon.upOrange} alt="seta" />
						<p>VER MAPA</p>
					</S.backtoTheMap>
				</>
			)}
		</S.containerTermsOfUse>
	);
}
