import React, { useEffect, useState } from 'react';
import { getUserLocalStorage } from 'services/session';
import { filterUsers } from 'helpers/utils';
import { useSelector } from 'react-redux';

import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

export default function Management() {
	const [session, setSession] = useState({});
	const roleManager = session?.user?.user_role?.role || '';
	const roleManagerID = session?.user?.user_role?.id || '';

	useEffect(() => {
		setSession(getUserLocalStorage());
	}, []);
	const { listUsers } = useSelector((state) => state.user);

	const listFilter = filterUsers(roleManager, listUsers);

	const [open, setOpen] = React.useState(true);

	const handleClick = () => {
		setOpen(true);
	};

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setOpen(false);
	};

	useEffect(() => {
		handleClick();
	}, []);

	const inAjust = listFilter.filter((e) => e.situation_id !== 1).length;

	if (roleManagerID !== 1 && roleManagerID !== 2 && roleManagerID !== 6) return null;

	return inAjust > 0 ? (
		<Snackbar
			open={open}
			autoHideDuration={6000}
			anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
			onClose={handleClose}
		>
			<Alert onClose={handleClose} severity="warning" sx={{ width: '100%' }}>
				Lembrete: {inAjust} {inAjust === 1 ? 'usuário' : 'usuários'} aguardando a análise do perfil.
			</Alert>
		</Snackbar>
	) : null;
}
