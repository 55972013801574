import React, { useState } from 'react';
import { actionsUsers } from 'helpers/utils';
import * as S from '../ControlButton/styles';
import ListModal from './ListModal';

function BaseMapsToggleList({ mapSomai }) {
	const [showModal, setShowModal] = useState(false);

	const actionButton = !showModal ? 'abrir' : 'fechar';

	const openModal = () => {
		setShowModal((prev) => !prev);
		actionsUsers({
			category: `plataforma/mapa/webgis`,
			action: `Click no botão ${actionButton}`,
			label: 'Trocar o Mapa de Fundo'
		});
	};

	// para definir qual radio button fica checked
	const [checked, setChecked] = useState({
		baseOsmMap: false,
		baseArcGis: false,
		baseSateliteBing: false,
		googleMaps: true
	});

	const handleGA = ({ label }) => {
		actionsUsers({
			category: `plataforma/mapa`,
			action: 'Mapa base selecionado',
			label
		});
	};
	const toggleBaseLayer = (ev) => {
		const { name } = ev.target;

		// eslint-disable-next-line no-return-assign
		Object.keys(checked).forEach((key) => (checked[key] = false));
		setChecked({ [name]: true });

		if (name) {
			handleGA({ label: name });
		}

		mapSomai.getLayers().forEach((layer) => {
			if (layer.get('base')) {
				layer.setZIndex(1);
				if (layer.get('name') === name) {
					layer.setVisible(true);
				} else {
					layer.setVisible(false);
				}
			}
		});
	};

	return (
		<S.Container style={{ position: 'relative' }}>
			<button
				type="button"
				data-title="Trocar o mapa de fundo"
				aria-describedby="base_layer-popover"
				onClick={openModal}
				className={showModal ? `active sm__tooltip` : `sm__tooltip`}
			>
				<svg width="22" height="22" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M28.34 6.95493L20.34 4.05993C20.1173 3.97807 19.8727 3.97807 19.65 4.05993L12 6.92993L4.35 4.06493C4.19887 4.00847 4.03633 3.9894 3.87623 4.00937C3.71614 4.02934 3.56326 4.08774 3.43063 4.1796C3.298 4.27146 3.18957 4.39404 3.11458 4.53689C3.03959 4.67973 3.00028 4.8386 3 4.99993V23.9999C3.00036 24.2034 3.06277 24.4019 3.17891 24.569C3.29506 24.7361 3.4594 24.8637 3.65 24.9349L11.65 27.9349C11.8757 28.0193 12.1243 28.0193 12.35 27.9349L20 25.0699L27.65 27.9349C27.7615 27.9786 27.8803 28.0006 28 27.9999C28.2652 27.9999 28.5196 27.8946 28.7071 27.707C28.8946 27.5195 29 27.2651 29 26.9999V7.89493C28.9999 7.68942 28.9365 7.48892 28.8184 7.32073C28.7003 7.15253 28.5333 7.02481 28.34 6.95493ZM12.5 8.87993L19.5 6.25493V23.1199L12.5 25.7449V8.87993ZM5 6.44493L11.5 8.87993V25.7449L5 23.3049V6.44493ZM27 25.5549L20.5 23.1199V6.24493L27 8.59993V25.5549Z"
						fill="black"
					/>
				</svg>
			</button>
			<ListModal
				showModal={showModal}
				setShowModal={setShowModal}
				toggleBaseLayer={toggleBaseLayer}
				checked={checked}
			/>
		</S.Container>
	);
}

export default BaseMapsToggleList;
