import React from 'react';
import styled from 'styled-components';
import Attributions from '../..';

const Background = styled.div`
	height: 100%;
	z-index: 9999;
	display: ${(props) => (props.showModal ? 'flex' : 'none')};

	@media (max-width: 800px) {
		display: none;
	}
	justify-content: space-between;
	align-items: center;
	align-content: center;
`;

const ModalWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	align-content: center;
	padding: 1rem;
	width: 100%;
	height: 100%;
	box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
	border-radius: 5px 5px 0 0;
	background: rgba(248, 245, 243, 0.9);
	button {
		margin-left: 8px;
		width: 10px !important;
		display: flex;
		justify-content: center;
		align-items: center;
		align-content: center;
		border: 0;
		background-color: rgba(248, 245, 243, 0) !important;
	}
`;

function InfoContainer({ showModal, setShowModal }) {
	const closeModal = () => {
		setShowModal(false);
	};

	return (
		<Background showModal={showModal}>
			<ModalWrapper>
				<Attributions />
				<button type="button" onClick={closeModal}>
					<svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path
							d="M6.5 6C6.49902 6.23382 6.40546 6.4577 6.23985 6.62253L1.83049 11.0377C1.66179 11.1823 1.44478 11.2579 1.22283 11.2493C1.00089 11.2408 0.790351 11.1486 0.633295 10.9914C0.476238 10.8341 0.38423 10.6233 0.375658 10.4011C0.367085 10.1788 0.442578 9.96153 0.587052 9.7926L4.37028 6L0.587052 2.2074C0.442578 2.03847 0.367084 1.82118 0.375657 1.59895C0.38423 1.37671 0.476238 1.1659 0.633294 1.00863C0.790351 0.851372 1.00089 0.759243 1.22283 0.750659C1.44478 0.742075 1.66179 0.817668 1.83049 0.962331L6.23985 5.37747C6.40546 5.54231 6.49902 5.76619 6.5 6Z"
							fill="#69625D"
						/>
					</svg>
				</button>
			</ModalWrapper>
		</Background>
	);
}

export default InfoContainer;
