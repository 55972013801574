import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
// import { StyleLayers } from 'components/Map/Styles/js/StyleLayers';
import { olMap } from 'components/Map/ControlMap/index';
import { getLayerByName, clearVector, setActivatedLayers, removeLayerFromMap } from 'store/actions/mapActions';
import { transform } from 'ol/proj';
import Feature from 'ol/Feature';
// import { png } from 'lib/svgList';
import { Point } from 'ol/geom';
import { name } from 'components/Map/Tooltips/TooltipGlobal/ContentTabTis/nameMapFile';

// objConfig : nameVector, label, zIndex, icon, style, onVisiable
// onVisiable: Se vc quer ou não mostrar os dados no mapa, controle-o com algum toggle ou algo que retorne true ou false
// data: são os dados de lat e long q precisam ter as chaves lng e lat

export const addVectorPoint = (data, objConfig) => (dispatch) => {
	const { nameVector, label, zIndex, icon, style, onVisiable } = objConfig;

	if (getLayerByName(nameVector) === null) {
		clearVector(nameVector);
		const sourceMonitoring = new VectorSource({ wrapX: false });

		const vectorMonitoring = new VectorLayer({
			name: nameVector,
			source: sourceMonitoring,
			monitoring: false,
			label: label || 'Passe a label',
			zIndex: zIndex || 1,
			style,
			icon,
			haveTitle: false
		});
		olMap().addLayer(vectorMonitoring);
	}

	if (!onVisiable) {
		removeLayerFromMap(nameVector, false);
		dispatch(setActivatedLayers());
	} else {
		addFeaturesOnPoint(data, objConfig);
		dispatch(setActivatedLayers());
	}
};

export const addFeaturesOnPoint = (data, objConfig) => {
	const { nameVector } = objConfig;
	if (getLayerByName(nameVector) !== null && data.length > 0) {
		const layer = getLayerByName(nameVector);
		const source = layer.getSource();
		source.addFeatures(createFeaturePoint(data));
	}
};

export const createFeaturePoint = (array) => {
	const features = [];
	array.forEach((item) => {
		const { lat, lng } = item;
		item.nameMapFile = name.threatAndAlertsNasaAndInpe;
		const point = transform([lng, lat], 'EPSG:4326', 'EPSG:3857');
		const feature = [
			new Feature({
				geometry: new Point(point),
				props: item
			})
		];

		features.push(...feature);
	});
	return features;
};
