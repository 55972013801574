/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */

import React, { useState } from 'react';
import InfoContainer from './InfoContainer';
import * as S from './style';

function AttributionWebContainer() {
	const [showModal, setShowModal] = useState(false);

	const openModal = () => {
		setShowModal((prev) => !prev);
	};

	return (
		<S.Container>
			<button type="button" className={showModal ? `show-atrib active ` : 'show-atrib'} onClick={openModal}>
				<svg width="15" height="15" viewBox="0 0 3 10" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M2 3.125H0.75C0.58424 3.125 0.425269 3.19085 0.308058 3.30806C0.190848 3.42527 0.125 3.58424 0.125 3.75C0.125 3.91576 0.190848 4.07473 0.308058 4.19194C0.425269 4.30915 0.58424 4.375 0.75 4.375H1.375V8.75C1.375 8.91576 1.44085 9.07473 1.55806 9.19194C1.67527 9.30915 1.83424 9.375 2 9.375C2.16576 9.375 2.32473 9.30915 2.44194 9.19194C2.55915 9.07473 2.625 8.91576 2.625 8.75V3.75C2.625 3.58424 2.55915 3.42527 2.44194 3.30806C2.32473 3.19085 2.16576 3.125 2 3.125Z"
						fill="#69625D"
					/>
					<path
						d="M1.6875 1.875C2.20527 1.875 2.625 1.45527 2.625 0.9375C2.625 0.419733 2.20527 0 1.6875 0C1.16973 0 0.75 0.419733 0.75 0.9375C0.75 1.45527 1.16973 1.875 1.6875 1.875Z"
						fill="#69625D"
					/>
				</svg>
			</button>
			<InfoContainer showModal={showModal} setShowModal={setShowModal} />
		</S.Container>
	);
}

export default AttributionWebContainer;
