/* eslint-disable no-nested-ternary */
/* eslint-disable react/button-has-type */
import { CardMedia } from '@mui/material';
import React from 'react';

function DragDropFile({
	handleChange = () => {},
	handleDragChange = () => {},
	accept = '',
	isMultiple = false,
	image,
	size
}) {
	// drag state
	const [dragActive, setDragActive] = React.useState(false);
	// ref
	const inputRef = React.useRef(null);

	// handle drag events
	const handleDrag = (e) => {
		e.preventDefault();
		e.stopPropagation();
		if (e.type === 'dragenter' || e.type === 'dragover') {
			setDragActive(true);
		} else if (e.type === 'dragleave') {
			setDragActive(false);
		}
	};

	// triggers when file is dropped
	const handleDrop = (e) => {
		e.preventDefault();
		e.stopPropagation();
		setDragActive(false);
		if (e.dataTransfer.files && e.dataTransfer.files[0]) {
			handleDragChange(e.dataTransfer.files);
			// handleFiles(e.dataTransfer.files);
		}
	};

	// triggers the input when the button is clicked
	const onButtonClick = () => {
		inputRef.current.click();
	};

	return (
		<div id="form-file-upload" onDragEnter={handleDrag}>
			<input
				ref={inputRef}
				type="file"
				id="input-file-upload"
				multiple={isMultiple}
				onChange={handleChange}
				accept={accept}
			/>
			<label
				id="label-file-upload"
				htmlFor="input-file-upload"
				className={
					dragActive ? (size ? `input-text-${size} drag-active` : 'drag-active') : size ? `input-text-${size}` : ''
				}
			>
				{image ? (
					<CardMedia
						component="img"
						image={image}
						alt="Imagem do registro"
						sx={{
							width: '90%',
							height: '90%',
							objectFit: 'contain',
							objectPosition: 'center'
						}}
					/>
				) : (
					<div>
						<h2>Arraste os arquivos ou clique aqui para importar.</h2>
						<p>Formatos suportados: {accept}</p>
						<button className="upload-button" type="button" onClick={onButtonClick}>
							<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path
									d="M16.7343 10.3166C16.6402 10.2167 16.5265 10.137 16.4002 10.0826C16.2739 10.0281 16.1377 10 16 10C15.8623 10 15.7261 10.0281 15.5998 10.0826C15.4735 10.137 15.3598 10.2167 15.2657 10.3166L8.22459 17.818C8.06408 18.0149 7.98469 18.2649 8.00245 18.5177C8.02021 18.7705 8.1338 19.0072 8.32027 19.1799C8.50674 19.3526 8.75219 19.4485 9.00702 19.4482C9.26186 19.4479 9.50709 19.3514 9.69316 19.1783L14.9941 13.5322V26.9998C14.9941 27.2651 15.1001 27.5195 15.2887 27.7071C15.4774 27.8946 15.7332 28 16 28C16.2668 28 16.5226 27.8946 16.7113 27.7071C16.8999 27.5195 17.0059 27.2651 17.0059 26.9998V13.5322L22.3068 19.1783C22.4929 19.3514 22.7381 19.4479 22.993 19.4482C23.2478 19.4485 23.4933 19.3526 23.6797 19.1799C23.8662 19.0072 23.9798 18.7705 23.9976 18.5177C24.0153 18.2649 23.9359 18.0149 23.7754 17.818L16.7343 10.3166Z"
									fill="#006946"
								/>
								<path
									d="M27 4H5C4.47589 4.0198 3.9786 4.23687 3.60774 4.60774C3.23687 4.9786 3.0198 5.47589 3 6V11C3 11.2652 3.10536 11.5196 3.29289 11.7071C3.48043 11.8946 3.73478 12 4 12C4.26522 12 4.51957 11.8946 4.70711 11.7071C4.89464 11.5196 5 11.2652 5 11V6.04L5.04 6H26.96L27 6.04V11C27 11.2652 27.1054 11.5196 27.2929 11.7071C27.4804 11.8946 27.7348 12 28 12C28.2652 12 28.5196 11.8946 28.7071 11.7071C28.8946 11.5196 29 11.2652 29 11V6C28.9802 5.47589 28.7631 4.9786 28.3923 4.60774C28.0214 4.23687 27.5241 4.0198 27 4Z"
									fill="#006946"
								/>
							</svg>
						</button>
					</div>
				)}
			</label>
			{dragActive && (
				<div
					id="drag-file-element"
					onDragEnter={handleDrag}
					onDragLeave={handleDrag}
					onDragOver={handleDrag}
					onDrop={handleDrop}
				/>
			)}
		</div>
	);
}

export default DragDropFile;
