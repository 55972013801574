import React from 'react';

function Loading({ style, size, type }) {
	return (
		<>
			{type === 'loading' && <div className="loading" style={{ ...style, width: `${size}`, height: `${size}` }} />}
			{type === 'loading-simple' && (
				<div className="loading-simple" style={{ ...style, width: `${size}`, height: `${size}` }} />
			)}
			{type === 'circle-progress' && (
				<span className="somai-loading" style={{ ...style, width: `${size}`, height: `${size}` }}>
					<svg className="somai-loading-svg" viewBox="22 22 44 44">
						<circle className="somai-loading-circle" cx="44" cy="44" r="20.2" fill="none" strokeWidth="3.6" />
					</svg>
				</span>
			)}
			{type === 'simple-circle-progress' && (
				<span className="somai-loading-simple" style={{ ...style, width: `${size}`, height: `${size}` }}>
					<svg className="somai-loading-svg" viewBox="22 22 44 44">
						<circle className="somai-loading-circle" cx="44" cy="44" r="20.2" fill="none" strokeWidth="3.6" />
					</svg>
				</span>
			)}
			{type === 'linear-progress' && (
				<span className="somai-multi-linear-progress-loading" style={{ ...style, width: `${size}`, height: `${size}` }}>
					<span className="somai-multi-linear-progress-bar" />
				</span>
			)}
		</>
	);
}

export default Loading;
