import styled from 'styled-components';
import { color } from 'styles/Theme';

export const containerTitleMenu = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	color: ${color.interface.orange};
	font-weight: 800;
	font-size: 0.75rem;
	cursor: pointer;

	/* background-color: aquamarine; */
`;
export const containerItemAndArrow = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0.3rem;
	img {
		width: 10px;
		height: 6px;
		box-sizing: content-box;
	}

	/* background-color: azure; */
`;
export const ItemMenu = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 0.5rem;

	/* background-color: blue; */
	img {
		width: 15px;
		height: 15px;
	}

	p {
		color: ${color.interface.darkGray};
		font-family: 'Nunito';
		font-style: normal;
		font-weight: 800;
		line-height: 0.875rem;
		font-size: 0.625rem;
		letter-spacing: 0.1em;
		text-transform: uppercase;

		width: 100%;
	}
`;
export const lineMenu = styled.div`
	width: 100%;
	height: 0.5px;
	background-color: ${({ props }) => (props?.theme === 'dark' ? color.interface.lightGray : color.interface.orange)};
`;
