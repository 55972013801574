import React, { useState, useEffect } from 'react';
import { svg } from 'lib/svgList';
import { componentsViewUsers } from 'helpers/utils';
import CustomInput from 'components/CustomInput';
import { addRefRouteAction } from 'store/actions/isRouteRefActions';
import { signIn, closeErrorUser } from 'store/actions/userActions';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'components/Modal';

import RecoveryPasswordTemplate from './RecoveryPasswordTemplate/RecoveryPasswordTemplate';
import * as S from './Login.Styled';

function Login() {
	const dispatch = useDispatch();
	const { message, loading } = useSelector((state) => state.user);
	const currentTab = useSelector((state) => state.isTabRef);

	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [modalResetPassword, setModalResetPassword] = useState(false);
	const formData = { email, password };

	// estado necessário para controlar a função de resetar senha
	const [emailRecoveryPassWord, setEmailRecoveryPassWord] = useState('');

	const handleSubmit = (e) => {
		e.preventDefault();
		dispatch(signIn(formData));
	};

	const handleClose = () => {
		dispatch(closeErrorUser());
	};

	useEffect(() => {
		componentsViewUsers({
			page: `plataforma/painel/login`,
			title: 'Login'
		});
	}, []);

	return (
		<S.Container onSubmit={handleSubmit}>
			{currentTab === 'tab1' ? (
				<S.BoxLogo>
					<img src={svg.icon.aciLogo} alt="logo aci" />
				</S.BoxLogo>
			) : (
				<S.Title>PAINEL DE CONTROLE</S.Title>
			)}

			<CustomInput
				alt="email"
				src={svg.icon.userOrange}
				value={email}
				type="email"
				name="email"
				placeholder="Email"
				setValue={setEmail}
				required
			/>

			<CustomInput
				alt="password"
				src={svg.icon.padlock}
				value={password}
				type="password"
				name="password"
				placeholder="Senha"
				setValue={setPassword}
				required
			/>

			<S.ResetPassword
				onClick={() => {
					setModalResetPassword(true);
				}}
			>
				<p>Esqueceu a senha? </p>
			</S.ResetPassword>

			<Modal isOpen={modalResetPassword} setIsOpen={setModalResetPassword} useBtnEnd={false}>
				<RecoveryPasswordTemplate email={emailRecoveryPassWord} setEmail={setEmailRecoveryPassWord} />
			</Modal>

			<S.ButtonLogin type="submit" disabled={loading}>
				{loading ? 'Aguarde...' : 'Entrar'}
			</S.ButtonLogin>
			<S.ButtonRegister
				type="button"
				onClick={() => {
					dispatch(addRefRouteAction('register'));
				}}
				disabled={loading}
			>
				Cadastrar
			</S.ButtonRegister>

			<Modal isOpen={message?.length > 0} setIsOpen={handleClose} useBtnEnd={false}>
				{message}
			</Modal>
		</S.Container>
	);
}

export default Login;
