import React from 'react';
import ListAccordion from './ListAccordion';
import Item from './ListAccordion/Item';
import * as S from './styled';

export default function Consult({ typeMonitoringAndMenu = '', listDataUsers }) {
	return (
		<S.containerInsideAccordion>
			{typeMonitoringAndMenu === 'alert' || typeMonitoringAndMenu === 'traditionalUse' ? (
				<ListAccordion typeData={typeMonitoringAndMenu} listDataUsers={listDataUsers} />
			) : null}

			{typeMonitoringAndMenu === 'tracker' ? (
				<S.containerShowDataListAccordion>
					<Item typeData="tracker" alertsList={[{ title: 'Trajetos Registrados', type: 'tracker' }]} />
				</S.containerShowDataListAccordion>
			) : null}

			{typeMonitoringAndMenu === 'whereIBeen' ? (
				<S.containerShowDataListAccordion>
					<Item
						typeData="whereIBeen"
						alertsList={[
							{
								title: 'Localizações Registradas',
								type: 'where_i_been'
							}
						]}
					/>
				</S.containerShowDataListAccordion>
			) : null}
		</S.containerInsideAccordion>
	);
}
