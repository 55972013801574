/* eslint-disable no-shadow */
/* eslint-disable no-plusplus */
/* eslint-disable radix */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
// import { toStringHDMS } from 'ol/coordinate';
import { transform } from 'ol/proj';
import { getDMS } from 'helpers/utils';

const MapCenterContainer = styled.div`
	display: flex;
	align-items: center;
	color: #69625d;
	justify-content: space-between;
	.somai__coordinate-center {
		/* display: flex;
		align-items: center; */
		margin: 0 0.5rem;
		white-space: nowrap;
		font-size: 13px;
		font-weight: 400;
	}
	svg {
		width: 14px;
		height: 14px;
		color: #69625d;
	}
	button {
		cursor: pointer;
		background-color: transparent !important;
		& + button {
			margin-left: 6px;
		}
	}
`;

function CoordinateContainer({ mapSomai }) {
	const [coordinates, setCoordinates] = useState([-0.0, -0.0]);
	const [coordinatesHover, setCoordinatesHover] = useState();
	const [convert, setConvert] = useState(false);
	const [hovering, setHovering] = useState(false);

	useEffect(() => {
		const view = mapSomai?.getView();
		const sourceProjection = view?.getProjection();

		const onPointerMove = (event) => {
			setHovering(true);
			const { coordinate } = event;

			const sourceProjectionCode = sourceProjection?.getCode();
			const destinationProjection = 'EPSG:4326';
			const transformedCoordinate = transform(coordinate, sourceProjectionCode, destinationProjection);
			setCoordinatesHover(transformedCoordinate);
		};

		mapSomai?.on('pointermove', onPointerMove);

		const mapContainer = document.getElementById('mapSomai');

		mapContainer?.addEventListener('mouseout', () => {
			setHovering(false);
			setCoordinatesHover();
		});
		return () => {
			mapSomai?.un('pointermove', onPointerMove);
		};
	}, [mapSomai]);

	useEffect(() => {
		const view = mapSomai?.getView();
		const sourceProjection = view?.getProjection();

		const handleClick = (event) => {
			setHovering(false);
			setCoordinatesHover();
			const { coordinate } = event;
			const sourceProjectionCode = sourceProjection?.getCode();
			const destinationProjection = 'EPSG:4326';
			const transformedCoordinate = transform(coordinate, sourceProjectionCode, destinationProjection);
			setCoordinates(transformedCoordinate);
		};

		mapSomai?.on('click', handleClick);
		return () => {
			mapSomai?.un('click', handleClick);
		};
	}, [mapSomai]);

	const lat = coordinates && coordinates[1].toFixed(6);
	const lon = coordinates && coordinates[0].toFixed(6);

	const latDMS = getDMS(lat, 'lat');
	const lonDMS = getDMS(lon, 'long');

	const latHover = coordinatesHover && coordinatesHover[1].toFixed(6);
	const lonHover = coordinatesHover && coordinatesHover[0].toFixed(6);

	const latDMSHover = getDMS(latHover, 'lat');
	const lonDMSHover = getDMS(lonHover, 'lon');

	return (
		<MapCenterContainer className="somai__coordinate-center-container">
			<svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M0.68614 3.84088L9.64523 0.0177167C9.69233 -0.000630813 9.74374 -0.00483751 9.79318 0.00560916C9.84262 0.0160558 9.88797 0.0407048 9.9237 0.0765533C9.95943 0.112402 9.984 0.157903 9.99441 0.207512C10.0048 0.257122 10.0006 0.308698 9.98234 0.355959L6.17192 9.34502C6.1511 9.39359 6.11581 9.4345 6.07089 9.46216C6.02598 9.48981 5.97364 9.50285 5.92105 9.49948C5.86846 9.49611 5.8182 9.4765 5.77715 9.44334C5.73611 9.41018 5.70629 9.3651 5.69179 9.31427L4.67023 5.54747C4.65806 5.50481 4.63526 5.46595 4.60398 5.43457C4.5727 5.40319 4.53398 5.38031 4.49145 5.3681L0.737218 4.34312C0.677256 4.33883 0.620725 4.31346 0.577575 4.27146C0.534425 4.22947 0.507422 4.17355 0.50132 4.11354C0.495217 4.05354 0.510406 3.9933 0.544213 3.94342C0.57802 3.89355 0.628278 3.85724 0.68614 3.84088Z"
					fill="#291F17"
				/>
			</svg>
			<div className="somai__coordinate-center">
				{hovering ? (
					coordinatesHover && (
						<>
							{convert &&
								`S ${coordinatesHover && coordinatesHover[1].toFixed(6)}, W ${
									coordinatesHover && coordinatesHover[0].toFixed(6)
								}`}

							{!convert && `${latDMSHover} ${lonDMSHover}`}
						</>
					)
				) : (
					<>
						{convert && `S ${coordinates && coordinates[1].toFixed(6)}, W ${coordinates && coordinates[0].toFixed(6)}`}
						{!convert && `${latDMS} ${lonDMS}`}
					</>
				)}
			</div>

			<button
				type="button"
				onClick={() => {
					setConvert((prev) => !prev);
				}}
			>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					xmlnsXlink="http://www.w3.org/1999/xlink"
					version="1.1"
					width="256"
					height="256"
					viewBox="0 0 256 256"
					xmlSpace="preserve"
				>
					<defs />
					<g
						style={{
							stroke: 'none',
							strokeWidth: 0,
							strokeDasharray: 'none',
							strokeLinecap: 'butt',
							strokeLinejoin: 'miter',
							strokeMiterlimit: 10,
							fill: 'none',
							fillRule: 'nonzero',
							opacity: 1
						}}
						transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)"
					>
						<path
							d="M 7.533 47.791 c 0.07 0.027 0.142 0.045 0.214 0.067 c 0.132 0.04 0.264 0.074 0.403 0.096 c 0.036 0.006 0.07 0.021 0.106 0.025 C 8.377 47.993 8.496 48 8.615 48 c 0.2 0 0.398 -0.021 0.592 -0.06 c 0.118 -0.024 0.228 -0.066 0.341 -0.103 c 0.073 -0.024 0.148 -0.039 0.219 -0.068 c 0.128 -0.053 0.246 -0.124 0.364 -0.194 c 0.049 -0.029 0.103 -0.05 0.151 -0.082 c 0.164 -0.11 0.317 -0.235 0.457 -0.375 l 7.672 -7.672 c 1.172 -1.171 1.172 -3.071 0 -4.242 c -1.171 -1.172 -3.071 -1.172 -4.242 0 l -1.513 1.513 c 3.694 -14.43 16.807 -25.13 32.372 -25.13 c 12.993 0 24.908 7.625 30.354 19.427 c 0.693 1.504 2.477 2.16 3.98 1.467 c 1.505 -0.694 2.161 -2.477 1.467 -3.981 C 74.406 14.581 60.353 5.587 45.028 5.587 c -18.641 0 -34.29 13.013 -38.367 30.428 l -1.097 -1.803 c -0.862 -1.416 -2.707 -1.866 -4.123 -1.003 c -1.415 0.861 -1.865 2.707 -1.003 4.123 l 5.614 9.227 c 0.011 0.018 0.027 0.031 0.038 0.049 c 0.093 0.145 0.2 0.279 0.316 0.406 c 0.027 0.03 0.049 0.064 0.077 0.093 c 0.148 0.149 0.311 0.282 0.488 0.398 c 0.046 0.03 0.097 0.051 0.145 0.079 C 7.249 47.662 7.387 47.734 7.533 47.791 z"
							style={{
								stroke: 'none',
								strokeWidth: 1,
								strokeDasharray: 'none',
								strokeLinecap: 'butt',
								strokeLinejoin: 'miter',
								strokeMiterlimit: 10,
								fill: '#69625D',
								fillRule: 'nonzero',
								opacity: 1
							}}
							transform=" matrix(1 0 0 1 0 0) "
							strokeLinecap="round"
						/>
						<path
							d="M 89.562 52.668 l -5.614 -9.227 c -0.01 -0.017 -0.025 -0.03 -0.036 -0.047 c -0.095 -0.149 -0.205 -0.287 -0.324 -0.417 c -0.024 -0.027 -0.044 -0.058 -0.069 -0.084 c -0.148 -0.15 -0.312 -0.283 -0.489 -0.399 c -0.045 -0.03 -0.095 -0.05 -0.142 -0.077 c -0.135 -0.079 -0.274 -0.151 -0.421 -0.208 c -0.07 -0.027 -0.142 -0.044 -0.214 -0.066 c -0.132 -0.04 -0.264 -0.074 -0.403 -0.096 c -0.036 -0.006 -0.07 -0.021 -0.107 -0.025 c -0.052 -0.006 -0.103 0.003 -0.155 -0.001 C 81.52 42.016 81.455 42 81.386 42 c -0.061 0 -0.117 0.014 -0.177 0.018 c -0.093 0.005 -0.184 0.014 -0.276 0.028 c -0.128 0.019 -0.25 0.049 -0.372 0.084 c -0.08 0.023 -0.159 0.044 -0.236 0.073 c -0.135 0.051 -0.262 0.116 -0.387 0.185 c -0.059 0.033 -0.12 0.059 -0.177 0.096 c -0.18 0.116 -0.349 0.247 -0.5 0.398 l -7.672 7.672 c -1.172 1.171 -1.172 3.071 0 4.242 c 1.172 1.172 3.07 1.172 4.242 0 l 1.513 -1.513 c -3.694 14.43 -16.807 25.13 -32.372 25.13 c -13.459 0 -25.544 -8.011 -30.788 -20.408 c -0.646 -1.526 -2.405 -2.243 -3.932 -1.594 c -1.526 0.646 -2.24 2.405 -1.594 3.932 c 6.185 14.622 20.439 24.07 36.314 24.07 c 18.641 0 34.29 -13.013 38.367 -30.429 l 1.097 1.803 c 0.564 0.928 1.553 1.44 2.565 1.44 c 0.531 0 1.069 -0.141 1.557 -0.437 C 89.974 55.929 90.424 54.084 89.562 52.668 z"
							style={{
								stroke: 'none',
								strokeWidth: 1,
								strokeDasharray: 'none',
								strokeLinecap: 'butt',
								strokeLinejoin: 'miter',
								strokeMiterlimit: 10,
								fill: '#69625D',
								fillRule: 'nonzero',
								opacity: 1
							}}
							transform=" matrix(1 0 0 1 0 0) "
							strokeLinecap="round"
						/>
					</g>
				</svg>
			</button>
			<button
				type="button"
				onClick={() => {
					navigator.clipboard.writeText(
						convert
							? `S ${coordinates && coordinates[1].toFixed(6)}, W ${coordinates && coordinates[0].toFixed(6)}`
							: `${latDMS} ${lonDMS}`
					);
				}}
			>
				<svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M8 11.5C7.86739 11.5 7.74021 11.5527 7.64645 11.6464C7.55268 11.7402 7.5 11.8674 7.5 12V12.5H1.5V5H2C2.13261 5 2.25979 4.94732 2.35355 4.85355C2.44732 4.75979 2.5 4.63261 2.5 4.5C2.5 4.36739 2.44732 4.24021 2.35355 4.14645C2.25979 4.05268 2.13261 4 2 4H1.5C0.725 4 0.5 4.225 0.5 5V12.5C0.5 13.275 0.725 13.5 1.5 13.5H7.5C8.275 13.5 8.5 13.275 8.5 12.5V12C8.5 11.8674 8.44732 11.7402 8.35355 11.6464C8.25979 11.5527 8.13261 11.5 8 11.5Z"
						fill="#291F17"
					/>
					<path
						d="M10.5 0.5H4.5C3.725 0.5 3.5 0.725 3.5 1.5V9.5C3.5 10.275 3.725 10.5 4.5 10.5H10.5C11.275 10.5 11.5 10.275 11.5 9.5V1.5C11.5 0.725 11.275 0.5 10.5 0.5ZM10.5 9.5H4.5V1.5H10.5V9.5Z"
						fill="#291F17"
					/>
				</svg>
			</button>
		</MapCenterContainer>
	);
}

export default CoordinateContainer;
