import React, { useState } from 'react';
import { svg } from 'lib/svgList';
import CustomInput from 'components/CustomInput';
import Button from 'components/Buttons/Button/Button';
import CheckAnimation from 'components/Animations/CheckAnimation/CheckAnimation';
import UncheckAnimation from 'components/Animations/UncheckAnimation/UncheckAnimation';
import Load from 'components/Load/Load';

import { api } from 'services/api';
import * as S from './styled';

export default function RecoveryPassword() {
	const [password, setPassword] = useState('');
	const [password2, setPassword2] = useState('');
	const [errorForm, setErrorForm] = useState(false);
	const [messageErrorForm, setMessageErrorForm] = useState('');

	// pegando parametros vindos da url para alterar a senha
	const urlParams = window.location.pathname.split('/');
	const email = urlParams[urlParams.length - 1];
	const token = urlParams[urlParams.length - 2];
	const [isReq, setIsReq] = useState(false);
	const [errorReq, setErrorReq] = useState(false);
	const [messageReq, setMessageReq] = useState('');
	const [statusReq, setStatusReq] = useState(0);

	async function changePassword() {
		setErrorReq(false);
		setStatusReq(0);
		if (password === '' && password2 === '') {
			setErrorForm(true);
			setMessageErrorForm('Preencha todos os campos.');
		} else if (password !== password2) {
			setErrorForm(true);
			setMessageErrorForm('Digite a mesma senha nos 2 campos.');
		} else {
			setIsReq(true);
			setErrorForm(false);
			const objReq = {
				email,
				token,
				password
			};
			await api
				.post('/user/reset_password', objReq)
				.then((res) => {
					setIsReq(false);
					setErrorReq(false);
					setMessageReq(res.data.message);
					setStatusReq(res.status);
					setPassword('');
					setPassword2('');
					setTimeout(() => {
						window.location.href = process.env.REACT_APP_LANDING_PAGE;
					}, 3000);
				})
				.catch((erro) => {
					const { response: res } = erro;
					setIsReq(false);
					setErrorReq(true);
					setMessageReq(res ? res.data.message : 'Tente novamente mais tarde.');
					setStatusReq(res.status);
				});
		}
	}

	return (
		<S.containerRecoveryPassword>
			<S.logo src={svg.img.logoSomaiMultiColor} alt="Logo SOMAI" />
			<S.form>
				<CustomInput
					type="password"
					placeholder="Senha"
					value={password}
					setValue={setPassword}
					src={svg.icon.padlock}
					alt="senha"
				/>
				<CustomInput
					type="password"
					placeholder="Repita a senha "
					value={password2}
					setValue={setPassword2}
					src={svg.icon.padlock}
					alt="repita a senha"
				/>

				{isReq ? null : <Button text="Enviar" onClick={() => changePassword()} />}

				{isReq ? <Load size={40} /> : null}

				{errorForm ? <S.erroMessage>{messageErrorForm}</S.erroMessage> : null}

				{statusReq !== 200 && errorReq === true ? (
					<S.containerMessage>
						<UncheckAnimation />
						<S.erroMessage>{messageReq}</S.erroMessage>
					</S.containerMessage>
				) : null}

				{statusReq === 200 ? (
					<S.containerMessage>
						<CheckAnimation />
						<S.successMessage>{messageReq}</S.successMessage>
					</S.containerMessage>
				) : null}
			</S.form>
		</S.containerRecoveryPassword>
	);
}
