/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import styled from 'styled-components';
import InfoModal from '../Attribution/mobile/InfoModal';

const ContainerMobile = styled.div`
	justify-content: center;
	align-items: center;
	width: 35px;
	height: 35px;

	display: none;
	@media (max-width: 800px) {
		display: flex;
	}
	button {
		display: flex;
		justify-content: center;
		align-items: center;
		width: fit-content;

		cursor: pointer;
		:hover {
			text-decoration: none;
			background-color: #e6dcd3;
		}
		&.active {
			background-color: #e6dcd3;
		}
		.circle-btn {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 30px;
			height: 30px;
			border: 1px #69625d solid;
			border-radius: 50px;
		}
		svg {
			width: 15px;
			height: 15px;
		}
	}
`;

function MapInfoMobile({ mapSomai }) {
	const [showModal, setShowModal] = useState(false);
	const openModal = () => {
		setShowModal((prev) => !prev);
	};

	return (
		<ContainerMobile>
			<button
				type="button"
				className={showModal ? `map-info__show-btn active` : 'map-info__show-btn'}
				onClick={openModal}
			>
				<div className="circle-btn">
					<svg width="4" height="15" viewBox="0 0 4 15" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path
							d="M3 5H1C0.734784 5 0.48043 5.10536 0.292893 5.29289C0.105357 5.48043 0 5.73478 0 6C0 6.26522 0.105357 6.51957 0.292893 6.70711C0.48043 6.89464 0.734784 7 1 7H2V14C2 14.2652 2.10536 14.5196 2.29289 14.7071C2.48043 14.8946 2.73478 15 3 15C3.26522 15 3.51957 14.8946 3.70711 14.7071C3.89464 14.5196 4 14.2652 4 14V6C4 5.73478 3.89464 5.48043 3.70711 5.29289C3.51957 5.10536 3.26522 5 3 5Z"
							fill="#69625D"
						/>
						<path
							d="M2.5 3C3.32843 3 4 2.32843 4 1.5C4 0.671573 3.32843 0 2.5 0C1.67157 0 1 0.671573 1 1.5C1 2.32843 1.67157 3 2.5 3Z"
							fill="#69625D"
						/>
					</svg>
				</div>
			</button>
			<InfoModal showModal={showModal} mapSomai={mapSomai} setShowModal={setShowModal} />
		</ContainerMobile>
	);
}

export default MapInfoMobile;
