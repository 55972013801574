import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearOverlay } from 'components/Map/ControlMap';
import { onLoadMonitoring, activeModify, activeDraw, clearVector } from 'store/actions/mapActions';

function ControlMonitoring() {
	const dispatch = useDispatch();

	const { dataUser } = useSelector((state) => state.dataUserToShowOnMap);
	const { isEdit } = useSelector((state) => state.editDataUserOnMap);
	const { openIsRegister, routeMonitoring } = useSelector((state) => state.monitoring);

	const reduxTisList = useSelector((state) => state.listTis);

	// controla as interações do mapa
	useEffect(() => {
		if (routeMonitoring !== 'tracks') {
			dispatch(activeModify(isEdit));
		}
	}, [reduxTisList, dataUser, isEdit]);

	// Recarrega a lista no mapa na aba painel
	// controla as interações do mapa
	useEffect(() => {
		// limpa o mapa
		clearOverlay();
		// openIsRegister sinaliza o registro de um novo alerta
		// Se true ativa a função de pegar o ponto no mapa
		dispatch(activeDraw(openIsRegister));

		if (openIsRegister) {
			// limpa todos os alertas para manter apenas o novo ponto no mapa
			clearVector('monitoring');
		} else {
			// carrega a nova lista no mapa
			dispatch(onLoadMonitoring(dataUser));
		}
	}, [dataUser, openIsRegister]);

	return null;
}

export default ControlMonitoring;
