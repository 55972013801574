/* eslint-disable no-nested-ternary */
/* eslint-disable no-useless-concat */
/* eslint-disable no-shadow */
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
/* eslint-disable no-unused-vars */
/* eslint-disable no-plusplus */
import React, { createContext, useCallback, useContext, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { olMap } from 'components/Map/ControlMap';
import { Draw, Modify } from 'ol/interaction';
import Overlay from 'ol/Overlay';
import { Vector as VectorSource } from 'ol/source';
import { Vector as VectorLayer } from 'ol/layer';
import { Polygon, LineString, Point } from 'ol/geom';
import { Style, Fill, Stroke, Icon, RegularShape, Text, Circle as CircleStyle } from 'ol/style';
import { getLayerByName } from 'store/actions/mapActions';
import { getArea, getLength } from 'ol/sphere';
import { unByKey } from 'ol/Observable';
import { getEvents, getOptions } from 'components/Map/Utils';

export const MeasureStore = createContext();

export const initialState = {
	type: 'None',
	drawMeasuresIsActive: false
};

function reducer(state, action) {
	switch (action.type) {
		case 'MEASURE_AREA':
			return {
				...state,
				...{ type: 'Polygon', drawMeasuresIsActive: true }
			};

		case 'MEASURE_LENGTH':
			return {
				...state,
				...{ type: 'LineString', drawMeasuresIsActive: true }
			};
		case 'MEASURE_RESET':
			return {
				...state,
				...{ type: 'None', drawMeasuresIsActive: false }
			};
		default:
			return state;
	}
}

export const measureSource = new VectorSource({ wrapX: false });

export function MeasureStoreProvider({ children }) {
	const [state, dispatch] = useReducer(reducer, initialState);
	const [propsInteraction, setPropsInteraction] = useState();
	const [showModalMeasure, setShowModalMeasure] = useState(false);
	const [showFeatures, setShowFeatures] = useState(true);

	/**
	 * The help tooltip element.
	 * @type {HTMLElement}
	 */
	let helpTooltipElement;

	/**
	 * Overlay to show the help messages.
	 * @type {Overlay}
	 */
	let helpTooltip;

	/**
	 * The measure tooltip element.
	 * @type {HTMLElement}
	 */

	let measureTooltipElement;
	// const measureTooltipElement = useRef();

	/**
	 * Message to show when the user is drawing a polygon.
	 * @type {string}
	 */
	const continuePolygonMsg = 'Clique para continuar desenhando o polígono';

	/**
	 * Message to show when the user is drawing a line.
	 * @type {string}
	 */
	const continueLineMsg = 'Clique para continuar desenhando linha';

	const style = new Style({
		fill: new Fill({
			color: 'rgba(255, 255, 255, 0.6)'
		}),
		stroke: new Stroke({
			color: 'rgba(0, 0, 0, 0.5)',
			lineDash: [10, 10],
			width: 2
		}),
		image: new Icon({
			crossOrigin: 'anonymous',
			src: 'assets/icons/svg/drawplus.svg'
		})
	});

	// const showSegments = document.getElementById('segments');
	const showSegments = {
		checked: false
	};
	const clearPrevious = {
		checked: false
	};
	// const clearPrevious = document.getElementById('clear');

	const labelStyle = new Style({
		text: new Text({
			font: '800 14px Nunito,sans-serif',
			fill: new Fill({
				color: 'rgb(0, 0, 0)'
			}),
			backgroundFill: new Fill({
				color: 'rgb(255, 255, 255)'
			}),
			padding: [4, 5, 4, 5],
			textBaseline: 'bottom',
			offsetY: -15,
			offsetX: 20
		}),
		image: new RegularShape({
			radius: 8,
			points: 3,
			angle: Math.PI,
			displacement: [0, 10],
			fill: new Fill({
				color: 'rgb(255, 255, 255)'
			})
		})
	});

	const tipStyle = new Style({
		text: new Text({
			font: '800 14px Nunito,sans-serif',
			fill: new Fill({
				color: 'rgb(0, 0, 0)'
			}),
			backgroundFill: new Fill({
				color: 'rgb(255, 255, 255)'
			}),
			padding: [2, 2, 2, 2],
			textAlign: 'left',
			offsetX: 15
		})
	});

	const modifyStyle = new Style({
		image: new CircleStyle({
			radius: 5,
			stroke: new Stroke({
				color: 'rgb(255, 255, 255)'
			}),
			fill: new Fill({
				color: 'rgb(0, 0, 0)'
			})
		}),
		text: new Text({
			text: 'Arraste para editar',
			font: '800 14px Nunito,sans-serif',
			fill: new Fill({
				color: 'rgb(0, 0, 0)'
			}),
			backgroundFill: new Fill({
				color: 'rgb(255, 255, 255)'
			}),
			padding: [2, 2, 2, 2],
			textAlign: 'left',
			offsetX: 15
		})
	});

	const segmentStyle = new Style({
		text: new Text({
			font: '12px',
			fill: new Fill({
				color: 'rgba(255, 255, 255, 1)'
			}),
			backgroundFill: new Fill({
				color: 'rgba(0, 0, 0, 0.4)'
			}),
			padding: [2, 2, 2, 2],
			textBaseline: 'bottom',
			offsetY: -12
		}),
		image: new RegularShape({
			radius: 6,
			points: 3,
			angle: Math.PI,
			displacement: [0, 8],
			fill: new Fill({
				color: 'rgba(0, 0, 0, 0.4)'
			})
		})
	});

	const segmentStyles = [segmentStyle];

	const formatLength = (line) => {
		const length = getLength(line);
		let output;
		if (length > 100) {
			output = `${Math.round((length / 1000) * 100) / 100} km`;
		} else {
			output = `${Math.round(length * 100) / 100} m`;
		}
		return output;
	};

	const formatArea = (polygon) => {
		const area = getArea(polygon);
		let output;
		if (area > 10000) {
			output = `${Math.round((area / 1000000) * 100) / 100} km\xB2`;
		} else {
			output = `${Math.round(area * 100) / 100} m\xB2`;
		}
		return output;
	};

	const modify = new Modify({ source: measureSource, style: modifyStyle });

	let tipPoint;

	function styleFunction(feature, segments, drawType, tip) {
		const styles = [style];
		const geometry = feature.getGeometry();
		const type = geometry.getType();

		let point;
		let label;
		let line;
		if (!drawType || drawType === type) {
			if (type === 'Polygon') {
				point = geometry.getInteriorPoint();
				label = formatArea(geometry);
				line = new LineString(geometry.getCoordinates()[0]);
			} else if (type === 'LineString') {
				point = new Point(geometry.getLastCoordinate());
				label = formatLength(geometry);
				line = geometry;
			}
		}
		if (segments && line) {
			let count = 0;
			line.forEachSegment((a, b) => {
				const segment = new LineString([a, b]);
				const label = formatLength(segment);
				if (segmentStyles.length - 1 < count) {
					segmentStyles.push(segmentStyle.clone());
				}
				const segmentPoint = new Point(segment.getCoordinateAt(0.5));
				segmentStyles[count].setGeometry(segmentPoint);
				segmentStyles[count].getText().setText(label);
				styles.push(segmentStyles[count]);
				count++;
			});
		}
		if (label) {
			labelStyle.setGeometry(point);
			labelStyle.getText().setText(label);
			// styles.push(labelStyle);
		}
		if (tip && type === 'Point' && !modify.getOverlay().getSource().getFeatures().length) {
			tipPoint = geometry;
			tipStyle.getText().setText(tip);
			/* styles.push(tipStyle); */
		}
		return styles;
	}

	// let listener;
	const activeTip = `Clique para continuar desenhando ${state.type === 'Polygon' ? 'o polígono' : 'a linha'}`;
	const idleTip = 'Clique para começar a medir';
	let tip = idleTip;

	let sketch;

	let measureTooltip;
	let iconSvg;
	let iconPath;
	let coordinateText;

	/**
	 * Handle pointer move.
	 * @param {import("../src/ol/MapBrowserEvent").default} evt The event.
	 */
	const pointerMoveHandler = (evt) => {
		if (evt.dragging) {
			return;
		}
		/** @type {string} */
		let helpMsg = 'Clique para começar a medir';

		if (sketch) {
			const geom = sketch.getGeometry();
			if (geom instanceof Polygon) {
				helpMsg = continuePolygonMsg;
			} else if (geom instanceof LineString) {
				helpMsg = continueLineMsg;
			}
		}
		if (helpTooltipElement) {
			helpTooltipElement.innerHTML = helpMsg;
			helpTooltip.setPosition(evt.coordinate);

			helpTooltipElement.classList.remove('hidden');
		}
	};

	const vector = new VectorLayer({
		name: 'measure_draw',
		base: true,
		source: measureSource,
		style(feature) {
			return styleFunction(feature, showSegments.checked);
		},
		zIndex: 99999999999
	});

	let interaction = null;

	/**
	 * Creates a new help tooltip
	 */
	function createHelpTooltip() {
		if (helpTooltipElement) {
			helpTooltipElement.parentNode.removeChild(helpTooltipElement);
		}
		helpTooltipElement = document.createElement('div');
		helpTooltipElement.className = 'ol-tooltip somai-ol-tooltip-helper hidden';
		helpTooltip = new Overlay({
			element: helpTooltipElement,
			offset: [15, 0],
			positioning: 'center-left'
		});
		olMap()?.addOverlay(helpTooltip);
	}

	function createMeasureTooltip() {
		if (measureTooltipElement) {
			measureTooltipElement.parentNode.removeChild(measureTooltipElement);
		}
		iconSvg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
		iconPath = document.createElementNS('http://www.w3.org/2000/svg', 'path');
		coordinateText = document.createElement('div');
		measureTooltipElement = document.createElement('div');
		measureTooltipElement.className = 'ol-tooltip somai-ol-tooltip ol-tooltip-measure';
		measureTooltip = new Overlay({
			element: measureTooltipElement,
			offset: [0, -15],
			positioning: 'bottom-center',
			stopEvent: false,
			insertFirst: false
		});
		olMap()?.addOverlay(measureTooltip);
	}

	// creates unique id's
	function uniqueID() {
		return Math.floor(Math.random() * Date.now());
	}

	function addInteraction() {
		const type = state.type === 'Polygon' ? 'Polygon' : state.type === 'None' ? 'None' : 'LineString';
		if (type !== 'None') {
			interaction = new Draw({
				source: measureSource,
				type,
				stopClick: true,
				style(feature) {
					return styleFunction(feature, showSegments.checked, type, tip);
				}
			});
			olMap()?.addInteraction(interaction);

			createMeasureTooltip();
			createHelpTooltip();

			let listener;

			interaction.on('drawstart', (evt) => {
				const features = vector.getSource().getFeatures();
				// features = features.concat(evt.feature);

				// set sketch
				if (clearPrevious.checked) {
					measureSource.clear();
				}
				modify.setActive(false);
				tip = activeTip;

				sketch = evt.feature;

				/** @type {import("ol/coordinate").Coordinate|undefined} */
				let tooltipCoord = evt.coordinate;

				listener = sketch.getGeometry().on('change', (evt) => {
					const geom = evt.target;
					let output;
					if (geom instanceof Polygon) {
						output = formatArea(geom);
						tooltipCoord = geom.getInteriorPoint().getCoordinates();
						iconSvg.setAttribute('fill', 'none');
						iconSvg.setAttribute('viewBox', '0 0 14 12');
						iconSvg.setAttribute('width', '14');
						iconSvg.setAttribute('height', '12');
						iconPath.setAttribute(
							'd',
							'M9.845 1.25L12.6675 6L9.845 10.75H4.155L1.3325 6L4.155 1.25H9.75M10.13 0.25H3.88C3.79275 0.250527 3.70715 0.273879 3.63171 0.317737C3.55628 0.361595 3.49363 0.424431 3.45 0.5L0.322504 5.75C0.275782 5.82777 0.251099 5.91678 0.251099 6.0075C0.251099 6.09822 0.275782 6.18723 0.322504 6.265L3.44 11.5C3.48363 11.5756 3.54628 11.6384 3.62171 11.6823C3.69715 11.7261 3.78275 11.7495 3.87 11.75H10.12C10.2073 11.7495 10.2929 11.7261 10.3683 11.6823C10.4437 11.6384 10.5064 11.5756 10.55 11.5L13.6775 6.25C13.7242 6.17223 13.7489 6.08322 13.7489 5.9925C13.7489 5.90178 13.7242 5.81277 13.6775 5.735L10.56 0.5C10.5164 0.424431 10.4537 0.361595 10.3783 0.317737C10.3029 0.273879 10.2173 0.250527 10.13 0.25Z'
						);
						iconPath.setAttribute('fill', '#291F17');
						iconSvg.appendChild(iconPath);
						coordinateText.innerHTML = output;
					} else if (geom instanceof LineString) {
						output = formatLength(geom);
						tooltipCoord = geom.getLastCoordinate();
						iconSvg.setAttribute('fill', 'none');
						iconSvg.setAttribute('viewBox', '0 0 14 12');
						iconSvg.setAttribute('width', '14');
						iconSvg.setAttribute('height', '12');
						iconPath.setAttribute(
							'd',
							'M13.5261 9.2499C13.3465 8.94105 13.0731 8.69745 12.7456 8.55445C12.4181 8.41145 12.0536 8.3765 11.7049 8.45466L9.59854 4.81857C9.49869 4.64715 9.33499 4.52223 9.14325 4.47115C8.95151 4.42007 8.74733 4.44698 8.57539 4.54599L5.35082 6.39904L3.40457 3.04804C3.64612 2.78656 3.79738 2.4545 3.83611 2.10069C3.87484 1.74687 3.79898 1.38997 3.61971 1.08246C3.39553 0.699821 3.02935 0.421087 2.60074 0.306831C2.17214 0.192575 1.71574 0.25203 1.33074 0.472276C0.985672 0.672961 0.723876 0.990508 0.592735 1.36744C0.461595 1.74437 0.469775 2.15578 0.615796 2.52721C0.761817 2.89863 1.03603 3.20553 1.3888 3.39235C1.74157 3.57917 2.1496 3.63356 2.53901 3.54569L4.62035 7.11676C4.7202 7.28818 4.8839 7.4131 5.07564 7.46418C5.26738 7.51527 5.47156 7.48836 5.6435 7.38934L8.86807 5.53629L10.8393 8.94981C10.5978 9.21129 10.4465 9.54335 10.4078 9.89716C10.3691 10.251 10.4449 10.6079 10.6242 10.9154C10.8484 11.2994 11.2155 11.5791 11.6453 11.6934C12.0751 11.8077 12.5327 11.7474 12.9182 11.5256C13.1076 11.4161 13.2735 11.2703 13.4066 11.0967C13.5396 10.923 13.6371 10.7249 13.6936 10.5136C13.75 10.3023 13.7643 10.0819 13.7356 9.86509C13.7068 9.64826 13.6356 9.43922 13.5261 9.2499V9.2499Z'
						);
						iconPath.setAttribute('fill', '#291F17');
						iconSvg.appendChild(iconPath);
						coordinateText.innerHTML = output;
					}
					measureTooltipElement.appendChild(iconSvg);
					measureTooltipElement.appendChild(coordinateText);
					// measureTooltipElement.innerHTML = output;
					measureTooltip.setPosition(tooltipCoord);
				});
			});

			interaction.on('drawend', (e) => {
				const id = uniqueID();
				e.feature.setProperties({
					id,
					type: 'measure_draw'
				});

				e.feature.setId(id);
				modifyStyle.setGeometry(tipPoint);
				modify.setActive(true);
				olMap()?.once('pointermove', () => {
					modifyStyle.setGeometry();
				});
				tip = idleTip;
				measureTooltipElement.className = `ol-tooltip somai-ol-tooltip ol-tooltip-static`;
				measureTooltipElement.id = id;
				measureTooltip.setOffset([0, -7]);
				// unset sketch
				sketch = null;
				// unset tooltip so that a new one can be created
				measureTooltipElement = null;
				createMeasureTooltip();
				unByKey(listener);
			});
		}
	}

	useEffect(() => {
		olMap()?.addLayer(vector);
	}, []);
	useEffect(() => {
		olMap()?.addInteraction(modify);
	}, []);

	useEffect(() => {
		modify.setActive(true);
		addInteraction();
	}, []);

	olMap()?.on('pointermove', pointerMoveHandler);

	olMap()
		?.getViewport()
		.addEventListener('mouseout', () => {
			if (helpTooltipElement) {
				helpTooltipElement.classList.add('hidden');
			}
		});

	useEffect(() => {
		if (propsInteraction) {
			addInteraction();
		}
	}, [propsInteraction]);

	const handleChangeInteractionType = (value) => {
		const vectorDraw = getLayerByName('measure_draw');
		if (interaction) {
			olMap()?.removeInteraction(interaction);
			interaction = null;
		}

		if (value === 'Polygon') {
			if (state.type === 'Polygon') {
				dispatch({
					type: 'MEASURE_RESET'
				});
				if (helpTooltipElement) {
					helpTooltipElement.remove();
				}
			} else {
				dispatch({
					type: 'MEASURE_AREA'
				});
				if (helpTooltipElement) {
					helpTooltipElement.remove();
				}
				setPropsInteraction({
					source: vectorDraw.getSource(),
					type: value,
					style(feature) {
						return styleFunction(feature, showSegments.checked, value, tip);
					}
					/* onDrawstart: drawstart,
					onDrawend: drawend */
				});
			}
		} else if (value === 'LineString') {
			if (state.type === 'LineString') {
				dispatch({
					type: 'MEASURE_RESET'
				});
				if (helpTooltipElement) {
					helpTooltipElement.remove();
				}
			} else {
				dispatch({
					type: 'MEASURE_LENGTH'
				});
				setPropsInteraction({
					source: vectorDraw.getSource(),
					type: value,
					style(feature) {
						return styleFunction(feature, showSegments.checked, value, tip);
					}
					/* onDrawstart: drawstart,
					onDrawend: drawend */
				});
			}
		}
	};

	const resetInteraction = () => {
		if (interaction) {
			olMap()?.removeInteraction(interaction);
			interaction = null;
			dispatch({
				type: 'MEASURE_RESET'
			});
			if (helpTooltipElement) {
				helpTooltipElement.remove();
			}
		}
	};

	useEffect(() => {
		const keyDownHandler = (event) => {
			if (event.key === 'Escape') {
				handleChangeInteractionType(state.type);
				if (helpTooltipElement) {
					helpTooltipElement.remove();
				}
			}
		};
		document.addEventListener('keydown', keyDownHandler);

		return () => {
			document.removeEventListener('keydown', keyDownHandler);
		};
	}, [state.type]);

	const storevalue = useMemo(
		() => ({
			state,
			dispatch,
			handleChangeInteractionType,
			resetInteraction,
			showModalMeasure,
			setShowModalMeasure,
			showFeatures,
			setShowFeatures
		}),
		[state, showModalMeasure, showFeatures]
	);

	return <MeasureStore.Provider value={storevalue}>{children}</MeasureStore.Provider>;
}

export const useMeasureStore = () => useContext(MeasureStore);
