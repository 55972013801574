import React, { useEffect } from 'react';
import BarGraph from 'components/Graphs/BarGraph/BarGraph';
import { color } from 'styles/Theme';
import { useDispatch, useSelector } from 'react-redux';
import { getTraditionalUse } from 'store/actions/reportsForAdminAction';
import * as S from './styled';

export default function ReportTraditionalUses() {
	const dispatch = useDispatch();
	const { loadingTraditionalUse, listTraditionalUse } = useSelector((state) => state.reportsForAdmin);
	useEffect(() => dispatch(getTraditionalUse()), []);

	const generalLabels = ['ALDEIA', 'CAÇA', 'COLETA', 'PESCA', 'ROÇA', 'OUTROS'];

	const dataBar = [
		{
			label: 'Total de registros',
			data: [
				listTraditionalUse?.aldeia || 0,
				listTraditionalUse?.caca || 0,
				listTraditionalUse?.coleta || 0,
				listTraditionalUse?.pesca || 0,
				listTraditionalUse?.roca || 0,
				listTraditionalUse?.outros || 0
			],
			backgroundColor: color.auxiliary.bluePurple
		},
		{
			label: `Aprovados`,
			data: [
				listTraditionalUse?.approved?.aldeia || 0,
				listTraditionalUse?.approved?.caca || 0,
				listTraditionalUse?.approved?.coleta || 0,
				listTraditionalUse?.approved?.pesca || 0,
				listTraditionalUse?.approved?.roca || 0,
				listTraditionalUse?.approved?.outros || 0
			],
			backgroundColor: color.interface.green
		},
		{
			label: `Não aprovados`,
			data: [
				listTraditionalUse?.notApproved?.aldeia || 0,
				listTraditionalUse?.notApproved?.caca || 0,
				listTraditionalUse?.notApproved?.coleta || 0,
				listTraditionalUse?.notApproved?.pesca || 0,
				listTraditionalUse?.notApproved?.roca || 0,
				listTraditionalUse?.notApproved?.outros || 0
			],
			backgroundColor: color.interface.red
		},
		{
			label: `Em análise`,
			data: [
				listTraditionalUse?.inAnalyze?.aldeia || 0,
				listTraditionalUse?.inAnalyze?.caca || 0,
				listTraditionalUse?.inAnalyze?.coleta || 0,
				listTraditionalUse?.inAnalyze?.pesca || 0,
				listTraditionalUse?.inAnalyze?.roca || 0,
				listTraditionalUse?.inAnalyze?.outros || 0
			],
			backgroundColor: color.interface.orange
		}
	];

	return (
		<S.ContainerGeral>
			{loadingTraditionalUse ? (
				<S.Text>Carregando...</S.Text>
			) : (
				<BarGraph labelsProps={generalLabels} datasetsProps={dataBar} isMinWidth={600} />
			)}
		</S.ContainerGeral>
	);
}
