import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import BarGraph from 'components/Graphs/BarGraph/BarGraph';
import { returnDataGraph, isEmptyObject } from 'helpers/utils';
import { fireTotalLast30days } from 'store/actions/fireTotalLast30DaysAction';

export default function BarGraphTotalLast() {
	const dispatch = useDispatch();
	const { fireTotal, isLoading: loadingFireBarGraph } = useSelector((state) => state.fireTotalLast30Days);
	const { tiSelected } = useSelector((state) => state.tiSelectedTabTi);

	const [yearInsideTi, setYearInsideTi] = useState([]);
	const [countInsideTi, setCountInsideTi] = useState([]);
	const [yearOutsideTi, setYearOutsideTi] = useState([]);
	const [countOutsideTi, setCountOutsideTi] = useState([]);

	useEffect(() => {
		if (!isEmptyObject(tiSelected)) {
			dispatch(fireTotalLast30days(tiSelected.value));
		}
	}, [tiSelected]);

	useEffect(() => {
		if (fireTotal?.insideTi?.length > 0 || fireTotal?.outsideTi?.length > 0) {
			setYearInsideTi(fireTotal.insideTi.map((item) => item.year));
			setCountInsideTi(fireTotal.insideTi.map((item) => item.count));
			setYearOutsideTi(fireTotal.outsideTi.map((item) => item.year));
			setCountOutsideTi(fireTotal.outsideTi.map((item) => item.count));
		}
	}, [fireTotal]);

	const dataTotalLast = [
		{
			label: 'Dentro da TI',
			data: [...countInsideTi],
			backgroundColor: '#21543D'
		},
		{
			label: 'Fora da TI',
			data: [...countOutsideTi],
			backgroundColor: '#D44500'
		}
	];

	return (
		<BarGraph
			labelsProps={(yearInsideTi, yearOutsideTi)}
			datasetsProps={returnDataGraph(fireTotal, dataTotalLast)}
			title="Dentro"
			isLoading={loadingFireBarGraph}
			useLegend={false}
			useLegendRound
		/>
	);
}
