import React from 'react';
import { svg } from 'lib/svgList';
import ItensMenu from 'components/ItensMenu';
import TitleTab from 'components/TitleTab';
import UpdateUser from '../../../Users/UpdateUser';
import * as S from './styled';

export default function General() {
	const data = [
		{
			id: 1,
			title: 'Geral',
			subTitle: 'Meu Perfil',
			icon: svg.icon.userOrange,
			content: <UpdateUser />
		}
		// {
		// 	id: 2,
		// 	title: 'Geral',
		// 	subTitle: 'Meus Relatórios',
		// 	icon: svg.icon.fileOrange,
		// 	content: <h1>Hello!</h1>
		// }
	];

	return (
		<S.containerList>
			<TitleTab title="Geral" />
			{data.map((item) => (
				<ItensMenu key={item.id} title={item.title} subTitle={item.subTitle} icon={item.icon} content={item.content} />
			))}
		</S.containerList>
	);
}
